import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Tabs, Tab, Table } from "react-bootstrap";
import { css } from "@emotion/react";

import { CSVLink, CSVDownload } from "react-csv";
import MoonLoader from "react-spinners/PulseLoader";
import moment from "moment";

function App() {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  const [allimpound, setAllimpound] = useState([]);
  const [allauction, setAllAuction] = useState([]);
  const [allrelease, setAllRelease] = useState([]);
  const [allCurrent, setAllCurrent] = useState([]);
  const [dumpAllCurrent, setDumpAllCurrent] = useState([]);
  const [search, setSearch] = useState("");

  let [loading, setLoading] = useState(true);

  const [currentPrint, setCurrentPrint] = useState([]);
  const [releasePrint, setReleasePrint] = useState([]);
  const [auctionPrint, setAuctionPrint] = useState([]);
  const [allPrint, setAllPrint] = useState([]);

  const headers = [
    { label: "Invoice No.", key: "firstname" },
    { label: "Driver Name", key: "lastname" },
    { label: "Email", key: "email" },
    { label: "", key: "email" },
    { label: "Email", key: "email" },
  ];

  const currentToAuction = async (id) => {
    await fetch(`/account/apis/auction.php?id=${id}`)
      .then((response) => response.json())
      .then((json) => {
        console.log("currentToAuction 11 ");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getServerCurrent = async (id) => {
    await fetch(`/account/apis/view_impound.php?cid=${id}&type=waiting`)
      .then((response) => response.json())
      .then((json) => {
        const print = [];
        console.log("current");
        console.log("current");
        console.log("current");
        console.log(json);
        if (json[0].msg == "pass") {
          for (var i = 0; i < json.length; i++) {
            const intt = getNumberOfDays(json[i].date_im, Date.now());
            if (json[i].auction === "") {
            } else if (Number(json[i].auction) <= intt) {
              console.log("currentToAuction main ");
              currentToAuction(json[i].id);
            }
            const arrayToBeChanged = JSON.parse(json[i].array);
            arrayToBeChanged[1].quantity = intt;
            arrayToBeChanged[1].total = intt * arrayToBeChanged[1].price;
            const Total = arrayToBeChanged.reduce(
              (n, { total }) => n + total,
              0
            );
            json[i].sub = Total;
            json[i].tot = Total + Number(json[i].tax);
            const as = moment(json[i].date_im).format("MMMM DD, YYYY");
            json[i].date_im = as;

            let newObj = {
              id: i,
              invoice: json[i].invoie,
              driver: json[i].driver,
              havekeys: json[i].have_keys,
              impounddate: json[i].date_im,
              impoundtime: json[i].time_im,
              daysheld: intt,
              total: json[i].tot,
              color: json[i].color,
              model: json[i].model,
              license: json[i].license,
              state: json[i].v_state,
              vin: json[i].vin,
              year: json[i].year,
            };
            print.push(newObj);
          }
          setLoading(false);
          setAllCurrent(json);
          setDumpAllCurrent(json);
        } else {
          setLoading(false);
        }

        setCurrentPrint(print);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchx = (t) => {
    setSearch(t);

    const data = [...allCurrent];
    const newData = data.filter((item) => {
      const itemData = `${item.date_im.toUpperCase()} ${item.invoie.toUpperCase()} ${item.license.toUpperCase()}`;
      const textData = t.toUpperCase();

      return itemData.indexOf(textData) > -1;
    });
    if (t === "") {
      setAllCurrent(dumpAllCurrent);
    } else {
      setAllCurrent(newData);
    }
  };

  const getServerRelease = async (id) => {
    await fetch(`/account/apis/view_impound.php?cid=${id}&type=relese`)
      .then((response) => response.json())
      .then((json) => {
        if (json[0].msg === "fail") {
        } else {
          const print = [];
          for (var i = 0; i < json.length; i++) {
            const as = moment(json[i].date_im).format("MMMM DD, YYYY");
            json[i].date_im = as;
            const ass = moment(json[i].des).format("MMMM DD, YYYY");
            json[i].des = ass;
            let newObj = {
              id: i,
              invoice: json[i].invoie,
              driver: json[i].driver,
              havekeys: json[i].have_keys,
              impounddate: json[i].date_im,
              impoundtime: json[i].time_im,
              total: json[i].tot,
              color: json[i].color,
              model: json[i].model,
              license: json[i].license,
              state: json[i].v_state,
              vin: json[i].vin,
              year: json[i].year,
            };
            print.push(newObj);
          }

          setLoading(false);
          setAllRelease(json);
          setReleasePrint(print);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getServerAuction = async (id) => {
    await fetch(`/account/apis/view_impound.php?cid=${id}&type=auction`)
      .then((response) => response.json())
      .then((json) => {
        if (json[0].msg === "fail") {
        } else {
          const print = [];
          for (var i = 0; i < json.length; i++) {
            const as = moment(json[i].date_im).format("MMMM DD, YYYY");
            json[i].date_im = as;
            const ass = moment(json[i].des).format("MMMM DD, YYYY");
            json[i].des = ass;
            let newObj = {
              id: i,
              invoice: json[i].invoie,
              driver: json[i].driver,
              havekeys: json[i].have_keys,
              impounddate: json[i].date_im,
              impoundtime: json[i].time_im,
              total: json[i].tot,
              color: json[i].color,
              model: json[i].model,
              license: json[i].license,
              state: json[i].v_state,
              vin: json[i].vin,
              year: json[i].year,
            };
            print.push(newObj);
          }

          setAuctionPrint(print);
          setAllAuction(json);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getServerAll = async (id) => {
    await fetch(`/account/apis/view_impound.php?cid=${id}&type=all`)
      .then((response) => response.json())
      .then((json) => {
        if (json[0].msg === "fail") {
        } else {
          const print = [];
          for (var i = 0; i < json.length; i++) {
            const as = moment(json[i].date_im).format("MMMM DD, YYYY");
            json[i].date_im = as;
            const ass = moment(json[i].des).format("MMMM DD, YYYY");
            json[i].des = ass;
            let newObj = {
              id: i,
              invoice: json[i].invoie,
              driver: json[i].driver,
              havekeys: json[i].have_keys,
              impounddate: json[i].date_im,
              impoundtime: json[i].time_im,
              total: json[i].tot,
              color: json[i].color,
              model: json[i].model,
              license: json[i].license,
              state: json[i].v_state,
              vin: json[i].vin,
              year: json[i].year,
            };
            print.push(newObj);
          }

          setLoading(false);

          setAllPrint(print);

          setAllimpound(json);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const history = useHistory();

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));

    console.log(v);
    if (v === null) {
      console.log("V is null");
    } else {
      getServerAll(v[0].cid);
      getServerAuction(v[0].cid);
      getServerRelease(v[0].cid);
      getServerCurrent(v[0].cid);
    }
  }, []);

  const row = (id) => {
    localStorage.setItem("id_row", id);
    history.push("/row");
  };

  function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);
    let a = 1;

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);
    if (diffInDays < 1) {
      a = 1;
    } else {
      a = Number(diffInDays);
    }

    return a;
  }

  return (
    <div style={{ display: "block", flex: 1, padding: 30 }}>
      <strong className="d-inline-block mb-2 text-success">
        {" "}
        <h4> Impounds / Lot Management</h4>{" "}
      </strong>
      <div className="w-100">
        <p style={{ textAlign:"right",marginRight:"-50px" }}>
          <input
            size="sm"
            style={{ marginLeft: "10px", fontSize: "14px" }}
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              searchx(e.target.value);
            }}
          />
          <button
            size="sm"
            style={{ marginLeft: "10px", fontSize: "12px" }}
            className="btn btn-primary"
            onClick={() => searchx("")}
          >
            Clear
          </button>

          <div
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              fontSize: "12px",
            }}
            className="btn-group"
          >
            <button
              style={{ fontSize: "12px" }}
              type="button"
              className="btn btn-primary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Export CSV
            </button>
            <div className="dropdown-menu">
              <CSVLink
                className="dropdown-item"
                style={{ margin: "5px", fontSize: "12px" }}
                data={currentPrint}
                filename={"current.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                Current
              </CSVLink>
              <CSVLink
                className="dropdown-item"
                style={{ margin: "5px", fontSize: "12px" }}
                data={auctionPrint}
                filename={"auction.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                Auction
              </CSVLink>
              <CSVLink
                className="dropdown-item"
                style={{ margin: "5px", fontSize: "12px" }}
                data={releasePrint}
                filename={"release.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                Release
              </CSVLink>
              <CSVLink
                className="dropdown-item"
                style={{ margin: "5px", fontSize: "12px" }}
                data={allPrint}
                filename={"all.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                All
              </CSVLink>
            </div>
          </div>
          <div style={{ fontSize: "12px" }} className="btn-group">
            <button
              style={{ fontSize: "12px" }}
              type="button"
              className="btn btn-primary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Print
            </button>
            <div className="dropdown-menu">
              <center>
                <button
                  className="btn btn-primary"
                  style={{
                    height: "30px",
                    width: "70px",
                    marginTop: "3px",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    localStorage.setItem("print", JSON.stringify(currentPrint));
                    localStorage.setItem("print_type", "current");
                    const win = window.open("/allprint", "_blank");
                    win.focus();
                  }}
                >
                  Current
                </button>
                <br />
                <button
                  className="btn btn-primary"
                  style={{
                    height: "30px",
                    width: "70px",
                    marginTop: "3px",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    localStorage.setItem("print", JSON.stringify(releasePrint));
                    localStorage.setItem("print_type", "release");
                    const win = window.open("/allprint", "_blank");
                    win.focus();
                  }}
                >
                  Release
                </button>
                <br />
                <button
                  className="btn btn-primary"
                  style={{
                    height: "30px",
                    width: "70px",
                    marginTop: "3px",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    localStorage.setItem("print", JSON.stringify(auctionPrint));
                    localStorage.setItem("print_type", "auction");
                    const win = window.open("/allprint", "_blank");
                    win.focus();
                  }}
                >
                  Auction
                </button>
                <br />
                <button
                  className="btn btn-primary"
                  style={{
                    height: "30px",
                    width: "70px",
                    marginTop: "3px",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    localStorage.setItem("print", JSON.stringify(allPrint));
                    localStorage.setItem("print_type", "all");
                    const win = window.open("/allprint", "_blank");
                    win.focus();
                  }}
                >
                  All
                </button>
              </center>
            </div>
            <div style={{ width: "20px" }}></div>
          </div>
        </p>
      </div>

      {loading == true ? (
        <center>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <MoonLoader
            color="green"
            loading={loading}
            css={override}
            size={15}
            margin={10}
          />
        </center>
      ) : (
        <Tabs defaultActiveKey="first">
          <Tab eventKey="first" title={"Current (" + allCurrent.length + ")"}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Invoice No.</th>
                  <th>Driver Name</th>
                  <th>Have Keys</th>
                  <th>Impound Date</th>
                  <th>Days Held</th>
                  <th>Total</th>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Color</th>
                  <th>License</th>
                  <th>State</th>
                  <th>VIN</th>
                  <th>Year</th>
                </tr>
              </thead>
              <tbody>
                {allCurrent.map((i, index) =>
                  i.police === "yes" ? (
                    <>
                      <tr style={{ color: "red" }} onClick={() => row(i.id)}>
                        <td> {i.invoie}</td>
                        <td> {i.driver}</td>
                        <td> {i.have_keys}</td>
                        <td>
                          {i.date_im} {i.time_im}{" "}
                        </td>
                        <td>{getNumberOfDays(i.date_im, Date.now())}</td>

                        <td> ${i.tot}</td>
                        <td> {i.make}</td>
                        <td> {i.model}</td>

                        <td> {i.color}</td>
                        <td> {i.license}</td>
                        <td> {i.v_state}</td>
                        <td> {i.vin}</td>
                        <td> {i.year}</td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr onClick={() => row(i.id)}>
                        <td> {i.invoie}</td>
                        <td> {i.driver}</td>
                        <td> {i.have_keys}</td>
                        <td>
                          {" "}
                          {i.date_im} {i.time_im}
                        </td>
                        <td>{getNumberOfDays(i.date_im, Date.now())}</td>
                        <td> ${i.tot}</td>
                        <td> {i.make}</td>
                        <td> {i.model}</td>
                        <td> {i.color}</td>
                        <td> {i.license}</td>
                        <td> {i.v_state}</td>
                        <td> {i.vin}</td>
                        <td> {i.year}</td>
                      </tr>
                    </>
                  )
                )}
              </tbody>
            </Table>
          </Tab>

          <Tab eventKey="second" title="Auction">
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Invoice No.</th>
                  <th>Driver Name</th>
                  <th>Have Keys</th>

                  <th>Impound Date</th>

                  <th>Total</th>

                  <th>Make</th>
                  <th>Model</th>
                  <th>Color</th>
                  <th>license</th>
                  <th>State</th>
                  <th>VIN</th>

                  <th>Year</th>
                </tr>
              </thead>
              <tbody>
                {allauction.map((i, index) =>
                  i.police === "yes" ? (
                    <>
                      <tr style={{ color: "red" }} onClick={() => row(i.id)}>
                        <td> {i.invoie}</td>
                        <td> {i.driver}</td>
                        <td> {i.have_keys}</td>
                        <td>
                          {i.date_im} {i.time_im}{" "}
                        </td>

                        <td> {i.tot}$</td>

                        <td> {i.make}</td>
                        <td> {i.model}</td>
                        <td> {i.color}</td>
                        <td> {i.license}</td>
                        <td> {i.v_state}</td>
                        <td> {i.vin}</td>
                        <td> {i.year}</td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr onClick={() => row(i.id)}>
                        <td> {i.invoie}</td>
                        <td> {i.driver}</td>
                        <td> {i.have_keys}</td>
                        <td>
                          {" "}
                          {i.date_im} {i.time_im}
                        </td>

                        <td> {i.tot}$</td>

                        <td> {i.make}</td>
                        <td> {i.model}</td>
                        <td> {i.color}</td>
                        <td> {i.license}</td>
                        <td> {i.v_state}</td>
                        <td> {i.vin}</td>
                        <td> {i.year}</td>
                      </tr>
                    </>
                  )
                )}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="third" title="Release">
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Invoice No.</th>
                  <th>Driver Name</th>
                  <th>Have Keys</th>

                  <th>Impound Date</th>
                  <th>Release date</th>
                  <th>Total</th>
                  <th>Make</th>
                  <th>Model</th>

                  <th>Color</th>

                  <th>license</th>
                  <th>State</th>
                  <th>VIN</th>

                  <th>Year</th>
                </tr>
              </thead>
              <tbody>
                {allrelease.map((i, index) =>
                  i.police === "yes" ? (
                    <>
                      <tr style={{ color: "red" }} onClick={() => row(i.id)}>
                        <td> {i.invoie}</td>
                        <td> {i.driver}</td>
                        <td> {i.have_keys}</td>
                        <td>
                          {i.date_im} {i.time_im}{" "}
                        </td>
                        <td>{i.des}</td>
                        <td> ${i.tot}</td>
                        <td> {i.make}</td>

                        <td> {i.make}</td>
                        <td> {i.model}</td>
                        <td> {i.color}</td>
                        <td> {i.license}</td>
                        <td> {i.v_state}</td>
                        <td> {i.vin}</td>
                        <td> {i.year}</td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr onClick={() => row(i.id)}>
                        <td> {i.invoie}</td>
                        <td> {i.driver}</td>
                        <td> {i.have_keys}</td>
                        <td> {i.date_im}</td>
                        <td>{i.des}</td>
                        <td>${i.tot}</td>

                        <td> {i.make}</td>
                        <td> {i.model}</td>
                        <td> {i.color}</td>
                        <td> {i.license}</td>
                        <td> {i.v_state}</td>
                        <td> {i.vin}</td>
                        <td> {i.year}</td>
                      </tr>
                    </>
                  )
                )}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="all" title="All">
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Invoice No.</th>
                  <th>Driver Name</th>
                  <th>Have Keys</th>

                  <th>Impound Date</th>

                  <th>Make</th>
                  <th>Model</th>
                  <th>Color</th>

                  <th>License</th>
                  <th>State</th>
                  <th>VIN</th>

                  <th>Year</th>
                </tr>
              </thead>
              <tbody>
                {allimpound.map((i, index) =>
                  i.police === "yes" ? (
                    <>
                      <tr style={{ color: "red" }} onClick={() => row(i.id)}>
                        <td> {i.invoie}</td>
                        <td> {i.driver}</td>
                        <td> {i.have_keys}</td>
                        <td>{i.date_im}</td>
                        <td> {i.make}</td>
                        <td> {i.model}</td>
                        <td> {i.color}</td>

                        <td> {i.license}</td>
                        <td> {i.v_state}</td>
                        <td> {i.vin}</td>
                        <td> {i.year}</td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr onClick={() => row(i.id)}>
                        <td> {i.invoie}</td>
                        <td> {i.driver}</td>
                        <td> {i.have_keys}</td>
                        <td> {i.date_im}</td>

                        <td> {i.make}</td>
                        <td> {i.model}</td>
                        <td> {i.color}</td>
                        <td> {i.license}</td>
                        <td> {i.v_state}</td>
                        <td> {i.vin}</td>
                        <td> {i.year}</td>
                      </tr>
                    </>
                  )
                )}
              </tbody>
            </Table>
          </Tab>

          <button style={{ color: "red" }}>Export</button>
        </Tabs>
      )}
    </div>
  );
}

export default App;
