import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { useHistory } from "react-router-dom";
import { Display, CircleFill } from "react-bootstrap-icons";

function NewUsers() {
  const history = useHistory();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [Password, setPass] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const [cname, setCname] = useState();
  const [cid, setCid] = useState();
  const [uname, setUname] = useState();
  const [uid, setUid] = useState();
  const [displyXX, setDisply] = useState("user");

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));

    setCname(v[0].cname);
    setCid(v[0].cid);
    setUid(v[0].id);
    setUname(v[0].name);
    if (v[0].type === "user") {
    } else {
      setDisply("company");
    }
  }, []);

  const newUser = async (e) => {
    e.preventDefault();
    console.log("run");

    if (name == "") {
      alert("Enter Name");
    } else if (email == "") {
      alert("Enter Email");
    } else if (phone == "") {
      alert("Enter Phone");
    } else if (Password == "") {
      alert("Enter Password");
    } else {
      newU();
      console.log("else");
    }
  };

  const newU = async (e) => {
    await fetch(
      `/account/apis/user_new.php?cid=${cid}&uid=${uid}&email=${email}&name=${name}&phone=${phone}&pass=${Password}`
    )
      .then((response) => response.json())
      .then((json) => {
        history.push("/alluser");
      })
      .catch((err) => {
        alert(err);
      });
  };
  return (
    <>
      {displyXX === "user" ? (
        ""
      ) : (
        <div
          className="container"
          style={{ marginTop: "70px", marginBottom: "70px" }}
        >
          <form className="needs-validation">
            <strong className="d-inline-block mb-2 text-success">
              <h3>New User</h3>
            </strong>
            <div className="row">
              <div className="col-sm-6 col-12">
                <p>
                  <CircleFill
                    color="#1ceb15"
                    size={12}
                    style={{ marginRight: "3px" }}
                  />{" "}
                  Active |{" "}
                  <Display
                    size={20}
                    style={{ marginRight: "3px", marginLeft: "3px" }}
                  />
                  <> {uname}</>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-4">
                <label for="validationCustom01">Company Name </label>
                <input
                  type="text"
                  disabled
                  value={cname}
                  id="fname"
                  className="form-control form-control-sm"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-4">
                <label for="validationCustom01">Full Name* </label>
                <input
                  type="text"
                  value={name}
                  onChange={(t) => {
                    setName(t.target.value);
                  }}
                  id="fname"
                  placeholder="Full Name*"
                  className="form-control form-control-sm"
                />
              </div>
            </div>

            <div style={{ marginTop: "10px" }} className="row">
              <div className="col-sm-4 col-4">
                <label for="validationCustom01">Phone* </label>
                <input
                  type="text"
                  id="fname"
                  value={phone}
                  onChange={(t) => {
                    setPhone(t.target.value);
                  }}
                  placeholder="Phone*"
                  className="form-control form-control-sm"
                />
              </div>
            </div>
            <div style={{ marginTop: "10px" }} className="row">
              <div className="col-sm-4 col-4">
                <label for="validationCustom01">Email*</label>
                <input
                  type="text"
                  id="fname"
                  value={email}
                  onChange={(t) => {
                    setEmail(t.target.value);
                  }}
                  placeholder="Email*"
                  className="form-control form-control-sm"
                />
              </div>
            </div>
            <div style={{ marginTop: "10px" }} className="row">
              <div className="col-sm-4 col-4">
                <label for="validationCustom01">Password*</label>
                <input
                  type={isRevealPwd ? "text" : "password"}
                  id="fname"
                  value={Password}
                  onChange={(t) => {
                    setPass(t.target.value);
                  }}
                  name="name"
                  placeholder="Password*"
                  className="form-control form-control-sm"
                />
              </div>
            </div>
            <div style={{ marginTop: "5px" }} className="row">
              <div className="col-sm-4 col-4">
                <div className="form-check">
                  <input
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    className="form-check-input"
                    type="checkbox"
                    id="havekey"
                  />
                  <label
                    className="form-check-label"
                    style={{ fontSize: "12px" }}
                    htmlFor="havekey"
                  >
                    {isRevealPwd === true ? "Hide Password" : "Show Password"}
                  </label>
                </div>
              </div>
            </div>
            <button
              onClick={newUser}
              style={{ backgroundColor: "#3fa66c", marginTop: "20px" }}
              type="button"
              className="btn btn-secondary"
            >
              {" "}
              Submit{" "}
            </button>
          </form>
        </div>
      )}
    </>
  );
}
export default NewUsers;
