import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { Form } from "react-bootstrap";
import { BrowserRouter as Router, Link } from "react-router-dom";

function ViewTruck() {
  const [allCom, setAllCom] = useState([]);
  const [select, setSelect] = useState("");
  const array = [
    {
      msg: "pass",
      id: "",
      name: "",
      phone: "",
      cname: "",
      cid: "",
      type: "",
      cphone: "",
      cadd: "",
      cemail: "",
      cca: "",
      cusdot: "",
      cstate: "",
      ccity: "",
      cfax: "",
      ccountry: "",
    },
  ];

  const getAllCompanyServer = async () => {
    await fetch(`/account/apis/companylist.php`)
      .then((response) => response.json())
      .then((json) => {
        setAllCom(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY_MASTER"));

    if (v === null) {
    } else {
      getAllCompanyServer();
    }
  }, []);

  return (
    <div className="container-sm">
      <center>
        <div style={{ marginTop: "40px" }} className="col-sm-8  col-4">
          <label htmlFor="validationCustom01">
            Please, Select any Company for more details
          </label>
        </div>
        <div style={{ marginTop: "10px" }} className="col-sm-4  col-4">
          <Form.Select
            size="sm"
            value={select}
            onChange={(t) => {
              if (t.target.value === "a") {
              } else {
                setSelect(t.target.value);
                const a = t.target.value;

                array[0].id = allCom[a].id;
                array[0].name = allCom[a].name;
                array[0].phone = allCom[a].phone;
                array[0].cname = allCom[a].name;
                array[0].cid = allCom[a].id;
                array[0].type = "company";

                array[0].cphone = allCom[a].cphone;
                array[0].cadd = allCom[a].cadd;
                array[0].cemail = allCom[a].cemail;
                array[0].cca = allCom[a].cca;
                array[0].cusdot = allCom[a].cusdot;
                array[0].ccity = allCom[a].ccity;
                array[0].cstate = allCom[a].cstate;
                array[0].cfax = allCom[a].cfax;
                array[0].ccountry = allCom[a].ccountry;

                localStorage.setItem("LOCAL_KEY", JSON.stringify(array));
              }
            }}
          >
            <option value="a">Select Company</option>
            {allCom.map((i, index) => (
              <option value={index}>{i.name}</option>
            ))}
          </Form.Select>
        </div>
        <Link
          style={{ marginTop: "20px", backgroundColor: "#3fa66c" }}
          className="btn btn-secondary"
          to="home"
          target="_blank"
        >
          Submit
        </Link>
      </center>
    </div>
  );
}
export default ViewTruck;
