import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";



import "../MyComponents/index.css";
import hide from "../image/hidden.png";
import show from "../image/show.png";
import "./index.css";

function Login() {
  const [email, setEmail] = useState("");
  const [pass, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [type, setType] = useState("user");
  const [allCom, setAllCom] = useState([]);

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const history = useHistory();

  const getAllCompanyServer = async () => {
    await fetch(`/account/apis/companylist.php`)
      .then((response) => response.json())
      .then((json) => {
        setAllCom(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllCompanyServer();
  }, []);

  const loginServer = async (e) => {
    e.preventDefault();
//alert(`/account/apis/c_login.php?email=${email}&pass=${pass}&type=${type}&company_id=${company}`)
    await fetch(
      `/account/apis/c_login.php?email=${email}&pass=${pass}&type=${type}&company_id=${company}`
    )
      .then((response) => response.json())
      .then((json) => {
        if (json == null) {
        } else if (json[0].msg === "pass") {
          localStorage.setItem("LOCAL_KEY", JSON.stringify(json));
          history.push("/home");
        } else if (json[0].msg === "Disable") {
          alert("Sorry! Your account is Disabled. Please, contact Admin");
        } else {
          alert("Invalid Email or Password! Please try again.");
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <center>
      <div className="col-md-4" style={{ marginTop: "100px" }}>
        <div className="card-body">
          <center>
            <form>
              <div className="pwd-container">
                <div className="card-header">
                  <h4>ImpoundApp Login</h4>
                </div>
              </div>
              <div className="pwd-container">
                <select
                  style={{ marginTop: "20px" }}
                  value={company}
                  onChange={(t) => {
                    setCompany(t.target.value);
                  }}
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Email"
                >
                  <option value="a">Select Company</option>
                  {allCom.map((i) => (
                    <option value={i.id}>{i.name}</option>
                  ))}
                </select>
              </div>
              <div className="pwd-container">
                <input
                  required
                  type="email"
                  style={{ marginTop: "20px" }}
                  value={email}
                  onChange={(t) => {
                    setEmail(t.target.value);
                  }}
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Email"
                />
              </div>
       

              <div className="pwd-container">
                <input
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter Password"
                  autoComplete="off"
                  style={{ marginTop: "20px" }}
                  value={pass}
                  name="pwd"
                  placeholder="Enter Password"
                  type={isRevealPwd ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <img
                  style={{ height: "20px", width: "20px" }}
                  title={isRevealPwd ? "Hide password" : "Show password"}
                    src={isRevealPwd ? hide : show}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />
   
              </div>
               
              <div className="pwd-container">
                <input
                  style={{ marginLeft: "20px" }}
                  type="radio"
                  id="tt"
                  onClick={() => {
                    setType("user");
                  }}
                  checked={true}
                  name="fav_language"
                />
                <label
                  onClick={() => {
                    setType("user");
                  }}
                  style={{ marginLeft: "10px" }}
                  htmlFor="tt"
                >
                  Employee
                </label>
                <input
                  style={{ marginLeft: "20px" }}
                  type="radio"
                  id="p"
                  onClick={() => {
                    setType("company");
                  }}
                  name="fav_language"
                />
                 
                <label
                  onClick={() => {
                    setType("company");
                  }}
                  style={{ marginLeft: "10px" }}
                  htmlFor="p"
                >
                  Company
                </label>
              </div>
              <br />
              <button
                type="submit"
                style={{ marginTop: "20px" }}
                onClick={loginServer}
                className="btn btn-primary"
              >
                Login Now{" "}
              </button>
            </form>
          </center>
        </div>
      </div>
    </center>
  );
}

export default Login;
