import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Trash } from "react-bootstrap-icons";
import { BrowserRouter as Router, Link } from "react-router-dom";

function ViewDriver() {
  const [alltruck, setAllTruck] = useState([]);
  const [cid, setCid] = useState();

  const getAllTruckServer = async (ci) => {
    await fetch(`/account/apis/driver.php?cid=${ci}`)
      .then((response) => response.json())
      .then((json) => {
        setAllTruck(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));

    console.log(v);

    if (v === null) {
      console.log("V is null");
    } else {
      setCid(v[0].cid);
      getAllTruckServer(v[0].cid);
    }
  }, []);

  const del = async (id) => {
    await fetch(`/account/apis/del.php?id=${id}&t=driver`)
      .then((response) => response.json())
      .then((json) => {
        getAllTruckServer(cid);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container-sm">
      <Link
        to="/driver"
        style={{
          backgroundColor: "#3fa66c",
          fontSize: "18px",
          flex: 1,
          marginTop: "20px",
          alignItems: "flex-end",
        }}
        className="btn btn-secondary"
      >
        Add New Driver
      </Link>
      <table style={{ marginTop: "30px" }} className="table table-striped">
        <thead>
          <tr>
            <th> Name</th> <th>Phone No.</th>
            <th>Date & Time</th> <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {alltruck.map((i, index) => (
            <tr>
              <td scope="row">{i.name} </td>
              <td>{i.phone} </td>
              <td>{i.t} </td>{" "}
              <td>
                <Trash color="gray" size={20} onClick={() => del(i.id)} />
              </td>{" "}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default ViewDriver;
