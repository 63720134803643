import React, { useState, useEffect } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory, Link } from "react-router-dom";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/PulseLoader";
import moment from "moment";
import axios from "axios";
import { Tabs,  Tab,Nav,Sonnet ,Form, FormCheck,Table ,ProgressBar,Container,Row,Col} from "react-bootstrap";
import {
  PlusCircleFill,
  Signpost,
  PersonCircle,
  PersonPlusFill,
  Trash,
  SunFill,
  InfoSquare,
  Slack,
  Display,
  Save,
  Receipt,
  CircleFill,

} from "react-bootstrap-icons";
import { MultiSelect } from "react-multi-select-component";


function App(){

  const [row, setRow] = useState();
  const [img, setimg] = useState();
  const [change, setimgChange] = useState();
  const history = useHistory();


//upload start
const saveIm = async (e) => {
  e.preventDefault();

    const data = new FormData();
    data.append("img", img);
    data.append("impound_id", row);
    data.append("change", change);

       axios({
        method: "post",
        url: "/account/apis/image_upload_claim.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((s)=>(
        history.push("/claimupdate")
      )).catch((s)=>(
        history.push("/claimupdate")
      ));
};


useEffect(() => {
  let a = localStorage.getItem("id_row");
  
  console.log("img change",localStorage.getItem("imgchange"))

  setRow(a);
  if (a === null) {
    history.push("/report");
  } 

}, []);

  return(
    <div style={{ marginTop: "150px" }} className="container">
      <center>
        <form
          onSubmit={(e) => {
            saveIm(e);
          }}
        >
         <h2>Change Image </h2>
                   <div style={{marginTop:"15px"}} >
       
       <Form.Select
       style={{ marginTop:"35px",width: "600px" }}
       onChange={(a)=>{
        if(a=="a")
        {

        }else{
          setimgChange(a.target.value)
        }
        
      }}
   
   

  
 >
  
  <option value="a">Select Type</option>
            <option value="imglicense">Driver License</option>
            <option value="imgplate">License Plate</option>
            <option value="imgcard">Insurance Card</option>
            <option value="imgvehiclecard">Pre Vehicle Scene</option>
            <option value="imgspill">Spill</option>
            <option value="imgafter">After Vehicle Scene</option>
            <option value="imga">Other 1</option>
            <option value="imgb">Other 2</option>
            <option value="imgc">Other 3</option>
 </Form.Select>

 
         </div>
          <input
            style={{ width: "600px",marginTop: "20px" }}
            type="file"
            onChange={(e) => {
              setimg(e.target.files[0]);
              console.log("img",e.target.files[0])
      
            }}
            className="form-control"
          />

        


                    <button
            type="submit"
            style={{  width: "100px",marginTop: "20px", backgroundColor: "#3d85c6" }}
            className="btn btn-secondary"
          >
            
            Save
          </button>
          <button
         onClick={()=>{
          setimgChange("imglicense")
          history.push("/claimupdate")
         }}
            style={{ width: "100px",marginLeft:"20px", marginTop: "20px", backgroundColor: "#ffa500" }}
            className="btn btn-secondary"
          >
            
        Back
          </button>
        </form>
      </center>
    </div>
  )
}


export default App;