import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import axios from "axios";

const imageUrl ="https://i.picsum.photos/id/566/200/300.jpg?hmac=gDpaVMLNupk7AufUDLFHttohsJ9-C17P7L-QKsVgUQU";

export default function App() {
  const [imgUrl, setImgUrl] = useState();
 
  const [name, setName] = useState("");

  function base64_encode(s) {
    
    setImgUrl(s);
  }

  useEffect(() => {
 
  }, []);

  return (
    <div>
    <image style={{marginTop:"500px"}} source={{uri:imgUrl}} />
      <center>
       
        {
          
        }
        <image  source={{uri: imageUrl}} /> 
          <input
            style={{ width: "600px" }}
            type="file"
            onChange={(e) => {
              
              
              base64_encode(e.target.files[0]);
        
            }}
            className="form-control"
          />

         
      
      </center>
    </div>
  );
}