import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Trash } from "react-bootstrap-icons";
import { BrowserRouter as Router, Link } from "react-router-dom";

function ViewTruck() {
  const [allCom, setAllCom] = useState([]);

  const getAllCompanyServer = async () => {
    await fetch(`/account/apis/companylist.php`)
      .then((response) => response.json())
      .then((json) => {
        setAllCom(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY_MASTER"));

    if (v === null) {
    } else {
      getAllCompanyServer();
    }
  }, []);

  const del = async (id) => {
    await fetch(`/account/apis/del.php?id=${id}&t=company`)
      .then((response) => response.json())
      .then((json) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Link
        to="/newcompany"
        style={{
          backgroundColor: "#3fa66c",
          fontSize: "18px",

          marginTop: "20px",
        }}
        className="btn btn-secondary"
      >
        Add New Company
      </Link>
      <table style={{ marginTop: "30px" }} className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Password</th>
            <th>Address</th>
            <th>Ca</th>

            <th>Usdot</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
            <th>Fax</th>
            <th>Country</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {allCom.map((i, index) => (
            <tr key={index}>
              <td>{i.name} </td>
              <td>{i.phone} </td>
              <td>{i.email} </td>
              <td>{i.pass} </td>
              <td>{i.add} </td>
              <td>{i.ca} </td>
              <td>{i.usdot} </td>
              <td>{i.city} </td>
              <td>{i.state} </td>
              <td>{i.zip} </td>
              <td>{i.fax} </td>
              <td>{i.country} </td>
              <td>
                <Trash color="gray" size={20} onClick={() => del(i.id)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default ViewTruck;
