import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  FormGroup,
  Button,
} from "react-bootstrap";
import logo from "../image/logo.png";

function Home() {
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <Navbar
            style={{ background: "#389460", color: "#ffffff" }}
            variant="dark"
            expand="lg"
            sticky="top"
          >
            <Navbar.Brand href="#home">
              {" "}
              <img
                src={logo}
                alt="LOGO"
                style={{
                  height: "50px",
                  width: "170px",
                  paddingLeft: "15px",
                  marginLeft: "30px",
                }}
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/about-us">Contact Us</Nav.Link>
                <Nav.Link href="/contact-us">About Us</Nav.Link>
                <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Something
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </div>
  );
}

export default Home;
