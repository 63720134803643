import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Trash } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const ViewDriver = () => {
  const [allUser, setAllUser] = useState([]);
  const [cid, setCid] = useState();
  const [displyXX, setDisply] = useState("user");

  const getAlluserServer = async (id) => {
    await fetch(`/account/apis/user.php?cid=${id}`)
      .then((response) => response.json())
      .then((json) => {
        setAllUser(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));
    if (v[0].type === "user") {
    } else {
      setDisply("company");
    }
    setCid(v[0].cid);
    getAlluserServer(v[0].cid);
  }, []);

  const del = async (id) => {
    await fetch(`/account/apis/del.php?id=${id}&t=user`)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        getAlluserServer(cid);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {displyXX === "user" ? (
        ""
      ) : (
        <div className="container-sm">
          <Link
            to="/user"
            style={{
              backgroundColor: "#3fa66c",
              fontSize: "18px",
              flex: 1,
              marginTop: "20px",
              alignItems: "flex-end",
            }}
            className="btn btn-secondary"
          >
            Add New User
          </Link>
          <table style={{ marginTop: "30px" }} className="table table-striped">
            <thead>
              <tr>
                <th> Name</th> <th>Email</th>
                <th>Password</th> <th>Phone No.</th>
                <th>Date & Time</th> <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allUser.map((i, index) => (
                <tr>
                  <td scope="row"> {i.name} </td>
                  <td scope="row"> {i.email} </td>
                  <td scope="row"> {i.pass} </td>
                  <td>{i.phone} </td>
                  <td>{i.t} </td>{" "}
                  <td>
                    <Trash color="gray" size={20} onClick={() => del(i.id)} />
                  </td>{" "}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
export default ViewDriver;
