import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tabs, Tab, Table } from "react-bootstrap";
import { css } from "@emotion/react";
import moment from "moment";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function App() {
  const [array, setArray] = useState([]);
  const [type, setType] = useState("");
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));

    console.log(v);
    if (v === null) {
      console.log("V is null");
    } else {
      setType(localStorage.getItem("print_type"));
      setArray(JSON.parse(localStorage.getItem("print")));
    }
  }, []);

  return (
    <div>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Invoice No.</th>
            <th>Driver Name</th>
            <th>Have Keys</th>
            <th>Impound Date</th>
            <th>Days Held</th>
            <th>Total</th>
            <th>Color</th>
            <th>Model</th>
            <th>Licence</th>
            <th>State</th>
            <th>VIN</th>
            <th>Year</th>
          </tr>
        </thead>
        <tbody>
          {array.map((i, index) => (
            <>
              <tr>
                <td> {i.invoice}</td>
                <td> {i.driver}</td>
                <td> {i.havekeys}</td>
                <td>
                  {i.impounddate} {i.impoundtime}
                </td>
                <td>{i.daysheld}</td>

                <td> ${i.total}</td>
                <td> {i.color}</td>
                <td> {i.model}</td>
                <td> {i.license}</td>
                <td> {i.state}</td>
                <td> {i.vin}</td>
                <td> {i.year}</td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default App;
