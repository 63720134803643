import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Form,
  FormControl,
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Button,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import logo from "../image/logo.png";
import { BrowserRouter as Router, useHistory, Link } from "react-router-dom";

function Newimpound() {
  const [log, setLog] = useState([]);
  const [displyXX, setDisply] = useState("user");

  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("LOCAL_KEY");
    history.push("/login");
  };

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));
    if (v == null) {
      history.push("/login");
    } else {
      setLog(v);
      if (v[0].type === "user") {
      } else if (v[0].type === "company") {
        setDisply("company");
      } else if (v[0].type === "master") {
        setDisply("master");
      }
    }
  }, []);

  return (
    <div>
      <Navbar style={{ background: "#389460", color: "#ffffff" }} expand="lg">
        <Container fluid>
          <Navbar.Brand href="#home">
            {" "}
            <img
              src={logo}
              alt="LOGO"
              style={{
                height: "40px",
               
              }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link
                style={{
                  color: "#ffffff",

                  fontSize: "18px",
                }}
                className="nav-link"
                to="/home"
              >
                Home{" "}
              </Link>

              <Link
                style={{
                  color: "#ffffff",

                  fontSize: "18px",
                }}
                className="nav-link"
                to="/report"
              >
                Reports{" "}
              </Link>
              {displyXX === "user" ? (
                <Link
                  style={{
                    color: "#ffffff",

                    fontSize: "18px",
                  }}
                  className="nav-link"
                  to="/impound"
                >
                  Create Impound
                </Link>
              ) : (
                ""
              )}
              {/* <Nav.Link href="home">Home</Nav.Link>
              <Nav.Link href="#link">Link</Nav.Link> */}
              <div className="dropdown">
                <p
                  className="dropdown-toggle nav-link"
                  id="menu1"
                  data-toggle="dropdown"
                  style={{
                    color: "#FFFFFF",
                    fontSize: "18px",
                  }}
                >
                  Setting
                  <span className="caret"></span>
                </p>
                <ul
                  className="dropdown-menu"
                  role="menu"
                  aria-labelledby="menu1"
                >
                  {displyXX === "user" ? (
                    ""
                  ) : (
                    <Link className="dropdown-item" to="/alluser">
                      All Users
                    </Link>
                  )}
                  <Link className="dropdown-item" to="/alltruck">
                    All Trucks
                  </Link>
                  <Link className="dropdown-item" to="/alldriver">
                    All Drivers
                  </Link>
                </ul>
              </div>

              {log === null ? (
                history.push("/login")
              ) : (
                <Link
                  style={{
                    color: "#ffffff",

                    fontSize: "18px",
                  }}
                  className="nav-link"
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
export default Newimpound;
