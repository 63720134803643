import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";
import MoonLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/react";
import { Alert } from "react-bootstrap";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function Home() {
  let [loading, setLoading] = useState(true);
  const [value, setValue] = useState([]);
  const [current, setCurrent] = useState("");
  const [all, setAll] = useState("");
  const [auction, setAuction] = useState("");
  const [release, setRelease] = useState("");

  const history = useHistory();

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));
    if (v === null) {
    } else {
      setValue(v);
      getAllServer(v[0].cid);
    }
  }, []);

  const getAllServer = async (id) => {
    await fetch(`/account/apis/home.php?cid=${id}`)
      .then((response) => response.json())
      .then((json) => {
        setAll(json[0].all);
        setAuction(json[0].auction);
        setCurrent(json[0].current);
        setRelease(json[0].release);
        setLoading(!loading);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ margin: "20px" }}>
      <center>
        {" "}
        <h3 style={{ fontSize: "40px" }}>
          Welcome,{" "}
          {value.map((i, index) =>
            i.msg == "fail" ? history.push("/") : i.name
          )}{" "}
          to ImpoundApp.com
        </h3>
      </center>

      {loading === true ? (
        <center>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <MoonLoader
            color="green"
            loading={loading}
            css={override}
            size={15}
            margin={10}
          />
        </center>
      ) : (
        <>
          <center>
            <div className="container" style={{ marginTop: "20px" }}>
              <Alert variant="success">
                <Alert.Heading>{all}</Alert.Heading>
                <p>All Impounds</p>
              </Alert>
              <br />
              <Alert variant="danger">
                <Alert.Heading>{current}</Alert.Heading>
                <p>All Current Impounds </p>
              </Alert>
              <br />
              <Alert variant="warning">
                <Alert.Heading>{release}</Alert.Heading>
                <p>All Released Impounds </p>
              </Alert>
              <br />
              <Alert variant="dark">
                <Alert.Heading>{auction}</Alert.Heading>
                <p>All Auctions Impounds </p>
              </Alert>
              <br />
            </div>
          </center>
        </>
      )}
    </div>
  );
}
export default Home;
