import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import logo from "../image/logo.png";
import { BrowserRouter as Router, useHistory, Link } from "react-router-dom";
import {
  Form,
  FormControl,
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Button,
} from "react-bootstrap";

function NNavbar() {
  const [log, setLog] = useState([]);

  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("LOCAL_KEY_MASTER");
    history.push("/login");
  };

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY_MASTER"));
    if (v == null) {
      history.push("/login");
    } else if (v[1].type === "master") {
      setLog(v);
    } else {
      history.push("/login");
    }
  }, []);

  return (
    <>
      <Navbar style={{ background: "#389460", color: "#ffffff" }} expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            {" "}
            <img
              src={logo}
              alt="LOGO"
              style={{
                height: "40px",
                width: "100%",
              }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link
                style={{
                  color: "#ffffff",
                  marginLeft: "30px",
                  fontSize: "18px",
                }}
                className="nav-link"
                to="/selectcompany"
              >
                Home
              </Link>
              <Link
                style={{
                  color: "#ffffff",
                  marginLeft: "30px",
                  fontSize: "18px",
                }}
                className="nav-link"
                to="/company"
              >
                All Companies
              </Link>

              {log === null ? (
                history.push("/login")
              ) : (
                <Link
                  style={{
                    color: "#ffffff",
                    marginLeft: "30px",
                    fontSize: "18px",
                  }}
                  className="nav-link"
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NNavbar;
