import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Display, CircleFill } from "react-bootstrap-icons";

function Record() {
  const [row, setRow] = useState();
  const [uname, setUname] = useState("");
  const [am, setam] = useState("");
  const [type, setType] = useState("");
  const [amount, setAmount] = useState("");
  const [ref, setRef] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const history = useHistory();

  const setRecord = async () => {
    await fetch(
      `/account/apis/record.php?mid=${row}&ref=${ref}&type=${type}&amount=${amount}&date=${date}&time=${time}`
    )
      .then((response) => response.json())
      .then((json) => {
        history.push("/row");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));
    let a = localStorage.getItem("id_row");

    setam(localStorage.getItem("amount"));

    console.log(v);

    if (v === null) {
      console.log("V is null");
    } else if (a === null) {
      console.log("a is null");
    } else {
      setRow(a);
      setUname(v[0].name);
    }
  }, []);

  return (
    <div className="container-sm">
      <div style={{ marginTop: "30px" }}>
        <strong className="d-inline-block mb-2 text-success">
          {" "}
          <h4> Impounds : Record Payment </h4>
        </strong>
      </div>

      <form className="needs-validation">
        <div className="row">
          <div className="col-sm-6 col-12">
            <p>
              <CircleFill
                color="#1ceb15"
                size={12}
                style={{ marginRight: "3px" }}
              />{" "}
              Active |{" "}
              <Display
                size={20}
                style={{ marginRight: "3px", marginLeft: "3px" }}
              />
              <> {uname}</>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">
              {" "}
              Total Amount:
              <span
                style={{ color: "red", fontSize: "20px", fontWeight: "bold" }}
              >
                {"  $"}
                {am}
              </span>{" "}
            </label>
          </div>
        </div>
        <input
          type="radio"
          id="cash"
          style={{ marginLeft: "5px", marginTop: "20px" }}
          name="fav_language"
          value="cash"
          onChange={() => {
            setType("Cash");
          }}
        />
          <label for="cash">Cash</label>
        <input
          type="radio"
          id="Check"
          name="fav_language"
          onChange={() => {
            setType("Check");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="Check">Check</label>
        <input
          type="radio"
          id="visa"
          name="fav_language"
          onChange={() => {
            setType("Visa");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="visa">Visa</label> 
        <input
          type="radio"
          id="mc"
          onChange={() => {
            setType("MasterCard");
          }}
          name="fav_language"
          value="MasterCard"
          style={{ marginLeft: "10px" }}
        />
          <label for="mc">MasterCard</label> 
        <input
          type="radio"
          id="d"
          name="fav_language"
          value="Discover"
          onChange={() => {
            setType("Discover");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="d">Discover </label> 
        <input
          type="radio"
          id="ae"
          name="fav_language"
          onChange={() => {
            setType("American Express");
          }}
          value="American Express"
          style={{ marginLeft: "10px" }}
        />
          <label for="ae">American Express</label> 
        <br /> 
        <input
          type="radio"
          id="oth"
          name="fav_language"
          value="Other"
          onChange={() => {
            setType("Other");
          }}
          style={{ marginRight: "5px" }}
        />
        <label for="oth">Other</label> 
        <input
          type="radio"
          id="Debit"
          name="fav_language"
          onChange={() => {
            setType("Debit");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="Debit">Debit</label> 
        <input
          type="radio"
          id="Account"
          name="fav_language"
          onChange={() => {
            setType("Account");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="Account">Account</label> 
        <input
          type="radio"
          id="EFT"
          name="fav_language"
          onChange={() => {
            setType("EFT");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="EFT">EFT</label> 
        <input
          type="radio"
          id="un"
          name="fav_language"
          onChange={() => {
            setType("Uncollectable Bad Debt");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="un">Uncollectable Bad Debt</label> 
        <input
          type="radio"
          id="cc"
          name="fav_language"
          onChange={() => {
            setType("Credit Card");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="cc">Credit Card</label> 
        <input
          type="radio"
          id="Square"
          name="fav_language"
          onChange={() => {
            setType("Square");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="Square">Square</label> 
        <div style={{ marginTop: "20px" }} className="row">
          <div className="col-sm-2 col-4">
            <label for="validationCustom01">Pay Amount </label>

            <input
              type="number"
              value={amount}
              onChange={(t) => {
                setAmount(t.target.value);
              }}
              id="fname"
              name="name"
              placeholder="Payment Amount"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">
              Payment Reference Number (optional){" "}
            </label>

            <input
              type="number"
              value={ref}
              onChange={(t) => {
                setRef(t.target.value);
              }}
              id="fname"
              name="name"
              placeholder="Payment Reference Number (optional)"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2 col-4">
            <label for="validationCustom01">Date </label>

            <input
              type="date"
              value={date}
              onChange={(t) => {
                setDate(t.target.value);
              }}
              id="fname"
              name="name"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-2 col-4">
            <label for="validationCustom01">Time</label>

            <input
              type="time"
              value={time}
              onChange={(t) => {
                setTime(t.target.value);
              }}
              id="fname"
              name="name"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <button
          style={{
            backgroundColor: "#3fa66c",
            marginTop: "20px",
            width: "80px",
          }}
          type="button"
          className="btn btn-secondary"
          onClick={setRecord}
        >
          Pay
        </button>
        <button
          style={{
            backgroundColor: "red",
            marginTop: "20px",
            width: "80px",
            marginLeft: "20px",
          }}
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            history.push("/row");
          }}
        >
          Cancel
        </button>
      </form>
    </div>
  );
}
export default Record;
