import React, {useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, useHistory } from "react-router-dom";


const Test = () => {

  const [all, setAll] = useState([]);
  useEffect(() => {
      setAll(JSON.parse(localStorage.getItem("AllImageImpound")))
  }, []);

 
 


  return (
    <div className="container">
   {all.map((i, index) => (
                        
                        
                        <>
                           <img
                         
                           
                         src={i.name} alt={i.name}
                       />
                       <br />
                       
                       
                       </>
                         
                        
                    
                      ))}
    </div>
  );
};

export default Test;
