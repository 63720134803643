import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import axios from "axios"; 

const Test = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [dis, setDis] = useState("no");

  const Up = async (e) => {
    e.preventDefault();
    if (dis === "image") {
      const data = new FormData();
      data.append("file", name);
      let a = localStorage.getItem("id_row");
      data.append("mid", a);

      let url = "/account/apis/image_upload.php";

      axios.post(url, data, {}).then((res) => {
        history.push("/row");
       console.log(name);
      });
    } else {
      alert("No image is selected");
    }
  };


  return (
    <div style={{ marginTop: "50px" }} className="container">
      <center>
        <form
          onSubmit={(e) => {
            Up(e);
          }}
        >
          <input
            style={{ width: "600px" }}
            type="file"
            onChange={(e) => {
              setName(e.target.files[0]);
              setDis("image");
            }}
            className="form-control"
          />

          <button
            type="submit"
            style={{ marginTop: "20px", backgroundColor: "#3fa66c" }}
            className="btn btn-secondary"
          >
            {" "}
            Submit
          </button>
        </form>
      </center>
    </div>
  );
};

export default Test;
