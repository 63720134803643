import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory, Link } from "react-router-dom";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/PulseLoader";
import moment from "moment";
import { Trash } from "react-bootstrap-icons";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function App() {
  const [displyXX, setDisply] = useState("user");
  const [loading, setLoading] = useState(true);
  const [allreleaseN, setAllReleaseN] = useState([]);
  const [allimpound, setAllimpound] = useState([]);
  const [imgg, setAllimg] = useState([]);
  const [allhistory, setAllHistory] = useState([]);
  const [allrecord, setAllRecord] = useState([]);

  const [invoiceSubTotal, setinvoiceSubTotal] = useState(0);
  const [invoiceTaxes, setinvoiceTaxes] = useState(0);
  const [invoiceMasterTotal, setinvoiceMasterTotal] = useState(0);
  const [row, setRow] = useState();
  const [date, setCurDate] = useState("");
  const [blance, setBlance] = useState("");
  const [imageShow, setimgShow] = useState(false);
  const [hazmatBtn, sethazmatBtn] = useState(false);

  const [invoice, setInvoice] = useState([]);
  const [currentPrint, setCurrentPrint] = useState([]);

  const getServerHistory = async (id) => {
    await fetch(`/account/apis/history.php?id=${id}`)
      .then((response) => response.json())
      .then((json) => {
        setAllHistory(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // getServer impound id and paymentRecord
  const getServer = async (id, jjson) => {
    await fetch(`/account/apis/impound_row.php?id=${id}`)
      .then((response) => response.json())
      .then((json) => {



        localStorage.setItem("byimpound",JSON.stringify(json) );
console.log("getserver json",json)


        const as = moment(json[0].date_im).format("MMMM DD, YYYY");

        json[0].date_im = as;
        const num = moment(json[0].time_im, ["HH.mm"]).format("hh:mm A");

        if (json[0].st === "waiting") {
         
          
          const int = getNumberOfDays(as, Date.now());
          console.log("int",int)
          const arrayToBeChanged = JSON.parse(json[0].array);

          console.log("arrayToBeChanged",arrayToBeChanged)

          arrayToBeChanged[1].quantity = int;
          arrayToBeChanged[1].total = int * arrayToBeChanged[1].price;
          const Total = arrayToBeChanged.reduce((n, { total }) => n + total, 0);

          json[0].time_im = num + " (" + int + " days)";
          json[0].sub = Total;

          json[0].tot = Total + Number(json[0].tax);

          setinvoiceMasterTotal(Total + Number(json[0].tax));

          setinvoiceSubTotal(Total);
          setinvoiceTaxes(Number(json[0].tax));
          setInvoice(arrayToBeChanged);

          localStorage.setItem(
            "invoice_array",
            JSON.stringify(arrayToBeChanged)
          );
          localStorage.setItem("invoice_sub", Total);
          localStorage.setItem("invoice_tax", Number(json[0].tax));
          localStorage.setItem("invoice_total", Total + Number(json[0].tax));
        } else if (json[0].st === "relese") {
          json[0].time_im = num;
          setinvoiceMasterTotal(json[0].tot);
          setinvoiceSubTotal(json[0].sub);
          setinvoiceTaxes(json[0].tax);
          setInvoice(JSON.parse(json[0].array));
        }
         
        localStorage.setItem("amount", json[0].tot);

        if (jjson[0].msg === "pass") {
          const Record_totl = jjson.reduce(
            (n, { amount }) => n + Number(amount),
            0
          );

          setBlance(
            json[0].tot -
              Number(Record_totl) +
              " as of " +
              moment().format("MMMM/DD/YYYY")
          );

          localStorage.setItem("amount", json[0].tot - Number(Record_totl));
        }

        

        setAllimpound(json);
        


        setCurDate("as of " + moment().format("MMMM/DD/YYYY"));
        getServerHistory(id);
        img(id);
        setLoading(!loading)
        
      })
      .catch((err) => {
       // console.log(err);
      });
  };
  const getServerRecordPayment = async (id) => {
    await fetch(`/account/apis/recordview.php?id=${id}`)
      .then((response) => response.json())
      .then((json) => {
        console.log("get record ",json);
        setAllRecord(json);
        getServer(id, json);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getServerRelease = async (a) => {
    await fetch(`/account/apis/releseview.php?id=${a}`)
      .then((response) => response.json())
      .then((json) => {
        setAllReleaseN(json);
        setRow(a);

        getServerRecordPayment(a);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const img = async (id) => {
    await fetch(`/account/apis/img.php?mid=${id}`)
      .then((response) => response.json())
      .then((json) => {
        setAllimg(json);
        localStorage.setItem("AllImageImpound",JSON.stringify(json) );

      })
      .catch((err) => {
        console.log(err);
      });
  };
  const del = async () => {
    await fetch(`/account/apis/del.php?id=${row}&t=impound`)
      .then((response) => response.json())
      .then((json) => {
        history.push("/report");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const undoRelease = async () => {
    await fetch(`/account/apis/undorelease.php?id=${row}`)
      .then((response) => response.json())
      .then((json) => {
        alert("Successfully Undo Release");
        history.push("/report");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const undopayment = async (id) => {
    await fetch(`/account/apis/undopayment.php?id=${id}`)
      .then((response) => response.json())
      .then((json) => {
        alert("Successfully Undo Payment completed");
        history.push("/report");
      })
      .catch((err) => {
       // console.log(err);
      });
  };
  const history = useHistory();

  function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);
    if (diffInDays < 1) {
      diffInDays = 1;
    }

    return diffInDays;
  }
  const getServerHazmat = async (id) => {
    await fetch(`/account/apis/view_claim.php?id=${id}`)
      .then((response) => response.json())
      .then((json) => {

        if(json[0].msg==="pass")
        { console.log("hazmat already ") 
          sethazmatBtn(true)

        }else{
          console.log("hazmat add now ") 
          sethazmatBtn(false)

        }
       
       
       
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    let a = localStorage.getItem("id_row");
    getServerHazmat(a)

    if (a === null) {
      history.push("/report");
    } else {
      console.log("value a is ",a);
      getServerRelease(a);
    }
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));
    console.log("value v is ",v);

    if (v == null) {
    } else {
      if (v[0].type === "user") {
      } else {
        setDisply("company");
      }
    }
  }, []);

  return (
    <div
      style={{ background: "white", display: "block", flex: 1, padding: 30 }}
    >
      <strong
        style={{ background: "white" }}
        className="d-inline-block mb-2 text-success"
      >
        <h4> Impounds / Lot Management</h4>{" "}
      </strong>

      {loading === true ? (
        <center>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <MoonLoader
            color="green"
            loading={loading}
            css={override}
            size={15}
            margin={10}
          />
        </center>
      ) : (
        <>
          <p>
            {displyXX === "user" ? (
              <button
                style={{
                  backgroundColor: "#3fa66c",
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
                className="btn btn-secondary"
                onClick={() => history.push("/modify")}
              >
                Modify Impound
              </button>
            ) : (
              ""
            )}
            <button
              style={{ backgroundColor: "#3fa66c", marginBottom: "10px" }}
              className="btn btn-secondary"
              onClick={() => history.push("/record")}
            >
              {" "}
              Record Payment
            </button>

            {allimpound.length === 0 ? (
              ""
            ) : allimpound[0].des === "" ? (
              <button
                style={{
                  backgroundColor: "#3fa66c",
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
                onClick={() =>
                  allimpound[0].police === "yes"
                    ? alert("Please uplift the Police Hold")
                    : history.push("/release")
                }
                className="btn btn-secondary"
              >
                Release Vehicle
              </button>
            ) : (
              <button
                style={{
                  backgroundColor: "#3fa66c",
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
                className="btn btn-secondary"
                onClick={() =>
                  displyXX === "company"
                    ? undoRelease()
                    : alert("Please contact Admin to Undo Release")
                }
              >
                {" "}
                Undo Release
              </button>
            )}

            <Link
              style={{
                backgroundColor: "#3fa66c",

                marginRight: "10px",
                marginBottom: "10px",
              }}
              className="btn btn-secondary"
              to="print"
              target="_blank"
            >
              Print Invoice
            </Link>
            <button
              style={{ backgroundColor: "#3fa66c", marginBottom: "10px" }}
              className="btn btn-secondary"
              onClick={() => history.push("/image")}
            >
              {" "}
              Images Upload
            </button>

            {displyXX === "company" ? (
              <button
                style={{
                  backgroundColor: "red",
                  marginLeft: "10px",
                
                  marginBottom: "10px",
                }}
                className="btn btn-secondary"
                onClick={() => del()}
              >
                {" "}
                Delete
              </button>
            ) : (
              ""
            )}

            {
              hazmatBtn===true? <Link
              style={{
                backgroundColor: "#FFCC00",
                color:"black",

                marginLeft: "10px",
                  marginRight: "10px",
                  marginBottom: "10px",
              }}
              className="btn btn-secondary"
              to="claimupdate"
              
            >
            Haz Mat
            </Link>
            : <Link
            style={{
              backgroundColor: "#FFCC00",
              color:"black",

              marginLeft: "10px",
                marginRight: "10px",
                marginBottom: "10px",
            }}
            className="btn btn-secondary"
            to="newclaim"
            
          >
          Haz Mat
          </Link>
            }
           
          </p>
          <div>
            {allimpound.map((i, index) => (
              <table class="table table-sm">
                <tr>
                  <th colSpan="2">
                    {i.police === "yes" ? (
                      <blockquote
                        style={{
                          background: "#f72323",
                          color: "white",
                        }}
                        className="blockquote text-center"
                      >
                        <p className="mb-1">
                          There is a police hold on this Vehicle{" "}
                        </p>
                      </blockquote>
                    ) : (
                      ""
                    )}
                  </th>
                </tr>
                <tr>
                  <th colSpan="2">
                    <h3>
                      Impound Details for {i.invoie} ({i.make} {i.model}{" "}
                      {i.year} {" ("}
                      {i.color}
                      {")"}){i.des === "" ? "" : " (RELEASED)"}
                    </h3>
                  </th>
                </tr>
                <tr>
                  <th>Impound Total</th>

                  <td>
                    ${i.tot} {i.des === "" ? date : ""}
                  </td>
                </tr>
                {blance === "" ? (
                  ""
                ) : (
                  <tr>
                    <th>Balance Due</th>
                    <td>
                      {blance < 0 ? "($" + blance * -1 + ")" : "$" + blance}
                    </td>
                  </tr>
                )}
                {i.des == "" ? (
                  ""
                ) : (
                  <tr>
                    <th>Date Released</th>
                    <td>{moment(i.des).format("MMMM DD, YYYY")}</td>
                  </tr>
                )}
                {i.des == "" ? (
                  ""
                ) : (
                  <tr>
                    <th>Release Type</th>
                    <td>{allreleaseN[0].type}</td>
                  </tr>
                )}

                {i.des == "" ? (
                  ""
                ) : (
                  <tr>
                    <th>Release to</th>
                    <td>
                      {allreleaseN[0].name}
                      {allreleaseN[0].phone === "" ? "" : <br />}
                      {allreleaseN[0].phone}
                      {allreleaseN[0].time === "" ? "" : <br />}
                      {allreleaseN[0].time}
                      {allreleaseN[0].city === "" ? "" : <br />}
                      {allreleaseN[0].city}
                      {allreleaseN[0].lic === "" ? "" : <br />}
                      {allreleaseN[0].lic}
                      {allreleaseN[0].licex === "" ? "" : <br />}
                      {allreleaseN[0].licex}
                      {allreleaseN[0].zip === "" ? "" : <br />}
                      {allreleaseN[0].zip}
                      {allreleaseN[0].state === "" ? "" : <br />}
                      {allreleaseN[0].state}
                      {allreleaseN[0].lictype === "" ? "" : <br />}
                      {allreleaseN[0].lictype}
                      {allreleaseN[0].add === "" ? "" : <br />}
                      {allreleaseN[0].add}
                    </td>
                  </tr>
                )}

                <tr>
                  <th>Date Impounded</th>
                  <td>
                    {i.date_im}
                    {" / "}
                    {i.time_im}
                  </td>
                </tr>
                <tr>
                  <th>Towed From</th>
                  <td>{i.pick}</td>
                </tr>
                <tr>
                  <th>Driver</th>
                  <td>{i.driver}</td>
                </tr>
                <tr>
                  <th>Vehicle Description</th>
                  <td>
                    {i.make} {i.model} {i.year} {" ("}
                    {i.color}
                    {") "}
                  </td>
                </tr>
                <tr>
                  <th>License Plate # </th>
                  <td>{i.license}</td>
                </tr>
                <tr>
                  <th>Reason for Impound</th>
                  <td>{i.reason}</td>
                </tr>
                <tr>
                  <th>Account </th>
                  <td>{i.account}</td>
                </tr>

                <tr>
                  <th>VIN </th>
                  <td>{i.vin}</td>
                </tr>
                <tr>
                  <th>Have Keys </th>
                  <td>{i.have_keys=="no"?"No":"Yes"}</td>
                </tr>
                <tr>
                  <th>Drivable </th>
                  <td>{i.drivable=="no"?"No":"Yes"}</td>
                </tr>
{
  i.auction==""?"": <tr>
  <th>Auction</th>
  <td>{i.auction} Days</td>
</tr>
}
               

                <tr>
                  <th>Notes </th>
                  <td>{i.notes}</td>
                </tr>
              </table>
            ))}
            <blockquote
              style={{
                paddingLeft: "5px",
                background: "#7DCEA0",
                color: "white",
              }}
              className="blockquote text-right"
            >
              <p className="mb-1">Invoice Charges </p>
            </blockquote>

            <table class="table">
              <tr>
                <th>Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
              {invoice.map((i, index) => (
                <tr>
                  <td>{i.name}</td>
                  <td>{i.quantity}</td>
                  <td>${i.price}</td>
                  <td>${i.total}</td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <th>Sub Total</th>
                <th>${invoiceSubTotal}</th>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <th>Tax</th>
                <th>${invoiceTaxes}</th>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <th>Grand Total</th>
                <th>${invoiceMasterTotal}</th>
              </tr>
            </table>

            {allrecord.length === 0 ? (
              ""
            ) : allrecord[0].msg === "pass" ? (
              <>
                <blockquote
                  style={{
                    paddingLeft: "5px",
                    background: "#7DCEA0",
                    color: "white",
                  }}
                  className="blockquote text-right"
                >
                  {" "}
                  Payment Record
                </blockquote>
                <table class="table table-sm">
                  <tr>
                    <th> Type</th>
                    <th> Amount</th>
                    <th> Reference Number</th>
                    <th> Date</th>
                    {displyXX === "user" ? "" : <th>Action</th>}
                  </tr>
                  {allrecord.map((i, index) => (
                    <tr>
                      <td> {i.type}</td>
                      <td>${i.amount} </td>
                      <td>{i.ref} </td>
                      <td>{moment(i.date).format("MM/DD/YYYY")} </td>

                      {displyXX === "user" ? (
                        ""
                      ) : (
                        <td
                          style={{ color: "red" }}
                          onClick={() => undopayment(i.id)}
                        >
                          <Trash
                            style={{ marginLeft: "10px" }}
                            color="red"
                            size={25}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </table>
              </>
            ) : (
              ""
            )}

            {allhistory.length === 0 ? (
              ""
            ) : (
              <>
                <blockquote
                  style={{
                    paddingLeft: "5px",
                    background: "#7DCEA0",
                    color: "white",
                  }}
                  className="blockquote text-right"
                >
                  {" "}
                  History
                </blockquote>
                <table class="table table-sm">
                  <tr>
                    <th> Impound History</th>
                    <th> Time Stamp</th>
                    <th> Performed By</th>
                  </tr>
                  {allhistory.map((i, index) => (
                    <tr>
                      <td>Impound details modified.</td>
                      <td> {moment(i.date).format("MM/DD/YYYY, h:mm A")} </td>
                      <td>{i.uname} </td>
                    </tr>
                  ))}
                </table>
              </>
            )}

            {imgg.length === 0 ? (
              ""
            ) : (
              <>
                <blockquote
                  style={{
                    paddingLeft: "5px",
                    background: "#7DCEA0",
                    color: "white",
                  }}
                  className="blockquote text-right"
                >Photographs
                </blockquote>
                <center>
                  <table>
                    <tr>
                      {imgg.map((i, index) => (
                        <Link
                        style={{
                         
          
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                        className="btn btn-secondary"
                        to="imageview"
                        target="_blank"
                      >
                        <td style={{ padding:"2px" }}>
                           <img
                         
                            style={{ height: "150px", width: "150px" }}
                            src={i.name} alt={i.name}
                          />
                         
                        </td>
                        </Link>
                      ))}
                    </tr>
                  </table>
                </center>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default App;
