import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Trash } from "react-bootstrap-icons";
import { BrowserRouter as Router, Link } from "react-router-dom";

function ViewTruck() {
  const [allCom, setAllCom] = useState([]);

  const getAllCompanyServer = async () => {
    await fetch(`/account/apis/companylist.php`)
      .then((response) => response.json())
      .then((json) => {
        setAllCom(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY_MASTER"));

    if (v === null) {
    } else {
      getAllCompanyServer();
    }
  }, []);

  const del = async (id) => {
    await fetch(`/account/apis/del.php?id=${id}`)
      .then((response) => response.json())
      .then((json) => {
        //getAllDriverServer(cid);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container-sm">
      <p>this is home page</p>
    </div>
  );
}
export default ViewTruck;
