import React, { useState,useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import moment from "moment";
import axios from "axios";

const Test = () => {
  const history = useHistory();
  
  let id = localStorage.getItem("id_row");

  const [log, setLog] = useState([]);
  const [idc, setIdc] = useState("");
  const [incidentDate, setIncidentDate] = useState("");
  const [incidentTimeStart, setIncidentTimeStart] = useState("");
  const [incidentTimeEnd, setIncidentTimeEnd] = useState("");
  const [claimtype, setclaimtype] = useState("");
  const [towingIncident, setTowingIncident] = useState("");
  const [incidentLocation, setIncidentLocation] = useState("");
  const [yards, setYard] = useState("");
  const [selectedSceneActivities, setSelectedSceneActivities] = useState([]);
  const [hazmat, sethazmat] = useState("");
  const [lawEnforcement, setlawEnforcement] = useState("");
  const [officerName, setOfficerName] = useState("");
  const [enforcementCase, setEnforcementCase] = useState("");
  const [description, setdescription] = useState("");
  const [prove, setprove] = useState("");
  const [selectedSpilledFluid, setSelectedSpilledFluid] = useState([]);
  const [selectedSpillDetails, setSelectedSpillDetails] = useState([]);
  const [selectedLiquidDebris, setSelectedLiquidDebris] = useState([]);
  const [lawCase, setLawCase] = useState("no");
  const [spillsize, setSpillsize] = useState("");
  const [Narrative, setNarrative] = useState("");


  const [Driver, setDriver] = useState([]);
  const [labor, setLabor] = useState([]);
  const [consumables, setConsumables] = useState([]);
  const [Equipment, setEquipment] = useState([]);
  const [Fee, setFee] = useState([]);

  const [invoice, setInvoice] = useState([]);
  
  const [imglicense, setimglicense] = useState();
  const [imgplate, setimgplate] = useState();
  const [imgcard, setimgcard] = useState();
  const [imgvehiclecard, setimgvehiclecard] = useState();
  const [imgspill, setimgspill] = useState();
  const [imgafter, setimgafter] = useState();
  const [imga, setimga] = useState();
  const [imgb, setimgb] = useState();
  const [imgc, setimgc] = useState();
  const [go, setGo] = useState();

  const getServerReview = async () => {
    await fetch(`/account/apis/view_claim.php?id=${id}`)
      .then((response) => response.json())
      .then((json) => {

        setIdc(json[0].id)
        setIncidentDate(json[0].incidentdate)
        setIncidentTimeStart(json[0].incidentstart)
        setIncidentTimeEnd(json[0].incidentend)
        setclaimtype(json[0].claimtype)
        setTowingIncident(json[0].towingincident)
        setIncidentLocation(json[0].incidentlocation)
        setYard(json[0].yards)
        setSelectedSceneActivities(JSON.parse(json[0].activities))
        sethazmat(json[0].hazmat)
        setlawEnforcement(json[0].enforcement)
        setOfficerName(json[0].officername)
        setEnforcementCase(json[0].enforcementcase)
        setdescription(json[0].enforcementcasedescription)
        setprove(json[0].officerapproval)
        setSelectedSpilledFluid(JSON.parse(json[0].fluid))
        setSelectedSpillDetails(JSON.parse(json[0].details))
        setSelectedLiquidDebris(JSON.parse(json[0].debris))
        setSpillsize(json[0].size)
        setNarrative(json[0].narrative)
        setLabor(JSON.parse(json[0].labor))
        setConsumables(JSON.parse(json[0].consumables))
        setEquipment(JSON.parse(json[0].equipment))
        setFee(JSON.parse(json[0].disposalfees))
        setDriver(JSON.parse(json[0].driver))
        setimglicense(json[0].imglicense)
        setimgplate(json[0].imgplate)
        setimgcard(json[0].imgcard)
        setimgvehiclecard(json[0].imgvehiclecard)
        setimgspill(json[0].imgspill)
        setimgafter(json[0].imgafter)
        setimga(json[0].imga)
        setimgb(json[0].imgb)
        setimgc(json[0].imgc)
        setInvoice(JSON.parse(json[0].invoice))

        console.log(JSON.parse(json[0].invoice))
       
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {

    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));

   
      if (v === null) {
      history.push("/login");
    } else {
      console.log(v);
      setLog(v)
      if (id === null) {
        history.push("/report");
      } else {
       
          getServerReview();
      }
       
    }

  
   
  }, []);
  return (
    <div style={{ marginTop: "50px" }} className="container">
          
          <center>  <h3 onClick={()=>{ window.print()}} ><label style={{ color: "grey",}} >Hazardous Waste Invoice</label></h3></center>

<h5>{yards}</h5>
<p>{log.length>0?<>{ log[0].cadd} </>:<></>}</p>
<p style={{marginTop:"-15px"}}>{log.length>0?<>{ log[0].ccity} </>:<></>} {log.length>0?<>{ log[0].cstate} </>:<></>} {log.length>0?<>{ log[0].czip} </>:<></>}</p>
<p  style={{marginTop:"-15px"}}>{log.length>0?<>{ log[0].cphone} </>:<></>}</p>
<p style={{backgroundColor:"grey",height:"20px",width:"100%"}}>

</p>

<table class="table table-sm">
<tr>
      <th><h3 style={{marginTop:"15px"}}>Scene</h3></th>
    </tr>
    <tr>
      <th> Print Date </th>
      <th> {moment().format("MM/DD/YYYY")}</th>
    </tr>


    <tr>
    <th>Incident Date</th>
      <td>{incidentDate}</td>
      <th>Incident Start Time</th>
      <td>{incidentTimeStart}</td>
    </tr>
    <tr>
      <th>Incident End Time</th>
      <td>{incidentTimeEnd}</td>
      <th>Claim Type</th>
      <td>{claimtype}</td>
    </tr>
  
    <tr>
      <th>Towing Incident</th>
      <td>{towingIncident}</td>
      <th>Scene Activities</th>
      <td>{selectedSceneActivities.map((v,i)=>(

<>{v.value} {" - "}</> 
))}</td>
    </tr>
    <tr>
      <th>Hazmat Response</th>
      <td>{hazmat}</td>
    </tr>
 

    <tr>
      <th colSpan={2}><h3 style={{marginTop:"20px"}}>Law Enforcement</h3></th>
    </tr>

    <tr>
      <th>Law Enforcement on Scene</th>
      <td>{lawEnforcement}</td>
      <th>Officer Name</th>
      <td>{officerName}</td>
    </tr>
    <tr>
      <th>Law Enforcement Case #</th>
      <td>{enforcementCase}</td>
      <th>Law Enforcement Case Description</th>
      <td>{description}</td>
    </tr>
    <tr>
      <th>Officer Approval</th>
      <td>{prove=="yes"?"Yes":"No"}</td>
    </tr>

    <tr>
      <th>Spilled Fluid</th>
      <td>{selectedSpilledFluid.map((v,i)=>(

<>{v.value} {" - "}</> 
))}</td>
      <th>Liquid Debris</th>
      <td>{selectedLiquidDebris.map((v,i)=>(

<>{v.value} {" - "}</> 
))}</td>
    </tr>
    <tr>
      <th>Spill Size</th>
      <td>{spillsize}</td>
      <th>Narrative</th>
      <td>{Narrative}</td>
    </tr>
    <tr>
      <th colspan={2}><h3 style={{marginTop:"20px"}}>Driver's Information</h3></th>
    </tr>
    {
      Driver.map((v,i)=>(
        <>
                            <tr>
        <th>Driver Name</th>
        <td>{v.name}</td>
        <th>Phone Number</th>
        <td>{v.phone}</td>
      </tr>
      <tr>
        <th>Company Name</th>
        <td>{v.company}</td>
        <th>DL State</th>
        <td>{v.dlsate}</td>
      </tr>
      <tr>
        <th>DL Number</th>
        <td>{v.dlnumber}</td>
        <th>Driver Approval</th>
        
        <td>{v.permission=="yes"?"Yes":"No"}</td>
      </tr>
      <tr>
        <th>Street</th>
        <td>{v.street}</td>
        <th>Line</th>
        <td>{v.line}</td>
      </tr>
      <tr>
        <th>City</th>
        <td>{v.city}</td>
        <th>State</th>
        <td>{v.state}</td>
      </tr>
      <tr>
        <th>Zip</th>
        <td>{v.zip}</td>
       
      </tr>
      <tr>
      <th colspan={2}><h3 style={{marginTop:"20px"}}>Vehicle's Information</h3></th>
    </tr>
      <tr>
        <th>License Plate Number</th>
        <td>{v.platcar}</td>
        <th>License Plate State</th>
        <td>{v.stateecar}</td>
      </tr>
      <tr>
        <th>Year</th>
        <td>{v.yearcar}</td>
        <th>Make</th>
        <td>{v.makecar}</td>
      </tr>
      <tr>
        <th>Model</th>
        <td>{v.modelcar}</td>
        <th>Vin</th>
        <td>{v.vincar}</td>
      </tr>
      <tr>
        <th>Reason for No Insurance</th>
        <td>{v.reason}</td>
        <th>Insurance Company Name</th>
        <td>{v.insurance}</td>
      </tr>
      <tr>
        <th>Policy Number</th>
        <td>{v.policy}</td>
        <th>Agent Phone Number</th>
        <td>{v.agent}</td>
      </tr>
  
        </>
      ))
    }



    </table>
   






  <div>
    

{
  labor.length<1?<></>:<><h5 style={{marginTop:"40px"}}>Labor</h5></>
}

<table style={{ border:" 1px solid",textAlign:"center",width:"100vh"}}>
  <tr>
    <th style={{ border:" 1px solid"}}>Name</th>
    <th style={{ border:" 1px solid"}}>Rank</th>
    <th style={{ border:" 1px solid"}}>Hours</th>
    <th style={{ border:" 1px solid"}}>Cost</th>
    <th style={{ border:" 1px solid"}}>Total</th>
    
  </tr>
{
  labor.map((v,i)=>(
    <>
    <tr>
      <td style={{ border:" 1px solid"}}>{v.namel}</td>
      <td style={{ border:" 1px solid"}}>{v.rankl}</td>
      <td style={{ border:" 1px solid"}}>{v.hoursl}</td>
      <td style={{ border:" 1px solid"}}>${v.costl}</td>
      <td style={{ border:" 1px solid"}}>${v.totall}</td>
    </tr>
    {/* <tr>
      <td style={{ border:" 1px solid",textAlign:"left"}} colSpan={5}><b>Scene:</b> {v.scenel.map((c,ii)=>(
        <>{c.value}</>  ))}</td>
    </tr> */}
    </>

  ))
}


</table>
{
  labor.length<1?<></>:<><h5 style={{marginTop:"20px"}}>Vehicular Fluid & Gas Disposal</h5></>
}

<table style={{ border:" 1px solid",textAlign:"center",width:"100vh"}}>
  <tr>
    <th style={{ border:" 1px solid"}}>Name</th>
    <th style={{ border:" 1px solid"}}>Rank</th>
    <th style={{ border:" 1px solid"}}>Drum</th>
    <th style={{ border:" 1px solid"}}>Cost</th>
    <th style={{ border:" 1px solid"}}>Total</th>
    
  </tr>
{
  labor.map((v,i)=>(
    <>
    <tr>
      <td style={{ border:" 1px solid"}}>{v.namev}</td>
      <td style={{ border:" 1px solid"}}>{v.rankv}</td>
      <td style={{ border:" 1px solid"}}>{v.drumv}</td>
      <td style={{ border:" 1px solid"}}>${v.costv}</td>
      <td style={{ border:" 1px solid"}}>${v.totalv}</td>
    </tr>
    </>

  ))
}


</table>


{
  consumables.length<1?<></>:<><h5 style={{marginTop:"20px"}}>Consumables</h5></>
}

<table style={{ border:" 1px solid",textAlign:"center",width:"100vh"}}>
  <tr>
    <th style={{ border:" 1px solid"}}>Name</th>
    
    <th style={{ border:" 1px solid"}}>Unit Of Measurement</th>
    <th style={{ border:" 1px solid"}}>Quantity </th>
    <th style={{ border:" 1px solid"}}>Cost</th>
    <th style={{ border:" 1px solid"}}>Total</th>
    
  </tr>
{
  consumables.map((v,i)=>(
    <>
    <tr>
      <td style={{ border:" 1px solid"}}>{v.name}</td>
      <td style={{ border:" 1px solid"}}>{v.unit}</td>
      <td style={{ border:" 1px solid"}}>{v.quantity}</td>
      
      <td style={{ border:" 1px solid"}}>${v.cost}</td>
      <td style={{ border:" 1px solid"}}>${v.total}</td>
    </tr>
    </>

  ))
}


</table>

{
  Equipment.length<1?<></>:<><h5 style={{marginTop:"20px"}}>Equipment</h5></>
}

<table style={{ border:" 1px solid",textAlign:"center",width:"100vh"}}>
  <tr>
    <th style={{ border:" 1px solid"}}>Type</th>
    
    <th style={{ border:" 1px solid"}}>ID</th>
    <th style={{ border:" 1px solid"}}>Quantity </th>
    <th style={{ border:" 1px solid"}}>Hours</th>
    <th style={{ border:" 1px solid"}}>Cost</th>
    <th style={{ border:" 1px solid"}}>Total</th>
    
  </tr>
{
  Equipment.map((v,i)=>(
    <>
    <tr>
      <td style={{ border:" 1px solid"}}>{v.type}</td>
      <td style={{ border:" 1px solid"}}>{v.idd}</td>
      <td style={{ border:" 1px solid"}}>{v.quantity}</td>
      <td style={{ border:" 1px solid"}}>{v.hours}</td>
      
      <td style={{ border:" 1px solid"}}>${v.cost}</td>
      <td style={{ border:" 1px solid"}}>${v.total}</td>
    </tr>
    </>

  ))
}


</table>


{
  Fee.length<1?<></>:<><h5 style={{marginTop:"20px"}}>Disposal Fees</h5></>
}

<table style={{ border:" 1px solid",textAlign:"center",width:"100vh"}}>
  <tr>
    <th style={{ border:" 1px solid"}}>Type</th>
    
    <th style={{ border:" 1px solid"}}>Unit of Measurement</th>
    <th style={{ border:" 1px solid"}}>Quantity </th>
  
    <th style={{ border:" 1px solid"}}>Cost</th>
    <th style={{ border:" 1px solid"}}>Total</th>
    
  </tr>
{
  Fee.map((v,i)=>(
    <>
    <tr>
      <td style={{ border:" 1px solid"}}>{v.type}</td>
      <td style={{ border:" 1px solid"}}>{v.unit}</td>
      <td style={{ border:" 1px solid"}}>{v.quantity}</td>
       <td style={{ border:" 1px solid"}}>${v.cost}</td>
      <td style={{ border:" 1px solid"}}>${v.total}</td>
    </tr>
    </>

  ))
}


</table>
{
  invoice.length<1?<></>:<><h5 style={{marginTop:"20px"}}>Grand Total</h5></>
}

<table style={{ border:" 1px solid",textAlign:"center",width:"100vh"}}>
  <tr>
    <th style={{ border:" 1px solid"}}>Labor + Vehicular Fluid & Gas Disposal + Consumables + Equipment + Disposal Fees </th>
    
  
    
 
{
  invoice.map((v,i)=>(
    <>
   
      <th style={{ border:" 1px solid",textAlign:"center",padding:"10px",marginTop:"5px"}}><h5>${v.total}</h5></th>
      
    
    </>

  ))
}

</tr>
</table>





  </div>

{
  Driver.map((v,i)=>(
    <>

<h3 style={{marginTop:"30px"}}>Photographs
</h3>
<div>
<label style={{marginTop:"20px",fontSize:"15"}}><b>Driver License</b></label>
<br/>
<img  className="img-thumbnail" alt="Cinque Terre" width="30%" height="30%"  src={imglicense} />
</div>
<div>
<label style={{marginTop:"20px",fontSize:"15"}}><b>License Plate</b></label>
<br/>
<img  className="img-thumbnail" alt="Cinque Terre" width="30%" height="30%"  src={imgplate}  />
</div>
<div>
<label style={{marginTop:"20px",fontSize:"15"}}><b>Insurance Card</b></label>
<br/>
<img   className="img-thumbnail" alt="Cinque Terre" width="30%" height="30%" src={imgcard} />
</div>
<div>
<label style={{marginTop:"20px",fontSize:"15"}}><b>Pre Vehicle Scene</b></label>
<br/>
<img  className="img-thumbnail" alt="Cinque Terre" width="30%" height="30%"    src={imgvehiclecard} />
</div>
<div>
<label style={{marginTop:"20px",fontSize:"15"}}><b>Spill</b></label>
<br/>
<img   className="img-thumbnail" alt="Cinque Terre" width="30%" height="30%"   src={imgspill} />
</div>
<div>
<label style={{marginTop:"20px",fontSize:"15"}}><b>After Vehicle Scene</b></label>
<br/>
<img   className="img-thumbnail" alt="Cinque Terre" width="30%" height="30%"   src={imgafter} />
</div>
<div>
<label style={{marginTop:"20px",fontSize:"15"}}><b>Other 1</b></label>
<br/>
<img    className="img-thumbnail" alt="Cinque Terre" width="30%" height="30%"  src={imga} />
</div>
<div>
<label  style={{marginTop:"20px",fontSize:"15"}}><b>Other 2</b></label>
<br/>
<img   className="img-thumbnail" alt="Cinque Terre" width="30%" height="30%"   src={imgb} />
</div>
<div>
<label style={{marginTop:"20px",fontSize:"15"}}><b>Other 3</b></label>
<br/>
<img className="img-thumbnail" alt="Cinque Terre" width="30%" height="30%"   src={imgc} />
</div>
</>
  ))
}

    </div>
  );
};


export default Test;
