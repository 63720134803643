import React, { useEffect, useState } from "react";
import "./App.css";
import Newimpound from "./MyComponents/Newimpound";
import Newtruck from "./MyComponents/Newtruck";
import NewDriver from "./MyComponents/NewDriver";
import Login from "./MyComponents/login";
import Navbar from "./MyComponents/Navbar";
import ViewDriver from "./MyComponents/ViewDriver";
import ViewTruck from "./MyComponents/ViewTruck";
import Home from "./MyComponents/home";
import Report from "./MyComponents/Reports";
import ViewUser from "./MyComponents/ViewUser";
import User from "./MyComponents/user";
import Logout from "./MyComponents/logout";
import ImpoundRow from "./MyComponents/ImpoundRow";
import Modify from "./MyComponents/modify";
import T from "./MyComponents/Report";
import Invoice from "./MyComponents/invoice";
import Test from "./MyComponents/Test";
import ImageUpload from "./MyComponents/ImageUpload";
import Relese from "./MyComponents/relese";
import Record from "./MyComponents/record";
import Print from "./MyComponents/print";
import MasterLogin from "./master/login_master";
import Company from "./master/company";
import Newcompany from "./master/newcompany";
import MasterNavbar from "./master/NavbarMaster";
import MasterReports from "./master/reports";
import MasterHome from "./master/home";
import AllPrint from "./MyComponents/printall";
import Header from "./MyComponents/header";
import Claim from "./claim/claim";
import ClaimUpadte from "./claim/update";
import ClaimReview from "./claim/review";
import ImageView from "./MyComponents/Imageview";
import ImageUpdateUpload from "./claim/update_image";
import Payment_Claim from "./claim/payment";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  const [displyXX, setDisply] = useState("user");

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));
    if (v == null) {
    } else {
      if (v[0].type === "user") {
      } else {
        setDisply("company");
      }
    }
  }, []);

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Navbar />
            <Home />
          </Route>
          <Route path="/release">
            <Navbar />
            <Relese />
          </Route>
          <Route path="/record">
            <Navbar />
            <Record />
          </Route>
          <Route path="/print">
            <Print />
          </Route>

          <Route path="/row">
            <Navbar />
            <ImpoundRow />
          </Route>

          <Route path="/image">
            <Navbar />
            <ImageUpload />
          </Route>

          <Route path="/test">
            <Navbar />
            <Test />
          </Route>

          <Route path="/inv">
            <Navbar />
            <Invoice />
          </Route>
          <Route path="/truck">
            <Navbar />
            <Newtruck />
          </Route>

          <Route path="/logout">
            <Logout />
          </Route>

          <Route path="/driver">
            <Navbar />
            <NewDriver />
          </Route>

          <Route path="/alldriver">
            <Navbar />
            <ViewDriver />
          </Route>

          <Route path="/t">
            <Navbar />
            <T />
          </Route>

          <Route path="/alltruck">
            <Navbar />
            <ViewTruck />
          </Route>

          <Route path="/impound">
            <Navbar />
            <Newimpound />
          </Route>

          <Route path="/modify">
            <Navbar />
            <Modify />
          </Route>

          <Route path="/home">
            <Navbar />
            <Home />
          </Route>
          <Route path="/report">
            <Navbar />
            <Report />
          </Route>

          <Route path="/login">
            <Login />
          </Route>
          <Route path="/alluser">
            <Navbar />
            <ViewUser />
          </Route>
          <Route path="/user">
            <Navbar />
            <User />
          </Route>
          <Route path="/allprint">
            <AllPrint />
          </Route>

          <Route path="/masterlogin">
            <MasterLogin />
          </Route>
          <Route path="/company">
            <MasterNavbar />
            <Company />
          </Route>
          <Route path="/newcompany">
            <MasterNavbar />
            <Newcompany />
          </Route>
          <Route path="/selectcompany">
            <MasterNavbar />
            <MasterReports />
          </Route>
          <Route path="/masterhome">
            <MasterNavbar />
            <MasterHome />
          </Route>
          <Route path="/h">
            <Header />
          </Route>
          <Route path="/newclaim">
            <Claim />
          </Route>
          <Route path="/paymentclaim">
          <Navbar />
            <Payment_Claim />
          </Route>
          <Route path="/claimupdate">
          <Navbar />
            <ClaimUpadte />
          </Route>
          <Route path="/review">
          
            <ClaimReview />
          </Route>
          <Route path="/imageview">
          <Navbar />
            <ImageView />
          </Route>
          <Route path="/imageUpdateUpload">
          <Navbar />
            <ImageUpdateUpload />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
