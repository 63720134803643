import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form } from "react-bootstrap";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import {
  PlusCircleFill,
  Trash,
  Display,
  CircleFill,
} from "react-bootstrap-icons";

function Newimpound() {
  const [value, setValue] = useState([]);
  const [uname, setUname] = useState();
  const [cname, setCname] = useState("");
  const [cid, setCid] = useState(0);
  const [uid, setUid] = useState(0);
  const [aaccount, setAccountA] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phoneno, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [categoryn, setCategoryn] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [pickuplocation, setPickupLocation] = useState("");
  const [destination, setDestination] = useState("");
  const [reason, setReason] = useState("");

  const [invoiceno, setInvoiceNo] = useState("");
  const [impponddate, setImpoundDate] = useState("");
  const [impoundtime, setImpoundTime] = useState("");
  const [policehold, setPolice] = useState("no");
  const [license, setLicense] = useState("");
  const [Vehiclestate, setVehiclestate] = useState("");
  const [vin, setVin] = useState("");
  const [color, setColor] = useState("");
  const [model, setModel] = useState("");
  const [maker, setMaker] = useState("");
  const [year, setYear] = useState("");
  const [drivable, setDrivable] = useState("no");
  const [havekey, setHavekey] = useState("no");
  const [keylocation, setKeyLocation] = useState("");
  const [auction, setAuction] = useState("");
  const [driver, setDriver] = useState("");
  const [truck, setTruck] = useState("");
  const [note, setNote] = useState("");
  const [allDriver, setAllDriver] = useState([]);
  const [alltruck, setAllTruck] = useState([]);
  const [allMaker, setAllMaker] = useState([]);
  const [allMakerModel, setMakerModel] = useState([]);

  const [invoiceSubTotal, setinvoiceSubTotal] = useState(0);
  const [invoiceTaxes, setinvoiceTaxes] = useState(0);
  const [invoiceMasterTotal, setinvoiceMasterTotal] = useState(0);

  const [invoice, setInvoice] = useState([
    {
      id: 1,
      name: "Unloaded/Enroute Mileage",
      quantity: 1,
      price: 65,
      total: 65,
      status: "disable",
    },
    {
      id: 2,
      name: "Impounds/Storage: Daily Impound Rate",
      quantity: 1,
      price: 75,
      total: 75,
      status: "diff",
    },
    {
      id: 4,
      name: "",
      quantity: 0,
      price: 0,
      total: 0,
      status: "normal",
    },
  ]);

  const history = useHistory();

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));
    if (v === null) {
    } else {
      setValue(v);

      setCname(v[0].cname);
      setCid(v[0].cid);
      setUid(v[0].id);
      setUname(v[0].name);

      getAllDriverServer(v[0].cid);
      getAllTruckServer(v[0].cid);
      getAllMakerServer();
    }
  };

  const addInvoice = () => {
    const oldArr = [...invoice];
    let newObj = {
      id: Math.random(),
      name: "",
      quantity: 0,
      price: 0,
      total: 0,
      status: "normal",
    };
    oldArr.push(newObj);
    setInvoice(oldArr);
  };

  const delInvoice = (id) => {
    let oldInvoice = [...invoice];
    const result = oldInvoice.filter((word) => word.id !== id);

    const subTotal = result.reduce((n, { total }) => n + total, 0);
    setinvoiceSubTotal(Number(subTotal));
    setinvoiceMasterTotal(Number(invoiceTaxes) + Number(subTotal));
    setInvoice(result);
  };

  const changeArayInvoices = (i, changes) => {
    console.log("invoice by start");
    console.log("invoice by start");
    console.log(invoice);
    const oldArr = [...invoice];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };

    oldArr[i].total = oldArr[i].quantity * oldArr[i].price;

    setInvoice(oldArr);
    const subTotal = oldArr.reduce((n, { total }) => n + total, 0);
    setinvoiceSubTotal(Number(subTotal));
    setinvoiceMasterTotal(Number(invoiceTaxes) + Number(subTotal));
    console.log("end");
    console.log("see");
    console.log("see");
    console.log("end");
    console.log(oldArr);
  };

  const AddTotalValue = (e) => {
    setinvoiceTaxes(e);
    setinvoiceMasterTotal(Number(invoiceSubTotal) + Number(e));
  };

  const getAllDriverServer = async (e) => {
    await fetch(`/account/apis/driver.php?cid=${e}`)
      .then((response) => response.json())
      .then((json) => {
        setAllDriver(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllTruckServer = async (e) => {
    await fetch(`/account/apis/truck.php?cid=${e}`)
      .then((response) => response.json())
      .then((json) => {
        setAllTruck(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllMakerServer = async () => {
    await fetch(`/account/apis/maker.php`)
      .then((response) => response.json())
      .then((json) => {
        setAllMaker(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllMakerModelServer = async (id) => {
    await fetch(`/account/apis/maker_model.php?id=${id}`)
      .then((response) => response.json())
      .then((json) => {
        setMakerModel(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveIm = async (e) => {
    e.preventDefault();

    if (pickuplocation === "") {
      alert("PickUp location is required");
    }else  if(impponddate===""){
      alert("Impound Date is required");

    } else {
      await fetch(
        `/account/apis/new_impound.php?cid=${cid}&uid=${uid}&acc=${aaccount}&fname=${fname}&lname=${lname}&phone=${phoneno}&category=${categoryn}&email=${email}&address=${address}&city=${city}&state=${state}&zip=${zip}&pickuplocation=${pickuplocation}&destination=${destination}&reason=${reason}&priority=${reason}&invoice_number=${invoiceno}&date_impound=${impponddate}&time_impound=${impoundtime}&police_hold=${policehold}&license=${license}&vehicle_state=${Vehiclestate}&vin=${vin}&year=${year}&vehicle_make=${maker}&vehicle_model=${model}&vehicle_color=${color}&drivable=${drivable}&have_key=${havekey}&key_location=${keylocation}&driver_id=${driver}&truck_id=${truck}&notes=${note}&auction=${auction}&sub=${invoiceSubTotal}&txt=${invoiceTaxes}&master=${invoiceMasterTotal}&array=${JSON.stringify(
          invoice
        )}`
      )
        .then((response) => response.json())
        .then((json) => {
          history.push("/report");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div
      className="container"
      style={{ marginTop: "70px", marginBottom: "70px" }}
    >
      <form className="needs-validation">
        <strong className="d-inline-block mb-2 text-success">
          <h3> New Impound </h3>
        </strong>

        <div className="row">
          <div className="col-sm-6 col-12">
            <p>
              <CircleFill
                color="#1ceb15"
                size={12}
                style={{ marginRight: "3px" }}
              />{" "}
              Active |{" "}
              <Display
                size={20}
                style={{ marginRight: "3px", marginLeft: "3px" }}
              />
              <> {uname}</>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 col-6">
            <label htmlFor="validationCustom01">Company</label>
            <input
              type="text"
              id="fname"
              value={cname}
              disabled
              placeholder="Company Name"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-6 col-6">
            <label htmlFor="lname">Account</label>

            <Form.Select
              size="sm"
              value={aaccount}
              onChange={(t) => {
                setAccountA(t.target.value);
              }}
            >
              <option>Don't specify account</option>
              <option>AAA</option>
              <option>CHP</option>
              <option>C.O.D</option>
              <option>Collision Impound</option>
              <option>PPI (Private Property Impound) </option>
              <option>(See Notes)</option>
            </Form.Select>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-12">
            <div
              className="h-30"
              style={{
                marginTop: "10px",
                backgroundColor: "#7DCEA0",
                paddingLeft: "10px",
                paddingTop: "5px",
                paddingBottom: "2px",
              }}
            >
              <h6>Contact Info</h6>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-3 col-6">
            <label htmlFor="validationCustom01">First Name </label>
            <input
              type="text"
              id="fname"
              name="name"
              placeholder="First Name"
              className="form-control form-control-sm"
              value={fname}
              onChange={(t) => {
                setFname(t.target.value);
              }}
            />
          </div>
          <div className="col-sm-3 col-6">
            <label htmlFor="validationCustom01">Last Name </label>
            <input
              type="text"
              id="fname"
              value={lname}
              onChange={(t) => {
                setLname(t.target.value);
              }}
              name="name"
              placeholder="Last Name"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-3  col-12">
            <label htmlFor="validationCustom01">Phone</label>
            <input
              type="text"
              id="fname"
              value={phoneno}
              onChange={(t) => {
                setPhoneNo(t.target.value);
              }}
              name="phone"
              placeholder="Phone "
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-3  col-12">
            <label htmlFor="validationCustom01">Email</label>
            <input
              type="text"
              id="fname"
              name="email"
              value={email}
              onChange={(t) => {
                setEmail(t.target.value);
              }}
              placeholder="Email"
              className="form-control form-control-sm"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-8  col-6">
            <label htmlFor="validationCustom01">Address</label>
            <input
              type="text"
              id="fname"
              value={address}
              onChange={(t) => {
                setAddress(t.target.value);
              }}
              name="address"
              placeholder="Address"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-4  col-6">
            <label htmlFor="validationCustom01">Category</label>
            <Form.Select
              value={categoryn}
              onChange={(t) => {
                setCategoryn(t.target.value);
              }}
              size="sm"
            >
              <option>Select Category</option>
              <option>Individual</option>
              <option>Owner</option>
              <option>Insurance</option>
              <option>lienholder</option>
              <option>Lessee</option>
              <option>other</option>
            </Form.Select>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 col-4">
            <label htmlFor="validationCustom01">City </label>
            <input
              type="text"
              id="fname"
              value={city}
              onChange={(t) => {
                setCity(t.target.value);
              }}
              name="city"
              placeholder="City"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-4  col-4">
            <label htmlFor="validationCustom01">State </label>
            <Form.Select
              value={state}
              onChange={(t) => {
                setState(t.target.value);
              }}
              size="sm"
            >
              <option>Unspecified</option>
              <option>CA</option>
              <option>AL</option>
              <option>AK</option>
              <option>AZ</option>
              <option>AR</option>
              <option>CA</option>
              <option>CO</option>
              <option>CT</option>
              <option>DE</option>
              <option>DC</option>
              <option>FL</option>
              <option>GA</option>
              <option>HI</option>
              <option>ID</option>
              <option>IL</option>
              <option>IN</option>
              <option>IA</option>
              <option>KS</option>
              <option>KY</option>
              <option>LA</option>
              <option>ME</option>
              <option>MT</option>
              <option>NE</option>
              <option>NV</option>
              <option>NH</option>
              <option>NJ</option>
              <option>NM</option>
              <option>NY</option>
              <option>NC</option>
              <option>ND</option>
              <option>OH</option>
              <option>OK</option>
              <option>OR</option>
              <option>MD</option>
              <option>MA</option>
              <option>MI</option>
              <option>MN</option>
              <option>MS</option>
              <option>MO</option>
              <option>PA</option>
              <option>RI</option>
              <option>SC</option>
              <option>SD</option>
              <option>TN</option>
              <option>TX</option>
              <option>UT</option>
              <option>VT</option>
              <option>VA</option>
              <option>WA</option>
              <option>WV</option>
              <option>WI</option>
              <option>WY</option>
              <option>MEX</option>
              <option>AB</option>
              <option>BC</option>
              <option>MB</option>
              <option>NB</option>
              <option>NL</option>
              <option>NS</option>
              <option>NT</option>
              <option>NU</option>
              <option>ON</option>
              <option>PE</option>
              <option>QC</option>
              <option>SK</option>
              <option>YT</option>
            </Form.Select>
          </div>
          <div className="col-sm-4  col-4">
            <label htmlFor="validationCustom01">Zip</label>
            <input
              type="text"
              value={zip}
              onChange={(t) => {
                setZip(t.target.value);
              }}
              id="fname"
              name="zip"
              placeholder="Zip"
              className="form-control form-control-sm"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 col-12">
            <label htmlFor="validationCustom01">Pickup Location* </label>
            <input
              type="text"
              id="fname"
              value={pickuplocation}
              onChange={(t) => {
                setPickupLocation(t.target.value);
              }}
              name="Pickup Location"
              placeholder="Pickup Location*"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-3 col-6">
            <label htmlFor="validationCustom01">Invoice # </label>
            <input
              type="text"
              id="fname"
              value={invoiceno}
              onChange={(t) => {
                setInvoiceNo(t.target.value);
              }}
              name="invoice"
              placeholder="Invoice Number"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-3  col-6">
            <label htmlFor="validationCustom01">Reason </label>
            <Form.Select
              value={reason}
              onChange={(t) => {
                setReason(t.target.value);
              }}
              size="sm"
            >
              <option>Select Reason</option>
              <option>Abandoned Vehicle</option>
              <option>Accident</option>
              <option>Expired Plates </option>
              <option>Police</option>
              <option> (See Notes) </option>
            </Form.Select>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-12">
            <div
              className="h-30"
              style={{
                marginTop: "10px",
                backgroundColor: "#7DCEA0",
                paddingLeft: "10px",
                paddingTop: "5px",
                paddingBottom: "2px",
              }}
            >
              {" "}
              <h6>Impound</h6>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-2 col-12">
            <label htmlFor="validationCustom01">Date </label>
            <input
              type="date"
              id="dateid"
              name="date"
              value={impponddate}
              onChange={(t) => {
                setImpoundDate(t.target.value);
              }}
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-2  col-12">
            <label htmlFor="validationCustom01"> Time </label>
            <input
              type="time"
              name="time"
              value={impoundtime}
              onChange={(t) => {
                setImpoundTime(t.target.value);
              }}
              id="fname"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-4  col-12">
            <label htmlFor="validationCustom01"> </label>

            <div className="form-check">
              <input
                onClick={() => {
                  if (policehold == "no") {
                    setPolice("yes");
                  } else {
                    setPolice("no");
                  }
                }}
                className="form-check-input"
                type="checkbox"
                id="flexCheckIndeterminate"
              />
              <label
                className="form-check-label"
                htmlFor="flexCheckIndeterminate"
              >
                {" "}
                Police Hold{" "}
              </label>
            </div>
          </div>{" "}
        </div>

        <div className="row">
          <div className="col-sm-12 col-12">
            <div
              className="h-30"
              style={{
                marginTop: "10px",
                backgroundColor: "#7DCEA0",
                paddingLeft: "10px",
                paddingTop: "5px",
                paddingBottom: "2px",
              }}
            >
              {" "}
              <h6>Vehicle Information </h6>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 col-12">
            <label htmlFor="validationCustom01">License Plate </label>
            <input
              type="text"
              id="fname"
              value={license}
              onChange={(t) => {
                setLicense(t.target.value);
              }}
              name="license"
              placeholder="License Plate"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-4 col-12">
            <label htmlFor="validationCustom01">State </label>
            <Form.Select
              value={Vehiclestate}
              onChange={(t) => {
                setVehiclestate(t.target.value);
              }}
              size="sm"
            >
               <option></option>
  <option>AB</option>
<option>AK</option>
<option>AL</option>
<option>AR</option>
<option>AZ</option>
<option>BC</option>
<option>CA</option>
<option>CA</option>
<option>CO</option>
<option>CT</option>
<option>DC</option>
<option>DE</option>
<option>FL</option>
<option>GA</option>
<option>HI</option>
<option>IA</option>
<option>ID</option>
<option>IL</option>
<option>IN</option>
<option>KS</option>
<option>KY</option>
<option>LA</option>
<option>MA</option>
<option>MB</option>
<option>MD</option>
<option>ME</option>
<option>MEX</option>
<option>MI</option>
<option>MN</option>
<option>MO</option>
<option>MS</option>
<option>MT</option>
<option>NB</option>
<option>NC</option>
<option>ND</option>
<option>NE</option>
<option>NH</option>
<option>NJ</option>
<option>NL</option>
<option>NM</option>
<option>NS</option>
<option>NT</option>
<option>NU</option>
<option>NV</option>
<option>NY</option>
<option>OH</option>
<option>OK</option>
<option>ON</option>
<option>OR</option>
<option>PA</option>
<option>PE</option>
<option>QC</option>
<option>RI</option>
<option>SC</option>
<option>SD</option>
<option>SK</option>
<option>TN</option>
<option>TX</option>
<option>UT</option>
<option>VA</option>
<option>VT</option>
<option>WA</option>
<option>WI</option>
<option>WV</option>
<option>WY</option>
<option>YT</option>
            </Form.Select>
          </div>
          <div className="col-sm-4  col-12">
            <label htmlFor="validationCustom01">VIN </label>
            <input
              type="text"
              id="fname"
              value={vin}
              onChange={(t) => {
                setVin(t.target.value);
              }}
              name="VIN"
              placeholder="VIN"
              className="form-control form-control-sm"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-3 col-12">
            <label htmlFor="validationCustom01">Year </label>
            <Form.Select
              value={year}
              onChange={(t) => {
                setYear(t.target.value);
              }}
              size="sm"
            >
              <option>Select Year</option>
              <option value="2022">2022</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
              <option value="2018">2018</option>
              <option value="2017">2017</option>
              <option value="2016">2016</option>
              <option value="2015">2015</option>
              <option value="2014">2014</option>
              <option value="2013">2013</option>
              <option value="2012">2012</option>
              <option value="2011">2011</option>
              <option value="2010">2010</option>
              <option value="2009">2009</option>
              <option value="2008">2008</option>
              <option value="2007">2007</option>
              <option value="2006">2006</option>
              <option value="2005">2005</option>
              <option value="2004">2004</option>
              <option value="2003">2003</option>
              <option value="2002">2002</option>
              <option value="2001">2001</option>
              <option value="2000">2000</option>
              <option value="1999">1999</option>
              <option value="1998">1998</option>
              <option value="1997">1997</option>
              <option value="1996">1996</option>
              <option value="1995">1995</option>
              <option value="1994">1994</option>
              <option value="1993">1993</option>
              <option value="1992">1992</option>
              <option value="1991">1991</option>
              <option value="1990">1990</option>
              <option value="1989">1989</option>
              <option value="1988">1988</option>
              <option value="1987">1987</option>
              <option value="1986">1986</option>
              <option value="1985">1985</option>
              <option value="1984">1984</option>
              <option value="1983">1983</option>
              <option value="1982">1982</option>
              <option value="1981">1981</option>
              <option value="1980">1980</option>
              <option value="1979">1979</option>
              <option value="1978">1978</option>
              <option value="1977">1977</option>
              <option value="1976">1976</option>
              <option value="1975">1975</option>
              <option value="1974">1974</option>
              <option value="1973">1973</option>
              <option value="1972">1972</option>
              <option value="1971">1971</option>
              <option value="1970">1970</option>
              <option value="1969">1969</option>
              <option value="1968">1968</option>
              <option value="1967">1967</option>
              <option value="1966">1966</option>
              <option value="1965">1965</option>
              <option value="1964">1964</option>
              <option value="1963">1963</option>
              <option value="1962">1962</option>
              <option value="1961">1961</option>
              <option value="1960">1960</option>
              <option value="1959">1959</option>
              <option value="1958">1958</option>
              <option value="1957">1957</option>
              <option value="1956">1956</option>
              <option value="1955">1955</option>
              <option value="1954">1954</option>
              <option value="1953">1953</option>
              <option value="1952">1952</option>
              <option value="1951">1951</option>
              <option value="1950">1950</option>
              <option value="1949">1949</option>
              <option value="1948">1948</option>
              <option value="1947">1947</option>
              <option value="1946">1946</option>
              <option value="1945">1945</option>
              <option value="1944">1944</option>
              <option value="1943">1943</option>
              <option value="1942">1942</option>
              <option value="1941">1941</option>
              <option value="1940">1940</option>
              <option value="1939">1939</option>
              <option value="1938">1938</option>
              <option value="1937">1937</option>
              <option value="1936">1936</option>
              <option value="1935">1935</option>
              <option value="1934">1934</option>
              <option value="1933">1933</option>
              <option value="1932">1932</option>
              <option value="1931">1931</option>
              <option value="1930">1930</option>
              <option value="1929">1929</option>
              <option value="1928">1928</option>
              <option value="1927">1927</option>
              <option value="1926">1926</option>
              <option value="1925">1925</option>
              <option value="1924">1924</option>
              <option value="1923">1923</option>
            </Form.Select>
          </div>
          <div className="col-sm-3 col-12">
            <label htmlFor="validationCustom01"> Vehicle Make </label>
            <Form.Select
              value={maker}
              onChange={(t) => {
                setMaker(t.target.value);
                getAllMakerModelServer(t.target.value);
              }}
              size="sm"
            >
              <option>Select Vehicle Make</option>
              {allMaker.map((i, index) => (
                <option value={i.id}>{i.name} </option>
              ))}
            </Form.Select>
          </div>
          <div className="col-sm-3 col-12">
            <label htmlFor="validationCustom01">Vehicle Model </label>
            <Form.Select
              value={model}
              onChange={(t) => {
                setModel(t.target.value);
              }}
              size="sm"
            >
              <option>Select Vehicle Model</option>
              {allMakerModel.map((i, index) => (
                <option>{i.name} </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-sm-3  col-12">
            <label htmlFor="validationCustom01">Vehicle Color </label>
            <Form.Select
              value={color}
              onChange={(t) => {
                setColor(t.target.value);
              }}
              size="sm"
            >
              <option>Select Vehicle Color</option>
              <option>(other)</option>
              <option>Beige</option>
              <option>Black</option>
              <option>Blue</option>
              <option>Bronze</option>
              <option>Brown</option>
              <option>Burgundy</option>
              <option>Champagne</option>
              <option>Gold</option>
              <option>Gray</option>
              <option>Green</option>
              <option>Maroon</option>
              <option>Navy</option>
              <option>Orange</option>
              <option>Pink</option>
              <option>Purple</option>
              <option>Red</option>
              <option>Silver</option>
              <option>Tan</option>
              <option>Teal</option>
              <option>White</option>
              <option>Yellow</option>
            </Form.Select>
          </div>
        </div>

        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col-sm-2 col-6">
            <div className="form-check">
              <input
                onClick={(t) => {
                  if (drivable == "no") {
                    setDrivable("yes");
                  } else {
                    setDrivable("no");
                  }
                }}
                className="form-check-input"
                type="checkbox"
                id="dri"
              />
              <label className="form-check-label" htmlFor="dri">
                Drivable
              </label>
            </div>
          </div>
          <div className="col-sm-2  col-6">
            <div className="form-check">
              <input
                onClick={(t) => {
                  if (havekey == "no") {
                    setHavekey("yes");
                  } else {
                    setHavekey("no");
                  }
                }}
                className="form-check-input"
                type="checkbox"
                id="havekey"
              />
              <label className="form-check-label" htmlFor="havekey">
                {" "}
                Have Keys{" "}
              </label>
            </div>
          </div>
          <div className="col-sm-4  col-12">
            <input
              type="text"
              value={keylocation}
              onChange={(t) => {
                setKeyLocation(t.target.value);
              }}
              id="fname"
              name="keylocation"
              placeholder="Key Location"
              className="form-control form-control-sm"
            />
          </div>

          <div className="col-sm-1  col-4">
            <label htmlFor="validationCustom01">Auction </label>
          </div>
          <div className="col-sm-2  col-8">
            <Form.Select
              value={auction}
              onChange={(t) => {
                if (t.target.value === "Select Days") {
                  setAuction("");
                } else {
                  setAuction(t.target.value);
                }
              }}
              size="sm"
            >
              <option>Select Days</option>
              <option>30</option>
              <option>60</option>
              <option>90</option>
              <option>120</option>
            </Form.Select>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 col-6">
            <label htmlFor="lname">Driver</label>

            <Form.Select
              value={driver}
              onChange={(t) => {
                setDriver(t.target.value);
              }}
              size="sm"
            >
              <option>Select Driver</option>
              {allDriver.map((i, index) => (
                <option value={i.id}>{i.name} </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-sm-6 col-6">
            <label htmlFor="lname">Truck</label>

            <Form.Select
              value={truck}
              onChange={(t) => {
                setTruck(t.target.value);
              }}
              size="sm"
            >
              <option>Select Truck</option>
              {alltruck.map((i, index) => (
                <option value={i.id}>{i.name} </option>
              ))}
            </Form.Select>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-12">
            <label htmlFor="validationCustom01">Notes * </label>
            <textarea
              value={note}
              onChange={(t) => {
                setNote(t.target.value);
              }}
              className="form-control form-control-sm"
            ></textarea>
          </div>
        </div>

        <div
          className="row"
          style={{ margin: "1px", padding: "1px", marginTop: "20px" }}
        >
          <div
            className="col-sm-6 col-3"
            style={{ backgroundColor: "#7DCEA0" }}
          >
            <label htmlFor="validationCustom01">Invoice Charges </label>
          </div>
          <div
            className="col-sm-1 col-3"
            style={{ backgroundColor: "#7DCEA0 " }}
          >
            <label htmlFor="validationCustom01">Quantity </label>
          </div>
          <div
            className="col-sm-1 col-3"
            style={{ backgroundColor: "#7DCEA0 " }}
          >
            <label htmlFor="validationCustom01">Price </label>
          </div>
          <div
            className="col-sm-1 col-3"
            style={{ backgroundColor: "#7DCEA0 " }}
          >
            <label htmlFor="validationCustom01">Total </label>
          </div>
        </div>

        {invoice.map((data, index) => {
          return (
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-sm-6 col-3">
                {data.status == "disable" ? (
                  <>
                    <input
                      disabled
                      type="text"
                      id="fname"
                      name="vin"
                      value={data.name}
                      className="form-control form-control-sm"
                    />
                  </>
                ) : data.status == "diff" ? (
                  <>
                    <input
                      disabled
                      type="text"
                      id="fname"
                      name="vin"
                      value={data.name}
                      className="form-control form-control-sm"
                    />
                  </>
                )  : (
                  <>
                    <Form.Select
                      size="sm"
                      onChange={(e) => {
                        if (index === 0) {
                        } else if (index === 1) {
                        } else {
                          if (e.target.value === "After Hours Gate Fee") {
                            changeArayInvoices(index, {
                              price: "120",
                              name: e.target.value,
                            });
                          } else if (e.target.value === "CHP Tow/Hook Fee") {
                            changeArayInvoices(index, {
                              price: "275",
                              name: e.target.value,
                            });
                          } else if (
                            e.target.value === "Collision Tow/Hook Fee"
                          ) {
                            changeArayInvoices(index, {
                              price: "300",
                              name: e.target.value,
                            });
                          } else if (e.target.value === "Lien Sale") {
                            changeArayInvoices(index, {
                              price: "100",
                              name: e.target.value,
                            });
                          } else if (e.target.value === "others") {
                            changeArayInvoices(index, {
                              price: "0",
                              name: e.target.value,
                            });
                          } else if (e.target.value === "a") {
                            changeArayInvoices(index, {
                              price: "0",
                              name: e.target.value,
                            });
                          }
                        }
                      }}
                    >
                      <option value="a" required>
                        Click here and choose an item from the list, or
                        start typing the name of the service
                      </option>

                      <option>After Hours Gate Fee</option>
                      <option>CHP Tow/Hook Fee</option>
                      <option>Collision Tow/Hook Fee</option>
                      
                      <option>Lien Sale</option>
                      <option>others</option>
                    </Form.Select>
                  </>
                )}
              </div>
              <div className="col-sm-1 col-3">
                <input
                  type="number"
                  id="fname"
                  value={
                    data.status === "diff"
                      ? "1"
                      : data.quantity === 0
                      ? ""
                      : data.quantity
                  }
                  disabled={data.status === "diff" ? true : false}
                  onChange={(e) => {
                    changeArayInvoices(index, { quantity: e.target.value });
                  }}
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col-sm-1  col-3">
                <input
                  type="number"
                  id="fname"
                  name="s"
                  value={data.price}
                  
                  className="form-control form-control-sm"
                  onChange={(e) => {
                    changeArayInvoices(index, { price: e.target.value });
                  }}
                />
              </div>

              <div className="col-sm-1  col-3">
                <input
                  type="number"
                  id="fname"
                  disabled
                  name="sd"
                  value={data.total}
                  className="form-control form-control-sm"
                />
              </div>

              <div className="col-sm-1  col-3">
                {index == 0 ? (
                  ""
                ) : index == 1 ? (
                  ""
                ) : index==2 ?"":  (
                  <Trash
                    color="gray"
                    size={20}
                    onClick={() => delInvoice(data.id)}
                  />
                )}
              </div>
            </div>
          );
        })}

        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-sm-7 col-6">
            <PlusCircleFill
              color="green"
              size={30}
              onClick={() => {
                addInvoice();
              }}
            />
          </div>
          <div className="col-sm-1 col-3">Subtotal</div>
          <div className="col-sm-1  col-3">
            <input
              type="text"
              disabled
              id="fname"
              name="vin"
              value={invoiceSubTotal}
              className="form-control form-control-sm"
            />
          </div>
        </div>

        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-sm-7 col-6"></div>
          <div className="col-sm-1 col-3"> Taxes</div>
          <div className="col-sm-1  col-3">
            <input
              type="text"
              id="fname"
              value={invoiceTaxes}
              onChange={(e) => {
                AddTotalValue(e.target.value);
              }}
              className="form-control form-control-sm"
            />
          </div>
        </div>

        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-sm-7 col-6"></div>
          <div className="col-sm-1 col-3"> Total </div>
          <div className="col-sm-1  col-3">
            <input
              type="text"
              id="fname"
              disabled
              name="invoicemsterrer"
              onChange={(e) => {
                setinvoiceMasterTotal(e.target.value);
              }}
              value={invoiceMasterTotal}
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <button
          onClick={saveIm}
          style={{ backgroundColor: "#3fa66c" }}
          className="btn btn-secondary"
        >
          Create Impound
        </button>
      </form>
    </div>
  );
}
export default Newimpound;
