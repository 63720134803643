import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/PulseLoader";
import moment from "moment";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function App() {
  const [all, setAll] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allimpound, setAllimpound] = useState([]);
  const [allrecord, setAllRecord] = useState([]);

  const [invoiceSubTotal, setinvoiceSubTotal] = useState(0);
  const [invoiceTaxes, setinvoiceTaxes] = useState(0);
  const [invoiceMasterTotal, setinvoiceMasterTotal] = useState(0);
  const [row, setRow] = useState();
  const [date, setCurDate] = useState("");
  const [blance, setBlance] = useState("");
  const [rcv, setRcv] = useState("");

  const [invoice, setInvoice] = useState([]);

  const getServer = async (id, jjson) => {
    await fetch(`/account/apis/impound_row.php?id=${id}`)
      .then((response) => response.json())
      .then((json) => {
        const as = moment(json[0].date_im).format("MMMM DD, YYYY");

        json[0].date_im = as;
        const num = moment(json[0].time_im, ["HH.mm"]).format("hh:mm A");

        if (json[0].st === "waiting") {
          const int = getNumberOfDays(json[0].date_im, Date.now());

          const arrayToBeChanged = JSON.parse(json[0].array);
          arrayToBeChanged[1].quantity = int;
          arrayToBeChanged[1].total = int * arrayToBeChanged[1].price;
          const Total = arrayToBeChanged.reduce((n, { total }) => n + total, 0);

          json[0].time_im = num + " (" + int + " days)";
          json[0].sub = Total;

          json[0].tot = Total + Number(json[0].tax);

          setinvoiceMasterTotal(Total + Number(json[0].tax));

          setinvoiceSubTotal(Total);
          setinvoiceTaxes(Number(json[0].tax));
          setInvoice(arrayToBeChanged);

          localStorage.setItem(
            "invoice_array",
            JSON.stringify(arrayToBeChanged)
          );
          localStorage.setItem("invoice_sub", Total);
          localStorage.setItem("invoice_tax", Number(json[0].tax));
          localStorage.setItem("invoice_total", Total + Number(json[0].tax));
        } else if (json[0].st === "relese") {
          json[0].time_im = num;
          setinvoiceMasterTotal(json[0].tot);
          setinvoiceSubTotal(json[0].sub);
          setinvoiceTaxes(json[0].tax);
          setInvoice(JSON.parse(json[0].array));
        }
        localStorage.setItem("amount", json[0].tot);

        if (jjson[0].msg === "pass") {
          const Record_totl = jjson.reduce(
            (n, { amount }) => n + Number(amount),
            0
          );

          setBlance(json[0].tot - Number(Record_totl));

          setRcv(Record_totl);

          localStorage.setItem("amount", json[0].tot - Number(Record_totl));
        }

        setLoading(!loading);

        setAllimpound(json);
        setCurDate("as of " + moment().format("MM/DD/YYYY"));

        var content = document.getElementById("divcontents");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getServerRecord = async (id) => {
    await fetch(`/account/apis/recordview.php?id=${id}`)
      .then((response) => response.json())
      .then((json) => {
        setAllRecord(json);

        getServer(id, json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const history = useHistory();

  function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);
    if (diffInDays < 1) {
      diffInDays = 1;
    }

    return diffInDays;
  }

  useEffect(() => {
    let a = localStorage.getItem("id_row");

    if (a === null) {
      history.push("/report");
    } else {
      setRow(a);

      getServerRecord(a);
    }
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));
    setAll(v);
    console.log("Here");
    console.log("Here");
    console.log("Here");
    console.log("Here");
    console.log("Here");
    console.log("Here");
    console.log("Here");
    console.log("Here");
    console.log(v);
  }, []);

  return (
    <div
      style={{ background: "white", display: "block", flex: 1, padding: 30 }}
    >
      {loading === true ? (
        <center>
          <MoonLoader
            color="green"
            loading={loading}
            css={override}
            size={15}
            margin={10}
          />
        </center>
      ) : (
        <>
          <div>
            <center>Impound Invoice </center>
            <h3>{all[0].cname}</h3>
            <p>
              {all[0].cadd}
              <br />
              Phone:{all[0].cphone}
            </p>
            <p></p>
            {allimpound.map((i, index) => (
              <table class="table table-sm">
                <tr>
                  <th> Print Date </th>
                  <th> {moment().format("MM/DD/YYYY")}</th>
                </tr>
                <tr>
                  <th>Invoice No.</th>

                  <td>{i.invoie}</td>
                  <th>Impound Total</th>

                  <td>
                    ${i.tot} {i.des === "" ? date : ""}
                  </td>
                </tr>

                <tr>
                  <th>Date Impounded</th>
                  <td>
                    {i.date_im}
                    {" / "}
                    {i.time_im}
                  </td>
                  <th>Towed From</th>
                  <td>{i.pick}</td>
                </tr>

                <tr>
                  <th>Driver</th>
                  <td>{i.driver}</td>
                  <th>Vehicle Description</th>
                  <td>
                    {i.make} {i.model} {i.year} {" ("}
                    {i.color}
                    {") "}
                  </td>
                </tr>
                <tr>
                  <th>License Plate # </th>
                  <td>{i.license}</td>
                  <th>Reason for Impound</th>
                  <td>{i.reason}</td>
                </tr>
                <tr></tr>
                <tr>
                  <th>Account </th>
                  <td>{i.account}</td>
                  <th>VIN </th>
                  <td>{i.vin}</td>
                </tr>

                <tr>
                  <th>Have Keys </th>
                  <td>{i.have_keys}</td>
                  <th>Drivable </th>
                  <td>{i.drivable}</td>
                </tr>
              </table>
            ))}
            <hr />
            <div
              style={{
                borderWidth: "1px",
                borderColor: "#202020",
                borderStyle: "solid",
              }}
            >
              <h5
                style={{
                  margin: "10px",
                }}
              >
                Invoice Charges{" "}
              </h5>
              <table
                style={{
                  margin: "10px",
                }}
                class="table"
              >
                <tr>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
                {invoice.map((i, index) => (
                  <tr>
                    <td>{i.name}</td>
                    <td>{i.quantity}</td>
                    <td>${i.price}</td>
                    <td>${i.total}</td>
                  </tr>
                ))}

                <tr>
                  <td></td>
                  <td></td>
                  <th>Sub Total</th>
                  <th>${invoiceSubTotal}</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <th>Tax</th>
                  <th>${invoiceTaxes}</th>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <th>Grand Total</th>
                  <th>${invoiceMasterTotal}</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>

                  {blance === "" ? (
                    ""
                  ) : (
                    <>
                      <th>Received Amount</th>
                      <td>{rcv}</td>
                    </>
                  )}
                </tr>

                <tr>
                  <td></td>
                  <td></td>

                  {blance === "" ? (
                    ""
                  ) : (
                    <>
                      <th>Due Amount</th>
                      <th>
                        {blance < 0 ? "($" + blance * -1 + ")" : "$" + blance}
                      </th>
                    </>
                  )}
                </tr>
              </table>
            </div>
            {all[0].cname} appreciates your business; if you have any question
            regarding this invoice,please contact us at {all[0].cname}
            <br />
            <br />
            <h5>
              Signature:______________________________________________ Date:
              _______________________{" "}
            </h5>
            <center>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                {" "}
                CA#{all[0].cca}
              </p>
              <br />
              <div
                style={{
                  borderWidth: "0.5px",
                  borderColor: "#202020",
                  borderStyle: "double",
                }}
              >
                <p style={{ margin: "20px" }}>
                  Upon request,you are entitled receive a copy of the Towing and
                  Storage Fees and Access Noticed
                </p>
              </div>
            </center>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
