import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Tabs, Tab, Table ,ProgressBar} from "react-bootstrap";

function App() {
  const [allimpound, setAllimpound] = useState([]);
  const [cid, setCid] = useState();
  const [type, setType] = useState("waiting");

  const getServer = async (id) => {
    await fetch(`/account/apis/view_impound.php?cid=${id}&type=${type}`)
      .then((response) => response.json())
      .then((json) => {
        console.log("run");
        console.log(json);
        setAllimpound(json);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }

  const history = useHistory();
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));

    console.log(v);
    if (v === null) {
      console.log("V is null");
    } else {
      setCid(v[0].cid);
      getServer(v[0].cid);
    }
  }, []);

  const del = (id) => {
    localStorage.setItem("id_row", id);
    history.push("/row");
    console.log("pass");
  };

  const dell = async (id) => {
    await fetch(`/account/apis/impound_row.php?id=${id}`)
      .then((response) => response.json())

      .then((json) => {
        console.log(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div style={{ display: "block", flex: 1, padding: 30 }}>
      <strong className="d-inline-block mb-2 text-success">
        {" "}
        <h4> Impounds / Lot Management</h4>{" "}
      </strong>
      <Tabs defaultActiveKey="first">
        <Tab eventKey="first" title={"Current ( " + allimpound.length + " )"}>
          <Table className="table table-bordered">
            <thead>
              <tr>
                <th>Invoice # </th>
                <th>Driver Name</th>
                <th>Have Keys</th>
                <th>Impound Date</th>
                <th>Days Held</th>
                <th>Total</th>
                <th>Vehicle</th>
                <th>Plate</th>
                <th>VIN</th>
              </tr>
            </thead>
            <tbody>
              {allimpound.map((i, index) => (
                <tr onClick={() => del(i.id)}>
                  <td> {i.invoie}</td>
                  <td> {i.account}</td>
                  <td>
                    {i.model} {i.color} {i.year}{" "}
                  </td>
                  <td> {i.license}</td>
                  <td> {i.vin}</td>

                  <td>
                    {" "}
                    {i.date_im} {i.time_im}
                  </td>
                  <td>{getNumberOfDays(i.date_im, Date.now())}</td>
                  <td> Total</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
      </Tabs>
    </div>
  );
}

export default App;
