import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Display, CircleFill } from "react-bootstrap-icons";

function Record() {
  const [row, setRow] = useState();
  const [uname, setUname] = useState("");

  const [type, setType] = useState("");
  const [lic, setLic] = useState("");
  const [licType, setLicType] = useState("");
  const [birth, setBirth] = useState("");
  const [exLic, setExLic] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [add, setAdd] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [am, setam] = useState("");

  const history = useHistory();

  const release = async (e) => {
    e.preventDefault();
    const array = localStorage.getItem("invoice_array");
    let sub = localStorage.getItem("invoice_sub");
    let tax = localStorage.getItem("invoice_tax");
    let total = localStorage.getItem("invoice_total");

    if (date === "") {
      alert("Release Date is required ");
    } else {
      await fetch(
        `/account/apis/relese.php?mid=${row}&array=${array}&sub=${sub}&tax=${tax}&total=${total}&type=${type}&lic=${lic}&date=${date}&time=${time}&birth=${birth}&licex=${exLic}&lictype=${licType}&name=${name}&phone=${phone}&add=${add}&city=${city}&state=${state}&zip=${zip}`
      )
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          history.push("/row");
        })
        .catch((err) => {
          history.push("/row");
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));
    let a = localStorage.getItem("id_row");
    const array = localStorage.getItem("invoice_array");
    console.log("array");
    console.log("array");
    console.log("array");
    console.log("array");
    console.log("array");
    console.log("array");
    console.log("array");
    console.log("array");
    console.log(array);

    setam(localStorage.getItem("amount"));

    console.log(v);

    if (v === null) {
      console.log("V is null");
    } else if (a === null) {
      console.log("a is null");
    } else {
      setRow(a);
      setUname(v[0].name);
    }
  }, []);

  return (
    <div className="container-sm">
      <div style={{ marginTop: "30px" }}>
        <strong className="d-inline-block mb-2 text-success">
          {" "}
          <h4> Release Vehicle</h4>
          {exLic}
        </strong>
      </div>
      <form className="needs-validation">
        <div className="row">
          <div className="col-sm-6 col-12">
            <p>
              <CircleFill
                color="#1ceb15"
                size={12}
                style={{ marginRight: "3px" }}
              />{" "}
              Active |{" "}
              <Display
                size={20}
                style={{ marginRight: "3px", marginLeft: "3px" }}
              />
              <> {uname}</>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">
              Total Amount:
              <span
                style={{
                  color: "red",

                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {"   "} ${am}
              </span>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01"></label>
          </div>
        </div>
        <span style={{ fontWeight: "bold" }}>
          How was this vehicle released?
        </span>
        <br />
        <input
          type="radio"
          id="p"
          name="fav_language"
          onChange={() => {
            setType("Released - with payment");
          }}
        />
         <label for="p">Released - with payment</label> <br />
        <input
          type="radio"
          id="tt"
          onChange={() => {
            setType("Released - to new owner/title turned over");
          }}
          name="fav_language"
          value="Released - to new owner/title turned over"
        />
          <label for="tt">Released - to new owner/title turned over</label>
        <br />
        <input
          type="radio"
          id="i"
          onChange={() => {
            setType("Released - to Insurance");
          }}
          name="fav_language"
          value="Released - to Insurance"
        />
          <label for="i">Released - to Insurance</label> 
        <br />
        <input
          type="radio"
          id="v"
          onChange={() => {
            setType("Vehicle was scrapped");
          }}
          name="fav_language"
          value="Vehicle was scrapped"
        />
          <label for="v">Vehicle was scrapped</label> 
        <br />
        <input
          type="radio"
          id="bb"
          onChange={() => {
            setType("Other (please explain in box below)");
          }}
          name="fav_language"
          value="Other (please explain in box below)"
        />
          <label for="bb">Other (please explain in box below) </label>   
        <div className="row">
          <div
            style={{
              marginTop: "20px",
              fontWeight: "bold",
              color: "green",
              fontSize: "20px",
            }}
            className="col-sm-4 col-4"
          >
            <label for="validationCustom01"> Release Details</label>
          </div>
        </div>
        <div style={{ marginTop: "10px" }} className="row">
          <div className="col-sm-2 col-4">
            <label for="validationCustom01"> License#</label>

            <input
              type="text"
              value={lic}
              onChange={(t) => {
                setLic(t.target.value);
              }}
              id="fname"
              name="license"
              placeholder="License"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-2 col-4">
            <label for="validationCustom01">Birth Date </label>

            <input
              type="date"
              value={birth}
              onChange={(t) => {
                setBirth(t.target.value);
              }}
              id="fname"
              name="birth"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-2 col-4">
            <label for="validationCustom01">License Expiration Date</label>

            <input
              type="date"
              value={exLic}
              onChange={(t) => {
                setExLic(t.target.value);
              }}
              id="fname"
              name="name"
              placeholder="License Expiration Date"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div style={{ marginTop: "10px" }} className="row">
          <div className="col-sm-6 col-4">
            <label for="validationCustom01">
              License Type (CA Drivers License, Canadian Drivers License, etc){" "}
            </label>

            <input
              type="text"
              value={licType}
              onChange={(t) => {
                setLicType(t.target.value);
              }}
              id="fname"
              name="name"
              placeholder="License Type (CA Drivers License, Canadian Drivers License, etc) "
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div style={{ marginTop: "10px" }} className="row">
          <div className="col-sm-3 col-4">
            <label for="validationCustom01">Full name </label>

            <input
              type="text"
              value={name}
              onChange={(t) => {
                setName(t.target.value);
              }}
              id="fname"
              name="name"
              placeholder="Full Name"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-3 col-4">
            <label for="validationCustom01"> Phone</label>

            <input
              type="text"
              value={phone}
              onChange={(t) => {
                setPhone(t.target.value);
              }}
              id="fname"
              name="name"
              placeholder="Phone"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div style={{ marginTop: "10px" }} className="row">
          <div className="col-sm-6 col-4">
            <label for="validationCustom01">Address </label>

            <input
              type="text"
              value={add}
              onChange={(t) => {
                setAdd(t.target.value);
              }}
              id="fname"
              name="address"
              placeholder="Address"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div style={{ marginTop: "10px" }} className="row">
          <div className="col-sm-2 col-4">
            <label for="validationCustom01"> City</label>

            <input
              type="text"
              value={city}
              onChange={(t) => {
                setCity(t.target.value);
              }}
              id="fname"
              placeholder="City"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-2 col-4">
            <label for="validationCustom01">State </label>

            <Form.Select
              value={state}
              onChange={(t) => {
                setState(t.target.value);
              }}
              size="sm"
            >
              <option>Unspecified</option>
              <option>CA</option>
              <option>AL</option>
              <option>AK</option>
              <option>AZ</option>
              <option>AR</option>
              <option>CA</option>
              <option>CO</option>
              <option>CT</option>
              <option>DE</option>
              <option>DC</option>
              <option>FL</option>
              <option>GA</option>
              <option>HI</option>
              <option>ID</option>
              <option>IL</option>
              <option>IN</option>
              <option>IA</option>
              <option>KS</option>
              <option>KY</option>
              <option>LA</option>
              <option>ME</option>
              <option>MT</option>
              <option>NE</option>
              <option>NV</option>
              <option>NH</option>
              <option>NJ</option>
              <option>NM</option>
              <option>NY</option>
              <option>NC</option>
              <option>ND</option>
              <option>OH</option>
              <option>OK</option>
              <option>OR</option>
              <option>MD</option>
              <option>MA</option>
              <option>MI</option>
              <option>MN</option>
              <option>MS</option>
              <option>MO</option>
              <option>PA</option>
              <option>RI</option>
              <option>SC</option>
              <option>SD</option>
              <option>TN</option>
              <option>TX</option>
              <option>UT</option>
              <option>VT</option>
              <option>VA</option>
              <option>WA</option>
              <option>WV</option>
              <option>WI</option>
              <option>WY</option>
              <option>MEX</option>
              <option>AB</option>
              <option>BC</option>
              <option>MB</option>
              <option>NB</option>
              <option>NL</option>
              <option>NS</option>
              <option>NT</option>
              <option>NU</option>
              <option>ON</option>
              <option>PE</option>
              <option>QC</option>
              <option>SK</option>
              <option>YT</option>
            </Form.Select>
          </div>
          <div className="col-sm-2 col-4">
            <label for="validationCustom01">Zip</label>

            <input
              type="text"
              value={zip}
              onChange={(t) => {
                setZip(t.target.value);
              }}
              id="fname"
              name="name"
              placeholder="Zip"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div style={{ marginTop: "10px" }} className="row">
          <div className="col-sm-2 col-4">
            <label for="validationCustom01"> Date</label>

            <input
              type="date"
              value={date}
              onChange={(t) => {
                setDate(t.target.value);
              }}
              id="fname"
              placeholder="City"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-2 col-4">
            <label for="validationCustom01">Time </label>

            <input
              type="time"
              value={time}
              onChange={(t) => {
                setTime(t.target.value);
              }}
              id="fname"
              name="State"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <button
          style={{
            backgroundColor: "#3fa66c",
            marginTop: "20px",
            width: "80px",
          }}
          type="button"
          className="btn btn-secondary"
          onClick={release}
        >
          Release
        </button>
        <button
          style={{
            backgroundColor: "red",
            marginTop: "20px",
            width: "80px",
            marginLeft: "20px",
          }}
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            history.push("/row");
          }}
        >
          Cancel
        </button>
      </form>
    </div>
  );
}
export default Record;
