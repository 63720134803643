import React, { useState, useEffect } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory, Link } from "react-router-dom";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/PulseLoader";
import moment from "moment";
import axios from "axios";
import { Tabs,  Tab,Nav,Sonnet ,Form, FormCheck,Table ,ProgressBar,Container,Row,Col} from "react-bootstrap";
import {
  PlusCircleFill,
  Signpost,
  PersonCircle,
  PersonPlusFill,
  Trash,
  SunFill,
  InfoSquare,
  Slack,
  Display,
  Save,
  Receipt,
  CircleFill,

} from "react-bootstrap-icons";
import { MultiSelect } from "react-multi-select-component";


function App(){

  const [uname, setUname] = useState();
  const [cname, setCname] = useState();
  const [mainView, setMainView] = useState("0");
  const history = useHistory();
  const [row, setRow] = useState();

  const [year, setYear] = useState();
  const [make, setmake] = useState();
  const [model, setmodel] = useState();
  const [vin, setvin] = useState();
  const [plat, setplat] = useState();
  const [statee, setstate] = useState();

  const [disply, setDisply] = useState("user");
 
  const [companies, setCompanies] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [scenper, setScenper] = useState("no");


const [incidentDate, setIncidentDate] = useState("");
const [incidentTimeStart, setIncidentTimeStart] = useState("");
const [incidentTimeEnd, setIncidentTimeEnd] = useState("");
const [towingIncident, setTowingIncident] = useState("");
const [incidentLocation, setIncidentLocation] = useState("");
const [hazmat, sethazmat] = useState("Response and Clean up - Removal of spill by tow company");
const [lawEnforcement, setlawEnforcement] = useState("");
const [officerName, setOfficerName] = useState("");
const [enforcementCase, setEnforcementCase] = useState("");
const [description, setdescription] = useState("");
const [lawCase, setLawCase] = useState("no");
const [spillsize, setSpillsize] = useState("");
const [Narrative, setNarrative] = useState("");

  const sceneActivities = [
    { label: "Fire Debris Cleanup", value: "Fire Debris Cleanup" },
    { label: "Recovery", value: "Recovery" },
    { label: "Road Block / Detour", value: "Road Block/ Detour" },
    { label: "Scene Safety", value: "Scene Safety" },
    { label: "Spill Cleanup", value: "Spill Cleanup" },
    { label: "Traffic Control", value: "Traffic Control" },
    { label: "Vehicle De-energization", value: "Vehicle De-energization" },
    { label: "Vehicle Fire", value: "Vehicle Fire" },
  ];
  const [selectedSceneActivities, setSelectedSceneActivities] = useState([]);
  const spilledFluid = [
    { label: "None/Solid Debris Only", value: "None/Solid Debris Only" },
    { label: "AntiFreeze", value: "AntiFreeze" },
    { label: "Battery Acid", value: "Battery Acid" },
    { label: "Brake Fluid", value: "Brake Fluid" },
    { label: "Diesel", value: "Diesel" },
    { label: "Gas", value: "Gas" },
    { label: "Mixed Vehicle Fluid", value: "Mixed Vehicle Fluid" },
    { label: "Oil", value: "Oil" },
    { label: "Transmission Fluid", value: "Transmission Fluid" },

   
  ];


  const [selectedSpilledFluid, setSelectedSpilledFluid] = useState([]);
  const spillDetails= [

    { label: "In Waterway", value: "In Waterway" },
    { label: "Near Culvert / Storm Drain", value: "Near Culvert / Storm Drain" },
    { label: "Near Waterway", value: "Near Waterway" },
    { label: "On Pavement", value: "On Pavement" },
    { label: "On Soil / Gravel, Other", value: "On Soil / Gravel, Other" },
   
  ];
  const [selectedSpillDetails, setSelectedSpillDetails] = useState([]);

  const liquidDebris  = [
    { label: "None/Solid Debris Only", value: "None/Solid Debris Only" },
    { label: "Dribble", value: "Dribble" },
    { label: "Puddle", value: "Puddle" },
    { label: "Run Off", value: "Run Off" },
    { label: "Soak In", value: "Soak In" },
    { label: "Splatter", value: "Splatter" },
  
   
  ];
  const [selectedLiquidDebris, setSelectedLiquidDebris] = useState([]);

  const getAllcompaniesServer = async (e) => {
    await fetch(`/account/apis/companylist.php`)
      .then((response) => response.json())
      .then((json) => {
        setCompanies(json);
        console.log(json)
      })
      .catch((err) => {
        console.log(err);
      });
  };


//  Driver start

const [imglicense, setimglicense] = useState();
const [imgplate, setimgplate] = useState();
const [imgcard, setimgcard] = useState();
const [imgvehiclecard, setimgvehiclecard] = useState();
const [imgspill, setimgspill] = useState();
const [imgafter, setimgafter] = useState();
const [imga, setimga] = useState();
const [imgb, setimgb] = useState();
const [imgc, setimgc] = useState();



  const [Driver, setDriver] = useState([{
    id: Math.random(),
    name: "",
    phone: "",
    company: "",
    dlsate: "",
    dlnumber: "",
    permission: "no",
    street: "",
    line: "",
    city: "",
    state: "",
    zip: "",
    reason: "",
    insurance: "",
    policy: "",
    agent: "",
    license: "",
    plat: "",
    card: "",
    scence: "",
    spill: "",
    citation: "",
    one: "",
    two: "",
    three: "",
    Activity: "",
    yearcar: "",
    vincar: "",
    makecar: "",
    modelcar: "",
    platcar: "",
    stateecar: "",
   
  },]);
  const addDriver = () => {
    const oldArr = [...Driver];
    let newObj = {
      id: Math.random(),
      name: "",
      phone: "",
      company: "",
      dlsate: "",
      dlnumber: "",
      permission: "no",
      street: "",
      line: "",
      city: "",
      state: "",
      zip: "",
      reason: "",
      insurance: "",
      policy: "",
      agent: "",
      license: "",
      plat: "",
      card: "",
      scence: "",
      spill: "",
      citation: "",
      one: "",
      two: "",
      three: "",
      yearcar: "",
      vincar: "",
      makecar: "",
      modelcar: "",
      platcar: "",
      stateecar: "",
     
      Activity: "normal",
    };
    oldArr.push(newObj);
    setDriver(oldArr);
  };
  const changeArayDriver = (i, changes) => {
 
    const oldArr = [...Driver];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    setDriver(oldArr);

  };
  const delDriver = (id) => {
    let old = [...Driver];
    const result = old.filter((word) => word.id !== id);
    setDriver(result);
  };



//  Driver End
// Labor section start
  const [labor, setLabor] = useState([]);
  const addLabor = () => {
    const oldArr = [...labor];
    let newObj = {
      id: Math.random(),
      rankl: "",
      namel: "",
      hoursl: "",
      costl: "",
      totall: "",
      scenel: [],
      rankv: "",
      namev: "",
      drumv: "",
      costv: "",
      totalv: "",
    };
    oldArr.push(newObj);
    setLabor(oldArr);
  };
  const changeArayLabor = (i, changes) => {
 
    const oldArr = [...labor];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    setLabor(oldArr);

  };
  const delLabor = (id) => {
    let old = [...labor];
    const result = old.filter((word) => word.id !== id);
    setLabor(result);
  };
  const activityOFLabor= [

    { label: "Debris Removal", value: "Debris Removal" },
    { label: "Extrication", value: "Extrication" },
    { label: "Fire Suppression", value: "Fire Suppression" },
    { label: "Incident Command", value: "Incident Command" },
    { label: " Road Block / Detour", value: " Road Block / Detour" },
    { label: " Scene Safety", value: " Scene Safety" },
    { label: "Spill Cleanup", value: "Spill Cleanup" },
    { label: "Traffic Control", value: "Traffic Control" },
    { label: "Vehicle De-energization", value: "Vehicle De-energization" },
    { label: "Vehicle Fire", value: "Vehicle Fire" },
    { label: "Vehicle Stabilization", value: "Vehicle Stabilization" },
   
  ];
//  const [selectedactivityOFLabor, setSelectedactivityOFLabor] = useState([]);
 const setSelectedactivityOFLabor=(array,i)=>{
  changeArayLabor(i,{scenel:array})
 }
// Labor Section End

//Consumables start

const [Consumables, setConsumables] = useState([]);
const addConsumables = () => {
  const oldArr = [...Consumables];
  let newObj = {
    id: Math.random(),
    name: "",
    unit: "",
    quantity: "",
    cost: "",
    total: "",
    Activity: "normal",
  };
  oldArr.push(newObj);
  setConsumables(oldArr);
};
const changeArayConsumables = (i, changes) => {

  const oldArr = [...Consumables];
  const oldObj = oldArr[i];
  oldArr[i] = { ...oldObj, ...changes };
  setConsumables(oldArr);

};
const delConsumables = (id) => {
  let old = [...Consumables];
  const result = old.filter((word) => word.id !== id);
  setConsumables(result);
};


//Consumables End

//Equipment start

const [Equipment, setEquipment] = useState([]);
const addEquipment = () => {
  const oldArr = [...Equipment];
  let newObj = {
    id: Math.random(),
    type: "",
    idd: "",
    quantity: "",
    cost: "",
    hours: "",
    total: "",
    Activity: "normal",
  };
  oldArr.push(newObj);
  setEquipment(oldArr);
};
const changeArayEquipment = (i, changes) => {

  const oldArr = [...Equipment];
  const oldObj = oldArr[i];
  oldArr[i] = { ...oldObj, ...changes };
  setEquipment(oldArr);

};
const delEquipment = (id) => {
  let old = [...Equipment];
  const result = old.filter((word) => word.id !== id);
  setEquipment(result);
};


//Equipment End

//fees start

const [Fee, setFee] = useState([]);

const addFee = () => {
  const oldArr = [...Fee];
  let newObj = {
    id: Math.random(),
    type: "",
    unit: "",
    quantity: "",
    cost: "",
    total: "",
    waste: [],
    Activity: "normal",
  };
  oldArr.push(newObj);
  setFee(oldArr);
};
const changeArayFee = (i, changes) => {

  const oldArr = [...Fee];
  const oldObj = oldArr[i];
  oldArr[i] = { ...oldObj, ...changes };
  setFee(oldArr);

};
const delFee = (id) => {
  let old = [...Fee];
  const result = old.filter((word) => word.id !== id);
  setFee(result);
};
//run
const typeOfWaste= [

  { label: "Corrosive", value: "Corrosive" },
  { label: "Hazardous", value: "Hazardous" },
  { label: "Ignitable", value: "Ignitable" },
  { label: "Reactive", value: "Reactive" },
  { label: "Toxic", value: "Toxic" },
];

const setSelectedTypeOfWaste=(array,i)=>{
changeArayFee(i,{waste:array})
}

//fees End

//Impound Section


//upload start


const saveIm = async (e,t) => {
  e.preventDefault();

  const invoice= [

    { labar: "", vehicular : "" ,consumables:"",equipment:"",fee:"",total:""},
    
  ];

  invoice[0].labar = labor.reduce((n, { totall }) => n + totall, 0);
  invoice[0].vehicular = labor.reduce((n, { totalv }) => n + totalv, 0);
  invoice[0].consumables = Consumables.reduce((n, { totalv }) => n + totalv, 0);
  invoice[0].equipment = Equipment.reduce((n, { total }) => n + total, 0);
  invoice[0].fee = Fee.reduce((n, { total}) => n + total, 0);
  invoice[0].total = invoice[0].labar+invoice[0].vehicular+invoice[0].consumables+invoice[0].equipment+invoice[0].fee


    const data = new FormData();
    data.append("license", imglicense);
    data.append("plate", imgplate);
    data.append("card", imgcard);
    data.append("vehiclecard", imgvehiclecard);
    data.append("spill", imgspill);
    data.append("after", imgafter);
    data.append("o1", imga);
    data.append("o2", imgb);
    data.append("o3", imgc);

    data.append("impound_id", row);

    data.append("incidentdate", incidentDate);
    data.append("incidentstart", incidentTimeStart);
    data.append("incidentend", incidentTimeEnd);
    data.append("claimtype", "MVA");
    data.append("towingincident", towingIncident);
    data.append("incidentlocation", incidentLocation);
    data.append("yards", cname);
    data.append("activities", JSON.stringify(selectedSceneActivities));
    data.append("hazmat", hazmat);
    data.append("enforcement", lawEnforcement);
    data.append("officername", officerName);
    data.append("enforcementcase", enforcementCase);
    data.append("enforcementcasedescription", description);
    data.append("officerapproval", scenper);
    data.append("fluid", JSON.stringify(selectedSpilledFluid));
    data.append("details", JSON.stringify(selectedSpillDetails));
    data.append("debris", JSON.stringify(selectedLiquidDebris));
    data.append("size", spillsize);
    data.append("narrative", Narrative);
    data.append("labor", JSON.stringify(labor));
    data.append("consumables", JSON.stringify(Consumables));
    data.append("equipment", JSON.stringify(Equipment));
    data.append("disposalfees", JSON.stringify(Fee));
    data.append("driver", JSON.stringify(Driver));

    data.append("invoice", JSON.stringify(invoice));
  
       axios({
        method: "post",
        url: "/account/apis/add_claim.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((s)=>{
        if(t==="save")
        {
          console.log(s.data)
          history.push("/claimupdate")
        }else{
          history.push("/review")
        }
      }).catch((ss)=>{
        if(t==="save")
        {
          console.log(ss)
          history.push("/claimupdate")
         
        }else{
          history.push("/review")
        }
      });
};


useEffect(() => {
  let a = localStorage.getItem("id_row");
  setRow(a);
  const impount =JSON.parse(localStorage.getItem("byimpound"))
  

  setYear(impount[0].year)
  setvin(impount[0].vin)
  setmake(impount[0].make)
  setmodel(impount[0].model)
  setplat(impount[0].license)
  setstate(impount[0].state)



  changeArayDriver(0,{yearcar:impount[0].year,platcar:impount[0].license,vincar:impount[0].vin,makecar:impount[0].make,modelcar:impount[0].model,platcar:impount[0].license,stateecar:impount[0].state})

  

  if (a === null) {
    history.push("/report");
  } 
  const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));
  setUname(v[0].name);
  setCname(v[0].cname);
 
  //getAllcompaniesServer()
  
  if (v == null) {
  } else {
    if (v[0].type === "user") {
    } else {
      setDisply("company");
    }
  }






}, []);
//enf upload
  return(
    <div > <div
      className="container"
      style={{ marginTop: "70px", marginBottom: "30px",  }}
    >
      <form>
       <strong className="d-inline-block mb-2 text-success">
          <h3> New Claim </h3>
         
        </strong>
               <div className="row">
          <div className="col-sm-4 col-4">
            <p>
          
              <CircleFill
                color="#1ceb15"
                size={12}
                style={{ marginRight: "3px" }}
              />{" "}
              Active |{" "}
              <Display
                size={20}
                style={{ marginRight: "3px", marginLeft: "3px" }}
              />
              <> {uname}</>
            </p>
          </div>
         

        </div>
        <div className="row" >
        <div className="col-sm-11 col-8">
          </div>

          <div className="col-sm-1 col-2">
        <button
            
            style={{ marginTop: "20px", backgroundColor: "green",width:"100px" }}
            className="btn btn-secondary"
            onClick={(e)=>( saveIm(e,"save"))}
          >
                Save
          </button>
    
          </div>
     

        </div>

        <div style={{marginTop:"15px", backgroundColor: "#48b4e0" }} className="row">
        <div onClick={()=>(
          setMainView("0")
        )} style={{marginTop:"15px",padding: "20px",backgroundColor: mainView=="0"?"#fff":"#48b4e0"}} className="col-sm-2 col-6">
         <center> <p> <Signpost color="#144a73"
                size={35}
                />{" "}
            Scene</p></center>
          <ProgressBar   now={35}  />
               </div>
               <div onClick={()=>(
          setMainView("1")
        )} style={{marginTop:"15px",padding: "20px",backgroundColor: mainView=="1"?"#fff":"#48b4e0"}} className="col-sm-2 col-6">
         <center> <p> <PersonCircle color="#144a73"
                size={35}
                />{" "}
            Drivers</p></center>
          <ProgressBar   now={40} />
               </div>

               <div onClick={()=>(
          setMainView("2")
        )} style={{marginTop:"15px",padding: "20px",backgroundColor: mainView=="2"?"#fff":"#48b4e0"}} className="col-sm-2 col-6">
         <center> <p> <PersonPlusFill color="#144a73"
                size={35}
                />{" "}
            Labor</p></center>
          <ProgressBar   now={52} />
               </div>
          <div onClick={()=>(
          setMainView("3")
        )} style={{marginTop:"15px",padding: "20px",backgroundColor: mainView=="3"?"#fff":"#48b4e0"}}  className="col-sm-2 col-6">
         <center> <p> <Slack color="#144a73"
                size={35}
                />{" "}
            Consumables</p></center>
          <ProgressBar   now={65} />
               </div>
          <div onClick={()=>(
          setMainView("4")
        )} style={{marginTop:"15px",padding: "20px",backgroundColor: mainView=="4"?"#fff":"#48b4e0"}} className="col-sm-2 col-6">
         <center> <p> <SunFill color="#144a73"
                size={35}
                />{" "}
            Equipment</p></center>
          <ProgressBar   now={80}/>
               </div>
          <div onClick={()=>(
          setMainView("5")
        )} style={{marginTop:"15px",padding: "20px",backgroundColor: mainView=="5"?"#fff":"#48b4e0"}}className="col-sm-2 col-6">
         <center> <p> <InfoSquare color="#144a73"
                size={35}
                />{" "}
            Disposal Fees</p></center>
          <ProgressBar   now={50} />
               </div>

               
        </div>
        



{
  mainView=="0"?      <>
  <div className="row">
    <div className="col-sm-12 col-12">
      <div
        className="h-30"
        style={{
          marginTop: "50px",
          backgroundColor: "#108dfa",
          
          paddingTop: "5px",
          paddingBottom: "4px",
        }}
      >
        {" "}
        <h4  style={{paddingLeft: 15}}>Scene  </h4>
      </div>
  
  </div>
         {/* 2nd row start */}
    <div className="row">
    <div style={{marginTop:"15px"}} className="col-sm-3 col-4">
    <label htmlFor="validationCustom01">Incident Date *</label>
      <input
        type="date"
        id="fname"
        value={incidentDate}
    onChange={(t)=>{setIncidentDate(t.target.value);
    }}
     
        
        placeholder="Company Name"
        className="form-control form-control-sm"
      />
      </div>
    <div style={{marginTop:"15px"}} className="col-sm-3 col-4">
    <label htmlFor="validationCustom01">Incident Start Time *</label>
      <input
        type="time"
        id="fname"
        placeholder="Incident Start Time "
        className="form-control form-control-sm"
        value={incidentTimeStart}
    onChange={(t)=>{setIncidentTimeStart(t.target.value);
    }}
      />

      </div>
    <div style={{marginTop:"15px"}} className="col-sm-3 col-4">
    <label htmlFor="validationCustom01">Incident End Time *</label>
      <input
        type="time"
        value={incidentTimeEnd}
        onChange={(t)=>{setIncidentTimeEnd(t.target.value);
        }}
    
        
        placeholder="Incident End Time"
        className="form-control form-control-sm"
      />

      </div>
    <div style={{marginTop:"15px"}} className="col-sm-3 col-12">
    <label htmlFor="lname">Claim Type</label>
    <input
        type="text"
        id="fname" 
        disabled
        value="MVA"
        className="form-control form-control-sm"
      />

      </div>
      </div>
        {/* 2nd row end */}
        <div className="row">
    <div style={{marginTop:"15px"}} className="col-sm-6 col-6">
    <label htmlFor="validationCustom01">Towing Incident # *</label>
      <input
        type="text"
        id="fname"
        placeholder="Towing Incident"
        className="form-control form-control-sm"
        value={towingIncident}
        onChange={(t)=>{setTowingIncident(t.target.value);
        }}
      />

      </div>
    <div style={{marginTop:"15px"}} className="col-sm-6 col-6">
    <label htmlFor="validationCustom01">Incident Location *</label>
      <input
        type="text"
        id="fname"
        placeholder="Towing Incident"
        className="form-control form-control-sm"
        value={incidentLocation}
        onChange={(t)=>{setIncidentLocation(t.target.value);
        }}
      />
      </div>
      </div>
      {/* 3rd row start */}
        <div className="row">
    <div style={{marginTop:"15px"}} className="col-sm-6 col-6">
    <label htmlFor="validationCustom01">Responding Yards *</label>
    <input
        type="text"
        id="fname"
        placeholder="Towing Incident"
        className="form-control form-control-sm"
        disabled
        value={cname}
        
      />

      </div>
    <div style={{marginTop:"15px"}} className="col-sm-6 col-6">
    <label htmlFor="validationCustom01">Hazmat Response *</label>
    <input
        type="text"
        id="fname"
        placeholder="Towing Incident"
        className="form-control form-control-sm"
        disabled
        value={hazmat}
        
      /> </div>

      </div>
      {/* 4th line start */}
        <div className="row">
    
    <div style={{marginTop:"15px"}} className="col-sm-12 col-12">
    <label htmlFor="validationCustom01">{" "}Scene Activities*</label>
    <MultiSelect
    
  options={sceneActivities}
  value={selectedSceneActivities}
  onChange={setSelectedSceneActivities}
  labelledBy="Select nw"

/></div>
</div>

{/* 5th */}
<div style={{marginTop:"15px",marginBottom:"25px" }}className="row">
    <div className="col-sm-12 col-12">
      <div
        className="h-30"
        style={{
          marginTop: "20px",
          backgroundColor: "#108dfa",
          
          paddingTop: "5px",
          paddingBottom: "4px",
        }}
      >{" "}<h4 style={{paddingLeft: 20}}>Law Enforcement </h4>
      </div>
    </div>
  </div>
  {/* 6th */}
<div className="row">         
    <div className="col-sm-4 col-4">
    <label htmlFor="validationCustom01">Law Enforcement on Scene *</label>
    <Form.Select
        size="sm"
        value={lawEnforcement}
        onChange={(t)=>{setlawEnforcement(t.target.value);
        }}
      >
        
        <option>None</option>
        <option>City</option>
        <option>County</option>
        <option>State</option>

      </Form.Select>
   
    </div>
    <div className="col-sm-4 col-4">
    <label htmlFor="validationCustom01">Officer Name</label>
      <input
        type="text"
        id="fname"
        placeholder="Officer Name"
        className="form-control form-control-sm"
        value={officerName}
        onChange={(t) => {
          setOfficerName(t.target.value);
        }}
      />
   
    </div>
    <div className="col-sm-4 col-4">
    <label htmlFor="validationCustom01">Law Enforcement Case #</label>
      <input
        type="text"
        id="fname"
        placeholder="Law Enforcement Case "
        className="form-control form-control-sm"
        value={enforcementCase}
        onChange={(t)=>{setEnforcementCase(t.target.value);
        }}
      />
   
    </div>
  </div>
  {/* 7th */}
<div className="row">         
  
    <div className="col-sm-6 col-12">
    <label htmlFor="validationCustom01">Law Enforcement Case Description</label>
      <input
        type="text"
        id="fname"
        placeholder="Law Enforcement Case Description"
        className="form-control form-control-sm"
        value={description}
        onChange={(t)=>{setdescription(t.target.value);
        }}
      />
   
    </div>
    <div className="col-sm-6 col-12">
    <div   onClick={() => {
            if (scenper == "no") {
              setScenper("yes");
            } else {
              setScenper("no");
            }
          }}  style={{marginTop: "30px"}} className="form-check">
        <input
        chack={scenper=="yes"? true:false}
          className="form-check-input"
          type="checkbox"
        
         
          id="scenper"
        />
        <label  className="form-check-label" htmlFor="scenper">
          Did you receive permission from an officer to perform the cleanup?
          
        </label>
      </div></div>
  </div>
  {/* 8th */}
  <div className="row" style={{marginBottom: "25px", marginTop: "10px",}}>
    <div className="col-sm-12 col-12">
      <div
        className="h-30"
        style={{
          marginTop: "20px",
          backgroundColor: "#108dfa",
          
          paddingTop: "5px",
          paddingBottom: "4px",
        }}
      >{" "}<h4 style={{paddingLeft: 15}}>Spill  </h4>
      </div>
    </div>
  </div>

{/* 9th */}
<div className="row">
    <div className="col-sm-3 col-6">
    <label htmlFor="validationCustom01">{" "}Spilled Fluid *</label>
    <MultiSelect
    
  options={spilledFluid}
  value={selectedSpilledFluid}
  onChange={setSelectedSpilledFluid}
  labelledBy="Select nw"

/>
 
    </div>
    <div className="col-sm-3 col-6">
    <label htmlFor="validationCustom01">{" "}Spill Details *</label>
    <MultiSelect
    
  options={spillDetails}
  value={selectedSpillDetails}
  onChange={setSelectedSpillDetails}
  labelledBy="Select nw"

/>
  
    </div>
    <div className="col-sm-3 col-6">
    <label htmlFor="validationCustom01">{" "}Liquid Debris *</label>
    <MultiSelect
    
  options={liquidDebris}
  value={selectedLiquidDebris}
  onChange={setSelectedLiquidDebris}
  labelledBy="Select nw"

/>
  
    </div>
    <div className="col-sm-3 col-6">
      <label>Spill Size *</label>
      <input
        type="text"
        id="fname"
        placeholder="Spill Size"
        value={spillsize}
        onChange={(t) => {
          setSpillsize(t.target.value)
        }}
        className="form-control form-control-sm"
      />
   
    </div>
  </div>
          {/* 9th */}
          <div className="row" style={{marginTop: "15px", marginBottom:"25px"}}>
    <div className="col-sm-12 col-12">
      <div
        className="h-30"
        style={{
          marginTop: "20px",
          backgroundColor: "#108dfa",
          
          paddingTop: "5px",
          paddingBottom: "4px",
        }}
      >{" "}<h4 style={{paddingLeft: 15}}>Narrative   </h4>
      </div>
    </div>
  </div>
          {/* 10th */}
         
  <div className="row">
    <div className="col-sm-12 col-12">
      <label htmlFor="validationCustom01">Narrative  </label>
      <textarea
        value={Narrative}
        onChange={(t) => {
         setNarrative(t.target.value);
        }}
        className="form-control form-control-sm"
      ></textarea>
    </div>
  </div>
<div style={{marginTop: "120px"}}>

</div>
        
  </div>




       </>:mainView=="1"?<>


       {/* <label  style={{marginTop:"60px", fontSize:"23px", fontWeight:"bold",  }} onClick={()=>{
           addDriver()
         }} htmlFor="validationCustom01">   <PlusCircleFill color="#144a73"
                size={35} /> Add Driver </label> */}


       {
         Driver.map((v,index)=>(
          <>
     


       
          <div className="row" style={{marginBottom: "15px",marginTop: "30px"}}>
     <div className="col-sm-12 col-12">
       <div
         className="h-30"
         style={{
           marginTop: "20px",
           backgroundColor: "#108dfa",
           
           paddingTop: "5px",
           paddingBottom: "4px",
         }}
       ><h4 style={{paddingLeft: 15}}>Drivers  </h4>
       </div>
     </div>
   </div>
 
     <div className="row">
            {/* 1st row start */}
       <div className="row">
       <div style={{marginTop:"15px"}} className="col-sm-3 col-4">
       <label htmlFor="validationCustom01">Driver Name *</label>
         <input
           type="text"
           id="fname"
           value={v.name}
           onChange={(t) => {
            changeArayDriver(index,{name:t.target.value})
          }}
           placeholder="Driver Name"
           className="form-control form-control-sm"
         />
         </div>
       <div style={{marginTop:"15px"}} className="col-sm-3 col-4">
       <label htmlFor="validationCustom01">Phone Number</label>
         <input
           type="text"
           value={v.phone}
           onChange={(t) => {
            changeArayDriver(index,{phone:t.target.value})
          }}
           id="fname"
           placeholder="Phone Number "
           className="form-control form-control-sm"
         />
 
         </div>
       <div style={{marginTop:"15px"}} className="col-sm-3 col-4">
       <label htmlFor="validationCustom01">Company Name </label>
         <input
           type="text"
           value={v.company}
           onChange={(t) => {
            changeArayDriver(index,{company:t.target.value})
          }}           
           placeholder="Company Name"
           className="form-control form-control-sm"
         />
 
         </div>
       <div style={{marginTop:"15px"}} className="col-sm-3 col-12">
       <label htmlFor="lname">DL State *</label>
       <Form.Select
   
   size="sm"
   value={v.dlsate}
   onChange={(t) => {
    changeArayDriver(index,{dlsate:t.target.value})
  }}
 >
      <option></option>
  <option>AB</option>
<option>AK</option>
<option>AL</option>
<option>AR</option>
<option>AZ</option>
<option>BC</option>
<option>CA</option>
<option>CA</option>
<option>CO</option>
<option>CT</option>
<option>DC</option>
<option>DE</option>
<option>FL</option>
<option>GA</option>
<option>HI</option>
<option>IA</option>
<option>ID</option>
<option>IL</option>
<option>IN</option>
<option>KS</option>
<option>KY</option>
<option>LA</option>
<option>MA</option>
<option>MB</option>
<option>MD</option>
<option>ME</option>
<option>MEX</option>
<option>MI</option>
<option>MN</option>
<option>MO</option>
<option>MS</option>
<option>MT</option>
<option>NB</option>
<option>NC</option>
<option>ND</option>
<option>NE</option>
<option>NH</option>
<option>NJ</option>
<option>NL</option>
<option>NM</option>
<option>NS</option>
<option>NT</option>
<option>NU</option>
<option>NV</option>
<option>NY</option>
<option>OH</option>
<option>OK</option>
<option>ON</option>
<option>OR</option>
<option>PA</option>
<option>PE</option>
<option>QC</option>
<option>RI</option>
<option>SC</option>
<option>SD</option>
<option>SK</option>
<option>TN</option>
<option>TX</option>
<option>UT</option>
<option>VA</option>
<option>VT</option>
<option>WA</option>
<option>WI</option>
<option>WV</option>
<option>WY</option>
<option>YT</option>
 </Form.Select>

 
         </div>
         </div>
           {/* 2nd row end */}
           <div className="row">
       <div style={{marginTop:"15px"}} className="col-sm-6 col-6">
       <label htmlFor="validationCustom01">DL Number *</label>
         <input
           type="text"
           id="fname"
           value={v.dlnumber}
           onChange={(t) => {
            changeArayDriver(index,{dlnumber:t.target.value})
          }}
           placeholder="DL Number *"
           className="form-control form-control-sm"
         />
         </div>
       <div      style={{marginTop:"15px"}} className="col-sm-6 col-6">
       <div  style={{marginTop: "30px"}} className="form-check">
           <input
         onClick={() => {
          if (v.permission == "no") {
           changeArayDriver(index,{permission:'yes'})

          } else {
           changeArayDriver(index,{permission:'no'})
          }
        }}
             className="form-check-input"
             type="checkbox"
             checked={v.permission == "no"?false:true}
             id="dd"
           />
           <span onClick={() => {
          if (v.permission == "no") {
           changeArayDriver(index,{permission:'yes'})

          } else {
           changeArayDriver(index,{permission:'no'})
          }
        }}  className="form-check-label" htmlFor="dd">
             Did you receive permission from  driver to perform the cleanup?
            
           </span>
         </div>
         </div>
         </div>
 
   {/* 5th */}
   <div className="row" style={{marginTop: "15px",marginBottom:"25px"}}>
       <div className="col-sm-12 col-12">
         <div
           className="h-30"
           style={{
             marginTop: "20px",
             backgroundColor: "#108dfa",
             
             
             paddingTop: "5px",
             paddingBottom: "4px",
           }}
         >{" "}<h4 style={{paddingLeft: 15}}>Billing Address * </h4>
         </div>
       </div>
     </div>
     {/* 6th */}
   <div className="row">         
       <div className="col-sm-4 col-4">
       <label htmlFor="validationCustom01">Street *</label>
       <input
           type="text"
           id="fname"
           value={v.street}
           onChange={(t) => {
            changeArayDriver(index,{street:t.target.value})
          }}
           placeholder="Street "
           className="form-control form-control-sm"
         />
      
       </div>
       <div className="col-sm-4 col-4">
       <label htmlFor="validationCustom01">Line 2</label>
         <input
           type="text"
           id="fname"
           value={v.line}
           onChange={(t) => {
            changeArayDriver(index,{line:t.target.value})
          }}
           placeholder="Line 2"
           className="form-control form-control-sm"
         />
      
       </div>
       <div className="col-sm-4 col-4">
       <label htmlFor="validationCustom01">City #</label>
         <input
           type="text"
           id="fname"
           value={v.city}
           onChange={(t) => {
            changeArayDriver(index,{city:t.target.value})
          }}
           placeholder="City"
           className="form-control form-control-sm"
         />
      
       </div>
     </div>
     {/* 7th */}
   <div className="row">         
     
       <div className="col-sm-6 col-12">
       <label htmlFor="validationCustom01">State *</label>

              <Form.Select
   
   size="sm"
   value={v.state}
   onChange={(t) => {
    changeArayDriver(index,{state:t.target.value})
  }}
 >
     <option></option>
  <option>AB</option>
<option>AK</option>
<option>AL</option>
<option>AR</option>
<option>AZ</option>
<option>BC</option>
<option>CA</option>
<option>CA</option>
<option>CO</option>
<option>CT</option>
<option>DC</option>
<option>DE</option>
<option>FL</option>
<option>GA</option>
<option>HI</option>
<option>IA</option>
<option>ID</option>
<option>IL</option>
<option>IN</option>
<option>KS</option>
<option>KY</option>
<option>LA</option>
<option>MA</option>
<option>MB</option>
<option>MD</option>
<option>ME</option>
<option>MEX</option>
<option>MI</option>
<option>MN</option>
<option>MO</option>
<option>MS</option>
<option>MT</option>
<option>NB</option>
<option>NC</option>
<option>ND</option>
<option>NE</option>
<option>NH</option>
<option>NJ</option>
<option>NL</option>
<option>NM</option>
<option>NS</option>
<option>NT</option>
<option>NU</option>
<option>NV</option>
<option>NY</option>
<option>OH</option>
<option>OK</option>
<option>ON</option>
<option>OR</option>
<option>PA</option>
<option>PE</option>
<option>QC</option>
<option>RI</option>
<option>SC</option>
<option>SD</option>
<option>SK</option>
<option>TN</option>
<option>TX</option>
<option>UT</option>
<option>VA</option>
<option>VT</option>
<option>WA</option>
<option>WI</option>
<option>WV</option>
<option>WY</option>
<option>YT</option>

 </Form.Select>
         
      
       </div>
       <div className="col-sm-6 col-12">
       <label htmlFor="validationCustom01">Zip *</label>
       <input
           type="text"
           id="fname"
           value={v.zip}
           onChange={(t) => {
            changeArayDriver(index,{zip:t.target.value})
          }}
           placeholder="Zip"
           className="form-control form-control-sm"
         />
      </div>
     </div>
     {/* 8th */}
     <div className="row" style={{marginTop: "15px",marginBottom:"25px"}}>
       <div className="col-sm-12 col-12">
         <div
           className="h-30"
           style={{
             marginTop: "20px",
             backgroundColor: "#108dfa",
             
             paddingTop: "5px",
             paddingBottom: "4px",
           }}
         >{" "}<h4 style={{paddingLeft: 15}}>Insurance * </h4>
         </div>
       </div>
     </div>
 
 {/* 9th */}
 <div className="row">
       <div className="col-sm-12 col-12">
       <label htmlFor="validationCustom01">Reason for No Insurance </label>
       <Form.Select
      
           size="sm"
           value={v.reason}
           onChange={(t) => {
            changeArayDriver(index,{reason:t.target.value})
          }}
         >
    
    <option disabled="" selected="">Reason for No Insurance</option>
    <option value="N/A">N/A</option>
    <option value="Info Unavailable">Info Unavailable</option>
    <option value="Self-Insured">Self-Insured</option>
    <option value="Un-Insured">Un-Insured</option>
  
         </Form.Select>
   
     
       </div>
       
 
     </div>
             {/* 9th */}
             <div className="row" style={{marginTop: "15px",marginBottom:"25px"}} >
       <div className="col-sm-12 col-12">
         <div
           className="h-30"
           style={{
             marginTop: "20px",
             backgroundColor: "#108dfa",
             
             paddingTop: "5px",
             paddingBottom: "4px",
           }}
         >{" "}<h4 style={{paddingLeft: 15}}>Policy Info   </h4>
         </div>
       </div>
     </div>
             {/* 10th */}
            
     <div className="row">
       <div className="col-sm-4 col-4">
         <label htmlFor="validationCustom01">Insurance Company Name </label>
         
       <input
           type="text"
           id="fname"
           value={v.insurance}
           onChange={(t) => {
            changeArayDriver(index,{insurance:t.target.value})
          }}
           placeholder="Insurance Company Name"
           className="form-control form-control-sm"
         />
       </div>
       <div className="col-sm-3 col-4">
         <label htmlFor="validationCustom01">Policy Number </label>
         
       <input
           type="text"
           id="fname"
           value={v.policy}
           onChange={(t) => {
            changeArayDriver(index,{policy:t.target.value})
          }}
           placeholder="Policy Number "
           className="form-control form-control-sm"
         />
       </div>
       <div className="col-sm-3 col-4">
         <label htmlFor="validationCustom01">Agent Phone Number </label>
         
       <input
           type="text"
           id="fname"
           value={v.agent}
           onChange={(t) => {
            changeArayDriver(index,{agent:t.target.value})
          }}
           placeholder="Agent Phone Number"
           className="form-control form-control-sm"
         />
       </div>
       <div style={{marginTop:"20px"}} className="col-sm-2 col-3">
{
  index==0?<></>:<Trash color="#144a73"
  onClick={() => delDriver(v.id)}
                 size={25} />
}
            </div>
     </div>
     <div className="row" style={{marginTop: "15px"}} >
       <div className="col-sm-12 col-12">
         <div
           className="h-30"
           style={{
             marginTop: "20px",
             backgroundColor: "#108dfa",
             
             paddingTop: "5px",
             paddingBottom: "4px",
           }}
         >{" "}<h4 style={{paddingLeft: 15}}> Photographs  </h4>
         </div>
       </div>
 
    
     </div>
     <div className="row" style={{marginTop: "15px"}} >
     <div className="col-sm-4 col-4">
       <label htmlFor="validationCustom01">Driver License </label>
       <input
           type="file"
           id="fname"
           accept="image/*"
            onChange={(e) => {
            setimglicense(e.target.files[0]);
          }}
           placeholder="Zip"
           className="form-control form-control-sm"
         />
      </div>
     <div className="col-sm-4 col-4">
       <label htmlFor="validationCustom01">License Plate </label>
       <input
           type="file"
           id="fname"
           accept="image/*"
        
           onChange={(e) => {
            setimgplate(e.target.files[0]);
          }}
           placeholder="Zip"
           className="form-control form-control-sm"
         />
      </div>
     <div className="col-sm-4 col-4">
       <label htmlFor="validationCustom01">Insurance Card  </label>
       <input
           type="file"
           id="fname"
        
           onChange={(e) => {
            setimgcard(e.target.files[0]);
          }}
           placeholder="Zip"
           className="form-control form-control-sm"
         />
      </div>
 
    
     </div>
     <div className="row" style={{marginTop: "15px"}} >
     <div className="col-sm-4 col-4">
       <label htmlFor="validationCustom01">Pre Vehicle Scene</label>
       <input
           type="file"
           id="fname"
        
           onChange={(e) => {
            setimgvehiclecard(e.target.files[0]);
          }}
           placeholder="Zip"
           className="form-control form-control-sm"
         />
      </div>
     <div className="col-sm-4 col-4">
       <label htmlFor="validationCustom01">Spill </label>
       <input
           type="file"
           id="fname"
        
           onChange={(e) => {
            setimgspill(e.target.files[0]);
          }}
           placeholder="Zip"
           className="form-control form-control-sm"
         />
      </div>
     <div className="col-sm-4 col-4">
       <label htmlFor="validationCustom01">After Vehicle Scene</label>
       <input
           type="file"
           id="fname"
        
           onChange={(e) => {
            setimgafter(e.target.files[0]);
          }}
           placeholder="Zip"
           className="form-control form-control-sm"
         />
      </div>
 
    
     </div>
     <div className="row" style={{marginTop: "15px"}} >
     <div className="col-sm-4 col-4">
       <label htmlFor="validationCustom01">Other 1 </label>
       <input
           type="file"
           id="fname"
        
           onChange={(e) => {
            setimga(e.target.files[0]);
          }}
           placeholder="Zip"
           className="form-control form-control-sm"
         />
      </div>
     <div className="col-sm-4 col-4">
       <label htmlFor="validationCustom01">Other 2 </label>
       <input
           type="file"
           id="fname"
        
           onChange={(e) => {
            setimgb(e.target.files[0]);
          }}
           placeholder="Zip"
           className="form-control form-control-sm"
         />
      </div>
     <div className="col-sm-4 col-4">
       <label htmlFor="validationCustom01">Other 3 </label>
       <input
           type="file"
           id="fname"
        
           onChange={(e) => {
            setimgc(e.target.files[0]);
          }}
           placeholder="Zip"
           className="form-control form-control-sm"
         />
      </div>
 
    
     </div>
 
           <div style={{marginBottom:"70px"}} >
 
           </div>
     </div>
 
          </>
         ))
       }
       
       </>
     :mainView=="2"?<div>

  
                
              {
                labor.map((v,index)=>(
              <>
               <div style={{marginBottom:"20px"}} className="row">
    <div className="col-sm-12 col-12">
      <div
        className="h-30"
        style={{
          marginTop: "50px",
          backgroundColor: "#108dfa",
          
          paddingTop: "5px",
          paddingBottom: "4px",
        }}
      >
        {" "}
        <h4  style={{paddingLeft: 15}}>Labor  </h4>
      </div>
  
  </div>
  </div>
                      <div  style={{marginTop:"20px"}} className="row">
          <div className="col-sm-3 col-3">
          <label htmlFor="lname">Rank *</label>

<Form.Select
  size="sm"
  value={v.rankl}
  onChange={(t) => {
    changeArayLabor(index,{rankl:t.target.value})
  }}
>
<option>Select Rank</option>
  <option>HAZWOPER CERTIFIED</option>
  <option>HAZWOPER CERTIFIED SUPERVISOR</option>
  

</Form.Select>


          </div>
          <div className="col-sm-3 col-3">
          <label htmlFor="lname">Name *</label>

<Form.Select
  size="sm"
  value={v.namel}
  onChange={(t) => {
    changeArayLabor(index,{namel:t.target.value})
  }}
>
<option>Select Name</option>
  <option>Daniel Aguilar</option>
  <option>Diazdeleon</option>
  <option>Moris Musharbash</option>
  

</Form.Select>


          </div>
          <div className="col-sm-3 col-3">
          <label htmlFor="lname">Hours  *</label>
          <input
                      
                      type="number"
                      id="fname"
                   
                      placeholder="Hours"
                      value={v.hoursl}
                      onChange={(t) => {

                       const tot=t.target.value*v.costl;

                        changeArayLabor(index,{hoursl:t.target.value,totall:tot})
                      }}
                      className="form-control form-control-sm"
                    />


          </div>
          <div className="col-sm-3 col-3">

<label htmlFor="lname">Cost *</label>


<div className="input-group mb-3">
  <div className="input-group-prepend">
    <span className="input-group-text">$</span>
  </div>
  <input  value={v.costl}
                      onChange={(t) => {
                        const tot=t.target.value*v.hoursl; 
                        changeArayLabor(index,{costl:t.target.value,totall:tot})
                      }} type="number" className="form-control" aria-label="Amount (to the nearest dollar)" />
                      </div>


          </div>

              </div>
              <div style={{marginTop:"20px"}} className="row">
          <div className="col-sm-9 col-9">
          <label>Scene Activities * </label>

          
            <MultiSelect
              options={activityOFLabor}
              value={v.scenel}
              onChange={(a)=>(
                setSelectedactivityOFLabor(a,index)
              )}
              labelledBy="select" />
                
            </div>

            <div style={{marginTop:"20px"}} className="col-sm-3 col-3">
<Trash color="#144a73"
 onClick={() => delLabor(v.id)}
                size={25} />
            </div>



{/* labar 2 */}

            <div style={{marginBottom:"20px"}} className="row">
    <div className="col-sm-12 col-12">
      <div
        className="h-30"
        style={{
          marginTop: "50px",
          backgroundColor: "#108dfa",
          
          paddingTop: "5px",
          paddingBottom: "4px",
        }}
      >
        {" "}
        <h4  style={{paddingLeft: 15}}>Vehicular Fluid & Gas Disposal  </h4>
      </div>
  
  </div>
  </div>
  <div  style={{marginTop:"20px"}} className="row">
          <div className="col-sm-3 col-3">
          <label htmlFor="lname">Rank *</label>

<Form.Select
  size="sm"
  value={v.rankv}
  onChange={(t) => {
    changeArayLabor(index,{rankv:t.target.value})
  }}
>
<option>Select Rank</option>
<option>HazMat Regular</option><option>HazMat Liability</option><option>HazMat Gas</option>
  

</Form.Select>


          </div>
          <div className="col-sm-3 col-3">
          <label htmlFor="lname">Name *</label>

<Form.Select
  size="sm"
  value={v.namev}
  onChange={(t) => {
    changeArayLabor(index,{namev:t.target.value})
  }}
>
<option>Select Name</option>
  <option>Daniel Aguilar</option>
  <option>Diazdeleon</option>
  <option>Moris Musharbash</option>
  

</Form.Select>


          </div>
          <div className="col-sm-3 col-3">
          <label htmlFor="lname">Drum  *</label>
          <input
                      
                      type="number"
                      
                   
                      placeholder="Drum"
                      value={v.drumv}
                      onChange={(t) => {
                       const tot=t.target.value*v.costv;
                        changeArayLabor(index,{drumv:t.target.value,totalv:tot})
                      }}
                      className="form-control form-control-sm"
                    />


          </div>
          <div className="col-sm-3 col-3">
    

<label htmlFor="lname">Cost * </label>


<div className="input-group mb-3">
  <div className="input-group-prepend">
    <span className="input-group-text">$</span>
  </div>
  <input  value={v.costv}
                      onChange={(t) => {
                        const tot=t.target.value*v.drumv; 
                        changeArayLabor(index,{costv:t.target.value,totalv:tot})
                      }} type="number" className="form-control" aria-label="Amount (to the nearest dollar)" />
                      </div>
          </div>

              </div>
            </div>

                 
                  </>

                ))
              }
                     <label  style={{marginTop:"60px", fontSize:"23px", fontWeight:"bold",  }} onClick={()=>{
           addLabor()
         }} htmlFor="validationCustom01">   <PlusCircleFill color="#144a73"
                size={35} /> Add Labor </label>
        
         </div>:mainView=="3"?<>
         <div>
           {
             Consumables.map((v,index)=>(<>

<div style={{marginBottom:"20px"}} className="row">
    <div className="col-sm-12 col-12">
      <div
        className="h-30"
        style={{
          marginTop: "50px",
          backgroundColor: "#108dfa",
          
          paddingTop: "5px",
          paddingBottom: "4px",
        }}
      >
        {" "}
        <h4  style={{paddingLeft: 15}}>Consumables  </h4>
      </div>
  
  </div>
  </div>
               
              <div  style={{marginTop:"20px"}} className="row">
              <div className="col-sm-4 col-3">
              <label htmlFor="lname">Name *</label>
    
    <Form.Select
      size="sm"
      value={v.name}
      onChange={(t) => {
        changeArayConsumables(index,{name:t.target.value})
      }}
    >
      <option>Select Name</option>
      <option>ABSORBENT MOP HEADS</option>
      <option>ADMIN FEE</option>
      <option>BAG OF ABSORBENT </option>
    
      <option>GRAY ABSORBENT PADS</option>
      <option>GRAY UNIVERSAL ABSORBENT SOCK</option>
      <option>HAZARDOUS MATERIAL LABELS</option>
      <option>ORANGE DISPOSAL WITH TIE</option>
     
    
    </Form.Select>
    
    
              </div>
              <div className="col-sm-2 col-3">
              <label htmlFor="lname">Quantity  *</label>
              <input
                      
                      type="text"
                      id="fname"
                      name="vin"
                      placeholder="Quantity"
                      value={v.quantity}
                      onChange={(t) => {
                        const tot=t.target.value*v.cost;
                        changeArayConsumables(index,{quantity:t.target.value,total:tot})
                      }}
                      className="form-control form-control-sm"
                    />

              </div>
              <div className="col-sm-2 col-3">
              <label htmlFor="lname">Unit Of Measurement *</label>
    
    <Form.Select
      size="sm"
      value={v.unit}
      onChange={(t) => {
        changeArayConsumables(index,{unit:t.target.value})
      }}
    >
       <option>Select Unit</option>
      <option>Each</option>
      <option>Gallon</option>
      <option>Per Bag</option>
   
    
    </Form.Select>
    
    
              </div>
              <div className="col-sm-2 col-3">
              <label htmlFor="lname">Cost  *</label>
              <div className="input-group mb-3">
  <div className="input-group-prepend">
    <span className="input-group-text">$</span>
  </div>
  <input  value={v.cost}
                      onChange={(t) => {
                        const tot=t.target.value*v.quantity;
                        changeArayConsumables(index,{cost:t.target.value,total:tot})
                      }} type="number" className="form-control" aria-label="Amount (to the nearest dollar)" />

</div>

              </div>
              <div style={{marginTop:"20px"}} className="col-sm-1 col-3">
<Trash color="#144a73"
 onClick={() => delConsumables(v.id)}
                size={25} />
            </div>

              </div>

              </>))
           }


         <label style={{marginTop:"60px", fontSize:"23px", fontWeight:"bold",  }} onClick={()=>{
           addConsumables()
         }} htmlFor="validationCustom01">   <PlusCircleFill color="#144a73"
                size={35} /> Add Consumables </label>

         </div>
         
         
         </>:mainView=="4"?<>
         <div>
           {
             Equipment.map((v,index)=>(<>
                    <div style={{marginBottom:"20px"}} className="row">
    <div className="col-sm-12 col-12">
      <div
        className="h-30"
        style={{
          marginTop: "50px",
          backgroundColor: "#108dfa",
          
          paddingTop: "5px",
          paddingBottom: "4px",
        }}
      >
        {" "}
        <h4  style={{paddingLeft: 15}}>Equipment  </h4>
      </div>
  
  </div>
  </div>
             
       
              <div  style={{marginTop:"20px"}} className="row">
              <div className="col-sm-3 col-3">
              <label htmlFor="lname">Type *</label>
    
    <Form.Select
      size="sm"
      value={v.type}
      onChange={(t) => {
        changeArayEquipment(index,{type:t.target.value})
      }}
    >
       <option>Select Type</option>
       <option>AERIAL LIFT</option>
      <option>FLATBED F650</option>
      <option>CHEVY 2500HD </option>    
    </Form.Select>
    
    
              </div>
              <div className="col-sm-2 col-3">
              <label htmlFor="lname">ID  *</label>
              <input
                      
                      type="number"
                      id="fname"
                      name="vin"
                      placeholder="ID"
                      value={v.idd}
                      onChange={(t) => {
                        changeArayEquipment(index,{idd:t.target.value})
                      }}
                      className="form-control form-control-sm"
                    />

              </div>
              <div className="col-sm-2 col-3">
              <label htmlFor="lname">Quantity  *</label>
    
              <input
                      
                      type="number"
                      id="fname"
                      name="vin"
                      placeholder="Quantity"
                      value={v.quantity}
                      onChange={(t) => {
                        const tot=t.target.value*v.hours*v.cost;
                        changeArayEquipment(index,{quantity:t.target.value,total:tot})
                      }}
                      className="form-control form-control-sm"
                    />
    
    
              </div>
              <div className="col-sm-2 col-3">
              <label htmlFor="lname">Hours *</label>
              <input
                      
                      type="number"
                      id="fname"
                      name="vin"
                      placeholder="Hours"
                      value={v.hours}
                      onChange={(t) => {
                        const tot=t.target.value*v.quantity*v.cost;

                        changeArayEquipment(index,{hours:t.target.value,total:tot})
                      }}
                      className="form-control form-control-sm"
                    />

              </div>
              <div className="col-sm-2 col-3">
              <label htmlFor="lname">Cost *</label>
              <div className="input-group mb-3">
  <div className="input-group-prepend">
    <span className="input-group-text">$</span>
  </div>
  <input  value={v.cost}
                      onChange={(t) => {
                        const tot=t.target.value*v.hours*v.quantity;
                        changeArayEquipment(index,{cost:t.target.value,total:tot})
                      }} type="number" className="form-control" aria-label="Amount (to the nearest dollar)" />
                      </div>

              </div>
              <div style={{marginTop:"20px"}} className="col-sm-1 col-3">
<Trash color="#144a73"
 onClick={() => delEquipment(v.id)}
                size={25} />
            </div>

              </div>

            </> ))
           }


         <label style={{marginTop:"60px", fontSize:"23px", fontWeight:"bold",  }}  onClick={()=>{
           addEquipment()
         }} htmlFor="validationCustom01">   <PlusCircleFill color="#144a73"
                size={35} /> Add Equipment </label>
         </div>   
         </>:mainView=="5"?<>
         <div>
           {
             Fee.map((v,index)=>(<>
    <div style={{marginBottom:"20px"}} className="row">
    <div className="col-sm-12 col-12">
      <div
        className="h-30"
        style={{
          marginTop: "50px",
          backgroundColor: "#108dfa",
          
          paddingTop: "5px",
          paddingBottom: "4px",
        }}
      >
        {" "}
        <h4  style={{paddingLeft: 15}}> Disposal Fees  </h4>
      </div>
  
  </div>
  </div>
             
              <div  style={{marginTop:"20px"}} className="row">
              <div className="col-sm-4 col-3">
              <label htmlFor="lname">Fee Type *</label>
    
    <Form.Select
      size="sm"
      value={v.type}
      onChange={(t) => {
        changeArayFee(index,{type:t.target.value})
      }}
    >
       <option>Select Fee Type</option>
      <option>ANTI FREEZE RECYCLING</option>
      <option>LANDFILL (KITTY LITTER)</option>
      <option>OIL RECYCLING</option>
      <option>VEHICULAR FLUID DISPOSAL</option>    
    </Form.Select>
    
    
              </div>
              <div className="col-sm-4 col-3">
              <label htmlFor="lname">Unit  of Measurement  *</label>
            
              <Form.Select
      size="sm"
      value={v.unit}
      onChange={(t) => {
        changeArayFee(index,{unit:t.target.value})
      }}
    ><option value="Bag">Bag</option>
    <option value="Bin">Bin</option>
    <option value="Drum">Drum</option>
    <option value="Gallon">Gallon</option>
    <option value="Pail">Pail</option>
    <option value="Quarts">Quarts</option>

    </Form.Select>
              </div>
              <div className="col-sm-2 col-2">
              <label htmlFor="lname">Quantity  *</label>
    
              <input
                      
                      type="number"
                      id="fname"
                      name="vin"
                      placeholder="Quantity"
                      value={v.quantity}
                      onChange={(t) => {
                        const tot=t.target.value*v.cost; 
                        changeArayFee(index,{quantity:t.target.value,total:tot})
                      }}
                      className="form-control form-control-sm"
                    />
    
    
              </div>
              <div className="col-sm-2 col-2"> 
              <label htmlFor="lname">Cost  *</label>
    
              <div className="input-group mb-3">
  <div className="input-group-prepend">
    <span className="input-group-text">$</span>
  </div>
  <input  value={v.cost}
                      onChange={(t) => {
                        const tot=t.target.value*v.quantity; 
                        changeArayFee(index,{cost:t.target.value,total:tot})
                      }} type="number" className="form-control" aria-label="Amount (to the nearest dollar)" />
                      </div>
    
    
              </div>
              {/* run */}
              <div className="col-sm-10 col-12">
              <label htmlFor="lname">Type of Waste</label>
              <MultiSelect
              options={typeOfWaste}
              value={v.waste}
              onChange={(a)=>(
                setSelectedTypeOfWaste(a,index)
              )}
              labelledBy="select" />
          

              </div>
              <div style={{marginTop:"20px"}} className="col-sm-1 col-3">
<Trash color="#144a73"
 onClick={() => delFee(v.id)}
                size={25} />
            </div>

              </div>

              </> ))
           }


         <label style={{marginTop:"60px", fontSize:"23px", fontWeight:"bold",  }} onClick={()=>{
           addFee()
         }} htmlFor="validationCustom01">   <PlusCircleFill color="#144a73"
                size={35} /> Add Fee </label>

         </div>
         
         
         
         </>:<></>
}


</form>
             </div>
             
            
</div>
  )
}


export default App;