import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function Newimpound() {
  const [show, setshow] = useState();
  const [invoiceSubTotal, setinvoiceSubTotal] = useState(0);
  const [invoiceTaxes, setinvoiceTaxes] = useState(0);
  const [invoiceMasterTotal, setinvoiceMasterTotal] = useState(0);
  const [invoice, setInvoice] = useState([
    {
      id: 1,
      name: "Unloaded/Enroute Mileage",
      quantity: 0,
      price: 0,
      total: 0,
      status: "disable",
    },
    {
      id: 2,
      name: "",
      quantity: 0,
      price: 0,
      total: 0,
      status: "diff",
    },
    {
      id: 3,
      name: "",
      quantity: 0,
      price: 0,
      total: 0,
      status: "normal",
    },
  ]);

  const changeArayInvoices = (i, changes) => {
    const oldArr = [...invoice];
    const oldObj = oldArr[i];
    oldArr[i] = { ...oldObj, ...changes };
    oldArr[i].total = oldArr[i].quantity * oldArr[i].price;
    setInvoice(oldArr);
    const subTotal = oldArr.reduce((n, { total }) => n + total, 0);
    setinvoiceSubTotal(Number(subTotal));
    setinvoiceMasterTotal(Number(invoiceTaxes) + Number(subTotal));
    console.log(oldArr[i]);
    setshow(oldArr);
  };

  const AddTotalValue = (e) => {
    setinvoiceTaxes(e);
    setinvoiceMasterTotal(Number(invoiceSubTotal) + Number(e));
  };
  useEffect(() => {
    changeArayInvoices(1, { price: 12 });
    changeArayInvoices(2, { price: 12 });
    changeArayInvoices(3, { price: 12 });
  }, []);
  return (
    <div
      className="container"
      style={{ marginTop: "70px", marginBottom: "70px" }}
    >
      {JSON.stringify(show)}
      {invoiceSubTotal}

      <strong className="d-inline-block mb-2 text-success">
        <h3> New Impound </h3>
      </strong>
    </div>
  );
}
export default Newimpound;
