import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Display, CircleFill } from "react-bootstrap-icons";

function NewTruck() {
  const history = useHistory();

  const [name, setName] = useState("");
  const [cid, setCid] = useState();

  const [uname, setUname] = useState();
  const [cname, setCname] = useState();
  const [uid, setUid] = useState();

  const [vin, setVin] = useState();
  const [make, setMake] = useState();
  const [model, setModel] = useState();
  const [year, setyear] = useState();

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));

    if (v === null) {
    } else {
      console.log(v);
      setCname(v[0].cname);
      setCid(v[0].cid);
      setUid(v[0].id);
      setUname(v[0].name);
      console.log(v);
    }
  }, []);

  const newTruck = async (e) => {
    e.preventDefault();

    if (name === "") {
      alert("Enter Truck No.");
    } else {
      await fetch(
        `/account/apis/truck_new.php?cid=${cid}&name=${name}&uid=${uid}&year=${year}&model=${model}&make=${make}&vin=${vin}`
      )
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          history.push("/alltruck");
        })
        .catch((err) => {
          console.log(err);
          console.log(err);
          console.log(err);
        });
    }
  };

  return (
    <div
      className="container"
      style={{ marginTop: "70px", marginBottom: "70px" }}
    >
      <form className="needs-validation">
        <strong className="d-inline-block mb-2 text-success">
          <h3>New Truck</h3>
        </strong>
        <div className="row">
          <div className="col-sm-6 col-12">
            <p>
              <CircleFill
                color="#1ceb15"
                size={12}
                style={{ marginRight: "3px" }}
              />{" "}
              Active |{" "}
              <Display
                size={20}
                style={{ marginRight: "3px", marginLeft: "3px" }}
              />
              <> {uname}</>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 col-6">
            <label for="validationCustom01">Company </label>
            <input
              value={cname}
              onChange={(t) => {
                setCname(t.target.value);
              }}
              type="text"
              disabled
              id="fname"
              name="company"
              placeholder="Company Name"
              className="form-control form-control-sm"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">VIN </label>
            <input
              type="text"
              value={vin}
              onChange={(t) => {
                setVin(t.target.value);
              }}
              name="vin"
              placeholder="VIN"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Make </label>
            <input
              type="text"
              value={make}
              onChange={(t) => {
                setMake(t.target.value);
              }}
              name="make"
              placeholder="Make"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Model </label>
            <input
              type="text"
              value={model}
              onChange={(t) => {
                setModel(t.target.value);
              }}
              name="name"
              placeholder="Model"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Year </label>
            <input
              type="text"
              value={year}
              onChange={(t) => {
                setyear(t.target.value);
              }}
              id="fname"
              name="year"
              placeholder="Year"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Truck No. * </label>
            <input
              type="text"
              value={name}
              onChange={(t) => {
                setName(t.target.value);
              }}
              placeholder="Truck No."
              className="form-control form-control-sm"
            />
          </div>
        </div>

        <button
          onClick={newTruck}
          style={{ backgroundColor: "#3fa66c", marginTop: "20px" }}
          type="button"
          className="btn btn-secondary"
        >
          {" "}
          Submit{" "}
        </button>
      </form>
    </div>
  );
}
export default NewTruck;
