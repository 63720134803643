import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";
import { Display, CircleFill } from "react-bootstrap-icons";

function NewDriver() {
  const history = useHistory();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [uname, setUname] = useState();
  const [cname, setCname] = useState();
  const [uid, setUid] = useState(0);
  const [cid, setCid] = useState();
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));
    console.log(v);
    console.log(v);
    setCname(v[0].cname);
    setCid(v[0].cid);
    setUid(v[0].id);
    setUname(v[0].name);
    console.log(v);
  }, []);
  const newDriver = async (e) => {
    e.preventDefault();

    await fetch(
      `/account/apis/driver_new.php?cid=${cid}&uid=${uid}&name=${name}&phone=${phone}`
    )
      .then((response) => response.json())
      .then((json) => {
        history.push("/alldriver");
      })
      .catch((err) => {
        alert(err);
      });
  };
  return (
    <div
      className="container"
      style={{ marginTop: "70px", marginBottom: "70px" }}
    >
      <form className="needs-validation">
        <strong className="d-inline-block mb-2 text-success">
          <h3>New Driver</h3>
        </strong>

        <div className="row">
          <div className="col-sm-6 col-12">
            <p>
              <CircleFill
                color="#1ceb15"
                size={12}
                style={{ marginRight: "3px" }}
              />{" "}
              Active |{" "}
              <Display
                size={20}
                style={{ marginRight: "3px", marginLeft: "3px" }}
              />
              <> {uname}</>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 col-6">
            <label for="validationCustom01">Company </label>
            <input
              disabled
              type="text"
              id="fname"
              value={cname}
              name="company"
              placeholder="Company Name"
              className="form-control form-control-sm"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Name * </label>
            <input
              type="text"
              value={name}
              onChange={(t) => {
                setName(t.target.value);
              }}
              id="fname"
              name="name"
              placeholder="Name"
              className="form-control form-control-sm"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Phone * </label>
            <input
              type="text"
              id="fname"
              value={phone}
              onChange={(t) => {
                setPhone(t.target.value);
              }}
              name="name"
              placeholder="Phone"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <button
          onClick={newDriver}
          style={{ backgroundColor: "#3fa66c", marginTop: "20px" }}
          type="button"
          className="btn btn-secondary"
        >
          {" "}
          Submit{" "}
        </button>
      </form>
    </div>
  );
}
export default NewDriver;
