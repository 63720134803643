import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import hide from "../image/hidden.png";
import show from "../image/show.png";
import "../MyComponents/index.css";

function Login() {
  const [email, setEmail] = useState("");
  const [pass, setPassword] = useState("");

  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const history = useHistory();

  const loginServer = async (e) => {
    e.preventDefault();

    await fetch(
      `/account/apis/c_login.php?email=${email}&pass=${pass}&type=master`
    )
      .then((response) => response.json())
      .then((json) => {
        console.log(json);

        if (json == null) {
        } else if (json[1].msg === "pass") {
          localStorage.setItem("LOCAL_KEY_MASTER", JSON.stringify(json));
          history.push("/selectcompany");
        } else {
          alert(" Fail try Again ");
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <div>
      <center>
        <div
          className="col-md-4"
          style={{
            marginTop: "100px",
          }}
        >
          <h4>Master Login</h4>

          <center>
            <form>
              <div className="pwd-container">
                <input
                  required
                  type="email"
                  style={{ marginTop: "20px" }}
                  value={email}
                  onChange={(t) => {
                    setEmail(t.target.value);
                  }}
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Email"
                />
              </div>
              <div className="pwd-container">
                <input
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter Password"
                  autoComplete="off"
                  style={{ marginTop: "20px" }}
                  value={pass}
                  name="pwd"
                  placeholder="Enter Password"
                  type={isRevealPwd ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <img
                  style={{ height: "20px", width: "20px" }}
                  title={isRevealPwd ? "Hide password" : "Show password"}
                    src={isRevealPwd ? hide : show}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />
              </div>
              <br />
              <button
                type="submit"
                style={{ marginTop: "20px" }}
                onClick={loginServer}
                className="btn btn-primary"
              >
                Login Now{" "}
              </button>
            </form>
          </center>
        </div>
      </center>
    </div>
  );
}

export default Login;
